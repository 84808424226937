import React from 'react'
import CustomIndustryWiseForms from './CustomIndustryWiseForms'

const  GroceryForm = () => {
  return (
    <> 
      <CustomIndustryWiseForms resType={"Grocery"} outLetType={["Kirana Stores","Supermarkets","Hypermarkets","FMCG ","Fruits & Vegetables"] }   featuresType={["Inventory Management","Quick Bill", "Credit Sales(Khata)"]} />
    </>
  )
}

export default  GroceryForm