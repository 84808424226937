import React, { Component } from 'react';
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// import Login from './components/common/Login';
// import LoggedIn from './components/common/LoggedIn';
import LandingPage from './components/LandingPage';
import HelpCenter from './components/OtherPages/HelpCenter';
import Article from './components/OtherPages/Article';
import SolutionsPage from './components/SolutionsPage';
import TermsAndServices from './components/OtherPages/TermsAndServices';
import GetInTouch from './components/OtherPages/GetInTouch';
import Policies from './components/OtherPages/Policies';
import PrivacyPolicy from './components/OtherPages/PrivacyPolicy';
import Pricing from './components/OtherPages/Pricing';
import EStore from './components/OtherPages/EStore';
import Khata from './components/OtherPages/Khata';
import StartTrialModal from './components/modals/StartTrialModal';
// import LoginModal from './components/modals/LoginModal';
import SignupModal from './components/modals/SignupModal';
import OtpModal from './components/modals/OtpModal';
import ContactModal from './components/modals/ContactModal';
// import ForgotPasswordModal from './components/modals/ForgotPasswordModal';
import HTAModal from './components/modals/HowToApplyModal';
import OurTeam from './components/OtherPages/OurTeam';
import Career from './components/OtherPages/Careers';
import Integrations from './components/OtherPages/Integrations';
import MerchantRegistration from "./components/modals/MerchantRegistration"; 

import PartnerLinking from './components/modals/PartnerLinking';
import './App.scss';
import LandingPageForm from './components/LandingPage/LandingPageForm';
import Restaurant from './components/OtherPages/CustomIndustryWiseForms';
import Demo from './components/OtherPages/MainRestaurant';
import SpaSalonIndustryForm from './components/OtherPages/SpaSalonIndustryForm';
import ElectronicsPOSForm from './components/OtherPages/ElectronicsPOSForm';
import GroceryForm from './components/OtherPages/ GroceryForm';
import RetailIndustryFrom from './components/OtherPages/RetailIndustryForm';
import MobileLandingPageForm from './components/OtherPages/IndustryFormMobile';
import OtherIndustryForm from './components/OtherPages/OtherIndustryForm';
import ThankYou from './components/OtherPages/ThankYou';
import NewGroceryForm from './components/OtherPages/NewGroceryForm';
import CustomIndustryWiseFormV2 from './components/OtherPages/CustomIndustryWiseFormV2';
import RestaurantFormV2 from './components/OtherPages/RestaurantFormV2';
import SpaAndSalonFormV2 from './components/OtherPages/SpaAndSalonV2';
import ElectronicsFormV2 from './components/OtherPages/ElectronicsFormV2';
import GroceryFormV2 from './components/OtherPages/GroceryFormV2';
import RetailFormV2 from './components/OtherPages/RetailFormV2';
import { EngagePamplet } from './components/OtherPages/EngagePamplet';
import AllInOnePosNew from './components/OtherPages/AllInOnePosNew';
import NewOnePager from './components/OtherPages/NewOnePager';

const $ = window.$;

class App extends Component {
	state = { cookieDisclaimer: document.cookie.split('; ').indexOf('cookieDisclaimer=true') !== -1 ? true : false }

	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>

					<div className="App" style={{ height: '100%' }}>
						<Switch>
							<Route exact path="/" component={LandingPage} />
							<Route exact path="/registration" component={MerchantRegistration} />
							<Route exact path="/activation" component={PartnerLinking} />
							<Route exact path="/solutions" component={SolutionsPage} />
							<Route exact path="/pricing" component={Pricing} />
							<Route exact path="/gdn/restaurant-pos-app" component={Demo} />
							<Route exact path="/engage23" component={EngagePamplet}/>
							<Route exact path="/gdn/spa-salon-pos-app" component={SpaSalonIndustryForm} />
							<Route exact path="/gdn/electronics-pos-app" component={ElectronicsPOSForm} />
							<Route exact path="/gdn/grocery-pos-app" component={GroceryForm} />
							<Route exact path="/gdn/retail-pos-app" component={RetailIndustryFrom} />
							<Route exact path="/gdn/all-in-one-pos-app" component={OtherIndustryForm}/>
							<Route exact path="/gdn/v2/restaurant-pos-app" component={RestaurantFormV2} />
							<Route exact path="/gdn/v2/spa-salon-pos-app" component={SpaAndSalonFormV2} />
							<Route exact path="/gdn/v2/electronics-pos-app" component={ElectronicsFormV2} />
							<Route exact path="/gdn/v2/grocery-pos-app" component={GroceryFormV2} />
							<Route exact path="/gdn/v2/retail-pos-app" component={RetailFormV2} />
							<Route exact path="/all-in-one-pos-app" component={AllInOnePosNew} />
							<Route exact path="/gdn/all-in-one-pos" component={NewOnePager} />
							{/* <Route exact path="/gdn/v2/demo/restaurant-pos-app" component={CustomIndustryWiseFormV2}/> */}
							<Route exact path="/dirIndustryForm/:purpose" component={MobileLandingPageForm}/>
							<Route exact path="/thankyou" component={ThankYou}/>
							<Route exact path="/estore" component={EStore} />
							<Route exact path="/khata" component={Khata} />
							<Route exact path="/support" component={GetInTouch} />
							<Route exact path="/termsAndServices" component={TermsAndServices} />
							<Route exact path="/policies" component={Policies} />
							<Route exact path="/privacyPolicy" component={PrivacyPolicy} />
							<Route exact path="/helpCenter" component={HelpCenter} />
							<Route exact path="/article/:slug" component={Article} />
							<Route exact path="/team" component={OurTeam} />
							<Route exact path="/careers" component={Career} />
							<Route exact path="/integrations" component={Integrations} />
							<Route exact path="/privacy" component={PrivacyPolicy} />
							<Route exact path="/terms" component={TermsAndServices} />
							<Route exact path="/mobileRestaurant" component={LandingPageForm} />
						</Switch>
						<StartTrialModal />
						{/* <LoginModal /> */}
						<SignupModal />
						<OtpModal />
						<ContactModal />
						{/* <ForgotPasswordModal /> */}
						<HTAModal />
						{( window.location.pathname.includes("engage23") || window.location.pathname.includes("gdn/") || window.location.pathname.includes("/dirIndustryForm/")||window.location.pathname==="/thankyou" )? null :(
							<>
							<div style={{
							display: this.state.cookieDisclaimer ? 'none' : 'flex',
							position: 'fixed',
							bottom: '60px',
							left: '50%',
							background: 'rgba(125, 156, 175, 0.95)',
							color: 'white',
							padding: '16px 16px 16px 20px',
							fontSize: '14px',
							lineHeight: '18px',
							justifyContent: 'space-between',
							alignItems: 'center',
							borderRadius: '5px',
							zIndex: 20,
							marginLeft: '-430px'
						}}
							className="cookie-disclimer">
							<p style={{ margin: 0 }}>This website uses cookies to provide you with a better surfing experience. To learn more, please visit our <a href="#" onClick={() => { window.open('/privacy') }}>Privacy Policy</a>. By continuing to use this website you consent to our use of cookies.</p>
							<button className={'cookie-privacy-btn'} onClick={() => {
								document.cookie = 'cookieDisclaimer=true; max-age=31536000;';
								this.setState({ cookieDisclaimer: true })
							}}>Accept</button>
						</div>
                  	
						<div style={{
							position: 'fixed',
							bottom: '60px',
							right: '5%',
							fontSize: '14px',
							lineHeight: '18px',
							justifyContent: 'space-between',
							alignItems: 'center',
							borderRadius: '5px',
							zIndex: 20,
							marginLeft: '-430px'
						}}
							className="help-div">
							<a className={'help-btn'} href="https://wa.me/919311101455?text=Hello" target="_blank" rel="noopener noreferrer">
								<i className="fa fa-brands font-bold fa-whatsapp text-lg" style={{ background: '#4ec95a'}}></i> <span className="help-text">Help?</span>
							</a>
						</div>
							</>
						) }
						
					
					</div>

				</BrowserRouter>

			</Provider>
		);
	}

	componentDidMount() {
		$('#login-modal').on('shown.bs.modal', function () {
			$('.login-form').find(':input[type=submit]').html('Login')
			$('.login-form').find(':input[type=submit]').prop('disabled', false)
			$(".login-form :input").show()
			$("#login-message-error").html('');
			$('#login-message').html('')
			$('#login-email').focus()
		})
		$(".login-form input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})

		$(".otp-form input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})

		$('#signup-modal').on('shown.bs.modal', function () {
			$('#signup-modal input[name="email"]').focus()
			$('#signup-form').find(':input[type=submit]').html('Sign Up')
			$('#signup-form').find(':input[type=submit]').prop('disabled', false)
			$('#signup-messages').html('')
		})
		$(".signup-form input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})

		$('#contact-modal').on('shown.bs.modal', function () {
			$('#contact-modal input[name="name"]').focus()
		})

		$('#forgot-password-modal').on('shown.bs.modal', function () {
			$('#forgot-password-modal form:nth-child(1)').show()
			$('#forgot-form').find(':input[type=submit]').prop('disabled', false)
			$('#forgot-form').find(':input[type=submit]').html(`Next`)
			$("#forgot-form :input").show()
			$("#forgot-messages-error").html('');
			$("#forgot-messages").html('');
			$('#forgot-password-modal form:nth-child(2)').hide()
			$('#forgot-password-modal form:nth-child(3)').hide()
			$('#password-updated-div').hide()
		})
		$("#forgot-form :input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})
		$("#forgot-otp-form :input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})
		$("#forgot-password-form :input").on('click', function () {
			$(this).css('border', 'solid 1px #d8d8d8');
		})
	}
}



export default App;