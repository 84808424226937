import React from "react";
import "./restaurant.css";
import white_logo from "../../assets/images/queueBusterLogo_white.svg";
import table from "../../assets/images/chair.svg";
import integration from "../../assets/images/integration.svg";
import recipe from "../../assets/images/recipe-book.svg";
import LandingPageForm from "../LandingPage/LandingPageForm";
import resturantBackground from "../../assets/images/restaurantBackground.jpg";
import retailBackground from "../../assets/images/retail_background.jpg";
import spaBackground from "../../assets/images/spa_background.jpg";
import groceryBackground from "../../assets/images/grocery_background.jpg";
import electronicBackground from "../../assets/images/electronics_background.jpg";
import checklist from "../../assets/images/checklists.svg";
import appointment from "../../assets/images/appointment.svg";
import quickbill from "../../assets/images/quickbill.svg";
import memberCard from "../../assets/images/member-card.svg";
import document from "../../assets/images/document.svg";
import onlineShopping from "../../assets/images/online-shopping.svg";
import setting from "../../assets/images/setting.svg";
import membership from "../../assets/images/membership.svg";
import verticalDots from "../../assets/images/vertical-dots-icon.png";
import grayBackground from "../../assets/images/avatar.jpeg";
import bulletPoint from "../../assets/images/bulletPoint.png";
import { useHistory } from "react-router-dom";
const CustomIndustryWiseForms = (props) => {
  const history = useHistory();

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  if (screenWidth < 900) {
    return (
      <div
        className="mobile-background-image"
        style={{
          padding: "6%",
          paddingLeft: "8%",
          height: "100vh",
          width: "100vw",
          fontSize: "30px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(${
            props.resType == "Restaurant"
              ? resturantBackground
              : props.resType == "Electronics"
              ? electronicBackground
              : props.resType == "Grocery"
              ? groceryBackground
              : props.resType == "Retail"
              ? retailBackground
              : props.resType == "Spa & Salon"
              ? spaBackground
              : grayBackground
          })`,
        }}
      >
        <div>
          <img src={white_logo} width="50%" height="30%" />
        </div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <span
            style={{
              // font: "italic normal 300 60px/110px Poppins",

              //  fontFamily: "italic normal Poppins",

              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              textShadow: "1px 1px #00000078",
              fontWeight: "300",
              fontSize: "2.2rem",
              paddingTop: "5%",
              color: "#FFFFFF",
              letterSpacing: "0px",
            }}
          >
            India’s
          </span>{" "}
          <span
            style={{
              // font: "italic normal 1000 60px/110px Poppins",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              textShadow: "1px 1px #00000078",
              fontWeight: "800",
              fontSize: "2.2rem",
              letterSpacing: "0px",
              color: "#F1E17B",
              // fontWeight: "Bold Italic",
            }}
          >
            No. 1
          </span>
          <div
            className="appType"
            style={{
              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              textShadow: "5px 5px #00000078",
              fontWeight: "900",
              fontSize: "2.1rem",
              letterSpacing: "0px",
              marginTop: "-3%",
              color: "#FFFFFF",
            }}
          >
            {props.resType}
          </div>
          <div
            className="appType"
            style={{
              fontFamily: "Poppins, sans-serif",
              fontStyle: "italic",
              textShadow: "5px 5px #00000078",
              fontWeight: "800",
              fontSize: "2.1rem",
              letterSpacing: "0px",
              marginTop: "-3%",
              color: "#FFFFFF",
            }}
          >
            POS Application
          </div>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "1.8rem",
              color: "#FFFFFF",
              paddingTop: "5%",
            }}
          >
            {props.resType == "All-in-one" ? "Business Types" : "Outlet Types"}
          </p>
          <p
            style={{
              fontFamily: "normal normal medium Poppins",
              fontSize: "1.35rem",
              color: "#FFFFFF",
              marginTop: "-2%",
            }}
          >
            {props &&
              props.outLetType &&
              props.outLetType.map((el, idx) => {
                return (
                  <span
                    key={idx}
                    style={
                      idx == 0
                        ? {
                            fontFamily: "Poppins, sans-serif",
                            fontStyle: "normal",
                            borderRight: "2px solid #FFFFFF",
                            paddingRight: "0.5rem",
                            fontSize: "1rem",
                          }
                        : {
                            fontFamily: "Poppins, sans-serif",
                            fontStyle: "normal",
                            borderRight: "2px solid #FFFFFF",
                            paddingRight: "0.5rem",
                            fontSize: "1rem",
                            paddingLeft: "0.3rem",
                          }
                    }
                  >
                    {el}
                  </span>
                );
              })}
            <span
              style={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontStyle: "normal",
                paddingLeft: "0.3rem",
              }}
            >
              & many more
            </span>
          </p>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontStyle: "normal",
              fontWeight: "800",
              fontSize: "1.2rem",
              color: "#FFFFFF",
              marginTop: screenHeight <= 568 ? "15%" : "33%",
            }}
          >
            {props.resType == "All-in-one" ? "Integrations" : "Features"}
          </p>
          <div>
            <div
              style={{
                color: "#FFFFFF",
                display: "grid",
                gridTemplateColumns: "40% 40%",
                gap: "8%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    props.resType == "Restaurant"
                      ? table
                      : props.resType == "Spa & Salon"
                      ? membership
                      : props.resType == "Electronics"
                      ? checklist
                      : props.resType == "Grocery"
                      ? checklist
                      : props.resType == "Retail"
                      ? checklist
                      : bulletPoint
                  }
                  style={{
                    width: "2.8rem",
                    height: "1.56rem",
                    margin: "0px 10px 20px 0px",
                  }}
                  alt="table"
                />
                <p
                  style={{
                    width: "8.65rem",
                    fontSize: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                  }}
                >
                  {props.featuresType[0]}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    props.resType == "Restaurant"
                      ? recipe
                      : props.resType == "Spa & Salon"
                      ? appointment
                      : props.resType == "Electronics"
                      ? document
                      : props.resType == "Grocery"
                      ? quickbill
                      : props.resType == "Retail"
                      ? memberCard
                      : bulletPoint
                  }
                  style={{
                    width: "2.8rem",
                    height: "1.56rem",
                    margin: "0px 10px 20px 0px",
                  }}
                  alt="table"
                />
                <p
                  style={{
                    width: "8.65rem",
                    fontSize: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                  }}
                >
                  {props.featuresType[1]}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    props.resType == "Restaurant"
                      ? integration
                      : props.resType == "Spa & Salon"
                      ? setting
                      : props.resType == "Electronics"
                      ? quickbill
                      : props.resType == "Grocery"
                      ? document
                      : props.resType == "Retail"
                      ? onlineShopping
                      : bulletPoint
                  }
                  style={{
                    width: "2.8rem",
                    height: "1.56rem",
                    margin: "0px 10px 20px 0px",
                  }}
                  alt="table"
                />
                <p
                  style={{
                    width: "8.65rem",
                    fontSize: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                  }}
                >
                  {props.featuresType[2]}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={verticalDots}
                  style={{
                    width: "2.8rem",
                    height: "1.56rem",
                    margin: "0px 10px 20px 0px",
                  }}
                  alt="table"
                />
                <p
                  style={{
                    width: "8.65rem",
                    fontSize: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                  }}
                >
                  And Many More..
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <button
            style={{
              marginTop: "5%",
              padding: "2%",
              height: "3.5rem",
              fontSize: "1.2rem",
              // width: "100%",
              borderRadius: "6px",
              boxShadow: "0px 16px 32px #FB768829",
              fontWeight: "600",
              background: "#3C69AF 0% 0% no-repeat padding-box",
              color: "white",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "normal",
              border: "none",
            //   position: "absolute",
              // bottom: "60px",
              width: "100%",
            //   right: "6%",
            //   left: "8%",
            }}
            onClick={() => {
              history.push(
                `/dirIndustryForm/${(props && props.resType) || ""}`
              );
            }}
          >
            Request Demo
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          padding: "60px",
          height: " 100vh",
          width: "100vw",
          backgroundImage: `url(${
            props.resType == "Restaurant"
              ? resturantBackground
              : props.resType == "Electronics"
              ? electronicBackground
              : props.resType == "Grocery"
              ? groceryBackground
              : props.resType == "Retail"
              ? retailBackground
              : props.resType == "Spa & Salon"
              ? spaBackground
              : grayBackground
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
        }}
      >
        <div
          style={{
            width: "100%",
			height: "100%",
            display: "grid",
            gridTemplateColumns: "65% 31%",
            gap: "2%",
            justifyContent: "space-between",
            marginTop: "24px",
            // alignContent:'bottom'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div className="pageTypeHeading" style={{ width: "100%" }}>
              <img src={white_logo} width="20.22%" height="60" />
              <div style={{ width: "100%", marginTop: "20px" }}>
                <span
                  className="indiaSpan"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "italic",
                    textShadow: "5px 5px #00000078",
                    fontWeight: "300",
                    fontSize: "4rem",
                    color: "#FFFFFF",
                    letterSpacing: "0px",
                  }}
                >
                  India’s
                </span>{" "}
                <span
                  className="no1Span"
                  style={
                    props.resType == "Restaurant" || props.resType == "Retail"
                      ? {
                          // font: "italic normal 1000 60px/110px Poppins",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "italic",
                          textShadow: "5px 5px #00000078",
                          fontWeight: "800",
                          fontSize: "4rem",
                          letterSpacing: "0px",
                          color: "#F1E17B",
                          // #A5F1E9
                          // fontWeight: "Bold Italic",
                        }
                      : props.resType == "Spa & Salon"
                      ? {
                          // font: "italic normal 1000 60px/110px Poppins",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "italic",
                          textShadow: "5px 5px #00000078",
                          fontWeight: "800",
                          fontSize: "4rem",
                          letterSpacing: "0px",
                          color: "#A5F1E9",
                        }
                      : props.resType == "Electronics"
                      ? {
                          // font: "italic normal 1000 60px/110px Poppins",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "italic",
                          textShadow: "5px 5px #00000078",
                          fontWeight: "800",
                          fontSize: "4rem",
                          letterSpacing: "0px",
                          color: "#F97575",
                        }
                      : props.resType == "Grocery"
                      ? {
                          // font: "italic normal 1000 60px/110px Poppins",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "italic",
                          textShadow: "5px 5px #00000078",
                          fontWeight: "800",
                          fontSize: "4rem",
                          letterSpacing: "0px",
                          color: "#65E83A",
                        }
                      : {
                          // font: "italic normal 1000 60px/110px Poppins",
                          fontFamily: "Poppins, sans-serif",
                          fontStyle: "italic",
                          textShadow: "5px 5px #00000078",
                          fontWeight: "800",
                          fontSize: "4rem",
                          letterSpacing: "0px",
                          color: "#F1E17B",
                        }
                  }
                >
                  &nbsp;No. 1
                </span>
              </div>
              <div
                className="appType"
                style={
                  screenWidth > 1000
                    ? {
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "italic",
                        textShadow: "5px 5px #00000078",
                        fontWeight: "800",
                        fontSize: "4rem",
                        letterSpacing: "0px",
                        marginTop: "-20px",
                      }
                    : {
                        color: "#FFFFFF",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "italic",
                        textShadow: "5px 5px #00000078",
                        fontWeight: "800",
                        fontSize: "2.4rem",
                        letterSpacing: "0px",
                        marginTop: "-20px",
                      }
                }
              >
                {props.resType} POS Application
              </div>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "2.08rem",
                  color: "#FFFFFF",
                }}
              >
                {props.resType == "All-in-one"
                  ? "Business Types"
                  : "Outlet Types"}
              </p>
              <p
                style={{
                  fontFamily: "normal normal medium Poppins",
                  fontSize: "1.35rem",
                  color: "#FFFFFF",
                }}
              >
                {props &&
                  props.outLetType &&
                  props.outLetType.map((el, idx) => {
                    return (
                      <span
                        key={idx}
                        style={
                          idx == 0
                            ? {
                                borderRight: "1px solid #FFFFFF",
                                padding: "0.5rem 0.9rem 0.9rem 0",
                                fontFamily: "Poppins, sans-serif",
                                fontStyle: "normal",
                              }
                            : {
                                borderRight: "1px solid #FFFFFF",
                                // border:'1px solid red',
                                padding: "0.5rem 0.9rem 0.9rem 0.9rem",
                                fontFamily: "Poppins, sans-serif",
                                fontStyle: "normal",
                              }
                        }
                      >
                        {el}
                      </span>
                    );
                  })}
                <span
                  style={{
                    padding: "0 0.9rem",
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                  }}
                >
                  & many more
                </span>
              </p>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontStyle: "normal",
                  textShadow: "5px 5px #00000078",
                  fontWeight: "600px",
                  fontSize: "2.08rem",
                  color: "#FFFFFF",
                }}
              >
                {props.resType == "All-in-one" ? "Integrations" : "Features"}
              </p>
              <div>
                <div
                id="featureBox"
                  style={{
                    display: "flex",
                    gap: '6px',
                    width: "90%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // flexWrap:'wrap',
                      gap: "0.52rem",
                      color: "#FFFFFF",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        props.resType == "Restaurant"
                          ? table
                          : props.resType == "Spa & Salon"
                          ? membership
                          : props.resType == "Electronics"
                          ? checklist
                          : props.resType == "Grocery"
                          ? checklist
                          : props.resType == "Retail"
                          ? checklist
                          : bulletPoint
                      }
                      style={{
                        //  width: "2.8rem",
                        height: "1.56rem",
                        margin: "0px 10px 10px 0px",
                      }}
                      alt="table"
                    />
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      {props.featuresType[0]}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // flexWrap:'wrap',
                      gap: "0.52rem",
                      color: "#FFFFFF",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        props.resType == "Restaurant"
                          ? recipe
                          : props.resType == "Spa & Salon"
                          ? appointment
                          : props.resType == "Electronics"
                          ? document
                          : props.resType == "Grocery"
                          ? quickbill
                          : props.resType == "Retail"
                          ? memberCard
                          : bulletPoint
                      }
                      style={{
                        //  width: "2.8rem",
                        height: "1.56rem",
                        margin: "0px 10px 10px 0px",
                      }}
                      alt="table"
                    />
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      {props.featuresType[1]}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // flexWrap:'wrap',
                      gap: "0.52rem",
                      color: "#FFFFFF",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        props.resType == "Restaurant"
                          ? integration
                          : props.resType == "Spa & Salon"
                          ? setting
                          : props.resType == "Electronics"
                          ? quickbill
                          : props.resType == "Grocery"
                          ? document
                          : props.resType == "Retail"
                          ? onlineShopping
                          : bulletPoint
                      }
                      style={{
                        //  width: "2.8rem",
                        height: "1.56rem",
                        margin: "0px 10px 10px 0px",
                      }}
                      alt="table"
                    />
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      {props.featuresType[2]}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.52rem",
                      color: "#FFFFFF",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={verticalDots}
                      style={{
                        //  width: "2.8rem",
                        height: "1.56rem",
                        margin: "0px 10px 10px 0px",
                      }}
                      alt="dot"
                    />

                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                      }}
                    >
                      And Much More..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "100%",marginBottom:'30px' }}>
            <LandingPageForm purpose={props.resType} />
          </div>
        </div>
      </div>
    );
  }
};

export default CustomIndustryWiseForms;
