import React, { Component } from "react";
import "./SignupModal.scss";
import ms from "../../assets/images/partnerLinking.svg";
import Header from "../common/Header";
import Footer from "../sections/Footer";
import main from '../main';
import { CountryCodeSelect } from "../common/qbStrap";
const $ = window.$;

class PartnerLinking extends Component {
  state = {firstName: "-",lastName: "-",phoneCode: "-",phone: "-",email: "-",leadAddLine1: "-",leadAddLine2: "-",yPlan1: "-"};
  
  componentDidMount() {
    main();
    const ths = this;
    const leadID = new URL(window.location.href).searchParams.get("leadID");

    

    const data = { check: window.check, check1:window.check1 , useEntity: 1, mainPage: 1, leadID, routeParam: "leadDetail" };

    if(!leadID) window.location.assign("/");

    $.ajax({
      url: `manage/sendData.php`,
      type: "POST",
      data,
      success: function (res) {
        const state = JSON.parse(res).data[0];
        ths.setState({ ...state });
      },
      error: function (err) {
        console.log(err.message || "Something went wrong!");
        window.location.assign("/");
      },
    });
  }

  updateLeadStatus = (leadStatus) => {
    const {leadID,resellerID} = this.state;
    const ths = this;
    $.ajax({
        url: `manage/sendData.php`,
        type: "POST",
        data: {check: window.check, check1:window.check1 , useEntity: 1, mainPage: 1,isPartner:1 , routeParam:"updateLeadStatus",leadID,resellerID,leadStatus},
        success: function (res) {
         const {status,message="Successfull!"} = JSON.parse(res);
         ths.setState({showToast: true,status,message});
         setTimeout(_ => {
            if(status) {
                window.location.assign("/");
            }
            else{
                ths.setState({showToast: false});
            }
         },3000);
        },
        error: function (err) {
          console.log(err.message || "Something went wrong!");
        },
      });
  }

  render() {
      const { showToast,message,firstName,lastName,phoneCode,phone,email,leadAddLine1,leadAddLine2,yPlan1,status} = this.state;
    const styles = {
        container: {backgroundSize: "cover",backgroundImage:  "url(" + ms + ")",backgroundPositon: "center",backgroundRepeat: "no-repeat",padding: "10px 0",marginTop: "67px"},
        card: {background: "rgba(255, 255, 255, 0.84)",borderRadius: "4px",width: "50%",margin:"15vh auto",padding: "30px"},
        header: {textAlign:"center"},
        approveBtn: {color: "#498bdc",borderColor: "#498bdc",borderWidth: "1px",background: "transparent",borderStyle: "solid",padding:"5px 10px",borderRadius: "4px",marginRight:"20px",fontWeight: "bold"},
        toast: {background: !status ? "red" : "green",color : "#fff",position: "fixed",right: "3%",padding: "6px  30px 6px 30px",borderRadius:"4px",opacity: "0.7"}
    }
    return (    
      <div>
          <Header/>
            <section id="container" style={styles.container}>
              
            {Boolean(showToast) && <span style={styles.toast}> {message} <span style={{marginLeft:"50px"}}>{ status ? "✓" : "✖ "}</span></span>  }          
              <section style={styles.card} >
                  <form class="needs-validation signup-form" id="signup-form1"  noValidate>
                  <header style={styles.header}>
                    <h2><bold>License Linking</bold></h2>
                    <small style={{color: "#6A6C75"}} className="mb-0">Link your license with reseller</small>
                  </header>
                  <article className="mt-4">
                      <label>Distributor Details</label>
                      <div class="form-row mt-2">
                        
                        <div class="col-md-4 mb-3">
                          <label style={{color: "#6A6C75"}}>Name</label>
                          <p>{firstName}{" "}{lastName}</p>
                        </div>

                        <div class="col-md-4 mb-3">
                        <label style={{color: "#6A6C75"}}>Phone</label>
                          <p><span>+{phoneCode}</span> {" "} <span>{phone}</span></p>
                        </div>

                        <div class="col-md-4 mb-3">
                        <label style={{color: "#6A6C75"}}>Phone</label>
                            <p>{email}</p>
                        </div>

                      </div>

                      <div class="form-row ">
                          <div class="col-md-8 mb-3">
                            <label style={{color: "#6A6C75"}}>Address</label>
                            <p>{leadAddLine1} {" "} {leadAddLine2}</p>
                          </div>
                      </div>
                  </article>

                  <article className="mt-2">
                      <label>License Details</label>
                      <div class="form-row mt-2"> 
                        <div class="col-md-4 mb-3">
                          <label style={{color: "#6A6C75"}}>License Price</label>
                          <p>{(window.currencySymbol || "₹")}{yPlan1}</p>
                        </div>
                      </div>
                  </article>

                  <footer>
                    <div class="form-row" style={{justifyContent: "center"}}>
                      <button style={styles.approveBtn} onClick={_ => this.updateLeadStatus("ACCEPTED")}>Approve</button>
                      <button class="btn btn-primary signupBtn" onClick={_ => this.updateLeadStatus("REJECTED")}>Reject</button>
                    </div>
                  </footer>
                  </form>
              </section>

            </section>
          <Footer/>
      </div>
  )}
}

export default PartnerLinking;
