import React,{Component} from 'react'

import Header from '../common/Header';
import GradientSection from '../sections/GradientSection';
import Partners from '../sections/Partners'
import ScheduleYourDemo from '../sections/ScheduleYourDemo'
import Footer from '../sections/Footer'
import StartTrial from '../sections/StartTrial';
import './landing.scss';
import main from '../main';
import BusinessTypes2 from '../sections/BusinessTypes2';
import GetStarted from '../sections/GetStarted';
import { changeBusinessTypesState } from '../../actions';
import { connect } from 'react-redux';

class LandingPage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <GradientSection />
                <BusinessTypes2 />
                <GetStarted />
                <StartTrial/>
                <Partners/>
                <ScheduleYourDemo/>
                <Footer/>
            </div>
        )
    }

    componentDidMount() {
        main();
        let $ = window.$;
        if (localStorage.getItem('landingPageState')) {
            let state = localStorage.getItem('landingPageState')
            if (state === "schedule_demo") {
                $('html, body').animate({
                    scrollTop: $('#schedule_demo').offset().top - 68
                }, 1000);
            } else {
                state = state.split('-');
                if (state[0] === 'web') {
                    $('html, body').animate({
                    scrollTop: $('#business_types_2').offset().top - 68 + 190
                    }, 1000);
                    this.props.changeBusinessTypesState(+state[2]);
                } else if (state[0] === 'mob') {
                    $('html, body').animate({
                        scrollTop: $('#business_types_2').offset().top - 68
                    }, 1000);
                }
            }
            localStorage.removeItem('landingPageState');
        }
    }
}

export default connect(null, { changeBusinessTypesState })(LandingPage);