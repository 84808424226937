import React, { Component } from 'react';

import Header from '../common/Header';
import Footer from '../sections/Footer';
import './HelpCenter.scss'

import main from '../main';

class HelpCenter extends Component {

	render() {
		const pdfSource = (window.location.origin.includes("localhost") ? "https://beta.queuebuster.co" : window.location.origin) + "/assets/user_manual.pdf#toolbar=0"
		return (
			<div id="main-container">
				<Header />

				<div id="help_center">
					<iframe title='QueueBuster User Manual' src={pdfSource} style={{ width: "80vw" }} loading="eager" frameborder="0" />
				</div>

				<Footer />
			</div>
		)
	}

	componentDidMount() {
		main();
	}
}

export default HelpCenter;