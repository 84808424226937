import React, {Component} from 'react';
import Header from '../common/Header';
import top from '../../assets/images/top_image.webp';
import eStore from '../../assets/images/estore_web.svg';
import iphone from '../../assets/images/power_iphonex.webp';
import check from '../../assets/icons/green_check.svg';
import smartphone from '../../assets/images/smartphone.svg';
import settings from '../../assets/images/settings.svg';
import drag from '../../assets/images/drag.svg';
import responsive from '../../assets/images/responsive.svg';
import growBusiness from '../../assets/images/grow-business.svg';
import zeroInvestment from '../../assets/images/zero-investment.svg';
import aNormal from '../../assets/images/addressing-normal.svg';
import BusinessUseCase from '../sections/BusinessUseCase';
import ScheduleDemo from '../sections/ScheduleYourDemo';
import Footer from '../sections/Footer';
import './EStore.scss';
import main from '../main';
import { GooglePlayButton } from '../common/qbStrap';

const $ = window.$;

let bullets1 = [
    {title: 'Sell online', desc: 'Increase your reach and start selling across towns and states, with no limitation on the number of products you sell.'},
    {title: 'Catalog discovery', desc: 'A fast, safe, and intuitive way for your customers to discover your catalog by simply scanning the eStore QR code.'},
    {title: 'In-store ordering', desc: 'Minimize the physical contact by letting customers view, order, and pay at the store using their mobile in seconds.'},
    {title: 'Customizable delivery options', desc: 'Customize and provide COD, Pick up, or Home delivery service as per your business needs.'},
    {title: 'Accept online payments', desc: 'Get paid via Credit cards, Debit cards, Netbanking, UPI, Wallets directly in your bank account.'},
    {title: 'Book a Visit', desc: 'Allow your customers to book a slot before their visit and limit your hassle to manage long queues in these challenging times.'},
    {title: 'Sync with POS', desc: 'Fully compatible and tightly integrated with QueueBuster POS application to remove any scope of human error.'}
]

class EStore extends Component {
    render() {
        return (
            <div id="eStore">
                <Header />

                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: ' 0 8.3% 90px 8.3%'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap', flexBasis: '47.03%'}}>
                            <img loading={'lazy'} src={eStore} style={{height: '45px', margin: '6px 13px 0 0'}} />
                            <p style={{
                                fontSize: '39px',
                                fontWeight: 'bold',
                                lineHeight: 1.05,
                                letterSpacing: '-0.37px',
                                color: '#242424',
                                flexBasis: '100%',
                                margin: '30px 0 30px 0'
                            }}>We care for your customers</p>
                            <p style={{
                                fontSize: '21px',
                                lineWeight: 1.73,
                                color: '#242424',
                                flexBasis: '100%',
                                marginBottom: '20px'
                            }}>QueueBuster brings you QB eStore to take your business online and enable digital ordering. A smart way for your customers to order contactless and queue less.</p>
                            <GooglePlayButton styles={{width: '207px'}} />
                        </div>
                        <img loading={'lazy'} src={top} style={{width: '47.03%', height: 'auto'}} />
                    </div>
                    <p style={{
                        margin: '0 8.3% 0 8.3%',
                        WebkitTextStroke: '0.4px #979797',
                        fontFamily: 'Roboto-Italic',
                        fontSize: '25px',
                        fontStyle: 'italic',
                        lineHeight: 1.8,
                        letterSpacing: '-0.23px',
                        textAlign: 'center',
                        color: '#7b7f82'
                    }}>
                        QB eStore is an extension of QueueBuster POS, which empowers you to sell online, allow in-store ordering, offer pickup or local delivery, and accept payments.
                    </p>
                </div>

                <div style={{padding: '0 8.3% 0 8.3%', margin: '90px 0 76px'}}>
                    <p style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        letterSpacing: '-0.64px',
                        textAlign: 'center',
                        color: '#212b36',
                        marginBottom: '30px'
                    }}>
                        Power up your business with QB eStore
                    </p>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                        <div style={{width: '41.66%', paddingTop: '43px'}}>
                            {
                                bullets1.map(v => (
                                    <div style={{marginBottom: '30px'}}>
                                        <div style={{marginBottom: '2px'}}>
                                            <img loading={'lazy'} src={check} style={{height: '18px', width: '18px', marginRight: '10px'}} />
                                            <span style={{
                                                fontSize: '20px',
                                                fontWeight: 600,
                                                color: '#2c2e30'
                                            }}>{v.title}</span>
                                        </div>
                                        <p 
                                            style={{
                                                marginBottom: 0, 
                                                paddingLeft: '28px',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                lineHeight: 1.38,
                                                letterSpacing: '-0.34px',
                                                color: '#7b7f82'
                                            }}>{v.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <img loading={'lazy'} src={iphone} style={{width: '50.66%', height: 'auto'}} />
                    </div>
                </div>
            
                <div style={{backgroundColor: '#498bdc', height: '3px', width: '300px', margin: '0 auto 80px auto'}} />

                <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 8.3%', marginBottom: '100px'}}>
                    <div style={{width: '28.7%'}}>
                        <img loading={'lazy'} src={growBusiness} style={{height: '80px', width: '80px', margin: '0 auto 20px auto', display: 'block'}} />
                        <p style={{
                            fontSize: '22px',
                            fontWeight: 600,
                            textAlign: 'center',
                            color: '#2c2e30',
                            marginBottom: '10px'
                        }}>Grow Your Business</p>
                        <p style={{
                            fontSize: '16px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                            color: '#7b7f82',
                            marginBottom: 0
                        }}>Shopping is moving online in a big way and you must not miss this opportunity. It's the perfect time to move in. Use QB eStore to build your online store and start selling online today.</p>
                    </div>
                    <div style={{width: '28.7%'}}>
                        <img loading={'lazy'} src={zeroInvestment} style={{height: '80px', width: '80px', margin: '0 auto 20px auto', display: 'block'}} />
                        <p style={{
                            fontSize: '22px',
                            fontWeight: 600,
                            textAlign: 'center',
                            color: '#2c2e30',
                            marginBottom: '10px'
                        }}>Zero Technology Investment</p>
                        <p style={{
                            fontSize: '16px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                            color: '#7b7f82',
                            marginBottom: 0
                        }}>Digital transformation is not a cheap investment due to the time & effort required to build it from scratch. With QB eStore, you can start your online store within minutes & with absolute zero technology investment.</p>
                    </div>
                    <div style={{width: '28.7%'}}>
                        <img loading={'lazy'} src={aNormal} style={{height: '80px', width: '80px', margin: '0 auto 20px auto', display: 'block'}} />
                        <p style={{
                            fontSize: '22px',
                            fontWeight: 600,
                            textAlign: 'center',
                            color: '#2c2e30',
                            marginBottom: '10px'
                        }}>Addressing the New Normal</p>
                        <p style={{
                            fontSize: '16px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                            color: '#7b7f82',
                            marginBottom: 0
                        }}>With QB eStore, you can open multiple ways for your customers to interact with your brand. Use it as an eCommerce application for your business or an in-store ordering application for contactless ordering.</p>
                    </div>
                </div>

                <div style={{backgroundColor: '#f3f7fd', padding: '57px 8.3% 80px 8.3%', display: 'flex', flexWrap: 'wrap'}}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: 600,
                        textAlign: 'center',
                        color: '#2c2e30',
                        marginBottom: '80px',
                        flexBasis: '100%'
                    }}>Take your store online in minutes</p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '10px',
                                border: 'solid 2px #498bdc',
                                backgroundColor: '#ffffff',
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={smartphone} style={{display: 'block', margin: '20px auto 0 auto', height: '40px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Download Our App
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0
                            }}>
                                Download the QueueBuster POS application from the Google play store.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '10px',
                                border: 'solid 2px #498bdc',
                                backgroundColor: '#ffffff',
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={settings} style={{display: 'block', margin: '20px auto 0 auto', height: '40px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Enable QB eStore
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0
                            }}>
                                Enable the eStore feature from the POS application to start managing your eStore.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '10px',
                                border: 'solid 2px #498bdc',
                                backgroundColor: '#ffffff',
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={drag} style={{display: 'block', margin: '20px auto 0 auto', height: '40px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Add Catalog
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0
                            }}>
                                Prepare & publish the catalog of your products for customers to browse.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '10px',
                                border: 'solid 2px #498bdc',
                                backgroundColor: '#ffffff',
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={responsive} style={{display: 'block', margin: '20px auto 0 auto', height: '40px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                fontWeight: 600,
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Start Selling Online
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0
                            }}>
                                Now, share and promote your online store for more customers to discover.
                            </p>
                        </div>
                    </div>
                    <GooglePlayButton styles={{margin: window.innerWidth > 800 ? '57px auto 0 auto' : '0 auto 40px auto', width: window.innerWidth > 800 ? '207px' : '167px'}} />
                </div>

                <BusinessUseCase />
                <div style={{height: '30px'}}></div>
                <ScheduleDemo />
                <Footer />
            </div>
        )
    }

    componentDidMount() {
        main();

        $('#eStore>div:nth-child(2)').css('padding-top', $('#main_header').height() + (window.innerWidth < 800 ? 30 : 46));
    }
}

export default EStore;