import CustomIndustryWiseFormV2 from "./CustomIndustryWiseFormV2";
import React from "react";
import NewIndustryWiseForms from "./CustomIndustryWiseFormsDesktopV2";

const RetailFormV2 = (props) => {
   const screenWidth = window.screen.width;
   const outletTypes = [
      "Fashion & Apparel",
      "Cosmetics",
      "Footwear",
      "Accessories",
      "Gift Shops",
      "Carts & Kiosks",
   ];

   const features = [
      {
         title: "Billing",
         content: "Create a catalog of products to bill your customers with ease",
      },
      {
         title: "CRM & Loyalty",
         content: "A suite of CRM & Loyalty tools to manage your customers",
      },
      {
         title: "Inventory Management",
         content: "Record & manage your inventory across all locations in one place",
      },
      {
         title: "eStore",
         content: "Create your own website & start selling online within minutes",
      },
      {
         title: "Integration",
         content: "50+ software & hardware integrations to manage your business",
      },
      {
         title: "And many more...",
         content: "",
      },
   ];
   if(screenWidth<900){
      return(
         <CustomIndustryWiseFormV2 outletTypes={outletTypes} features={features} resType={"Retail"} color={"#BA4E42"}  {...props} />
      )
   }else{
      return(
         <NewIndustryWiseForms outLetType={outletTypes} features={features} resType={"Retail"} color={"#BA4E42"}/>
      )
   }
};
export default RetailFormV2;
