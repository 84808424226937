import React,{Component} from 'react'
import Header from '../common/Header'
import CompleteSolution from '../sections/CompleteSolution';
import EnterpriseSolutions from '../sections/EnterpriseSolutions'
import QuerySection from '../sections/QuerySection'
import Footer from '../sections/Footer';
import './solutions.scss';
import main from '../main';

class LandingPage extends Component{
    render(){
        return(
            <div>
                <Header />
                <CompleteSolution />
                <EnterpriseSolutions />
                <QuerySection />
                <Footer />
            </div>
        )
    }

    componentDidMount() {
        main();
    }
}

export default LandingPage