import React, {Component} from 'react';
import './CompleteSolution.scss'
import com_sol from '../../assets/images/complete_solution.webp';

const $ = window.$;

class CompleteSolution extends Component {
    render() {
        return (
            <div id="complete_solution">
                <div style={{flexBasis: '51.34%'}}>
                    <h3>The Complete Solution</h3>
                    <p>QueueBuster app can be deployed on any Android device. However, here are a few recommended solutions to help you with your decision.</p>
                </div>
                <div style={{flexBasis: '44.54%'}}>
                    <img loading={'lazy'} src={com_sol} alt=""/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        $('#complete_solution').css('margin-top', $('#main_header').height() + (window.innerWidth < 800 ? 10 : 0));
    }
}

export default CompleteSolution;