import React from 'react';
// import bg from '../../assets/images/independenceday_web.webp';
// import bgMob from '../../assets/images/independenceday_mob.webp';
// import freedom50 from '../../assets/images/freedom50.svg';
import './TopBanner.scss';

const $ = window.$;
let showFunding = false;
const today = new Date()
const fundingNewsStart = new Date('2022-06-13 08:00:00')
const fundingNewsEnd = new Date('2022-07-31 23:59:59')
if (today >= fundingNewsStart && today <= fundingNewsEnd) {
    showFunding = true
}
let buttonStyle = {}

if (window.innerWidth < 800) {
    buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default () => (
    // {/* <div id="top_banner" style={{background: window.innerWidth >= 800 ? `url(${bg})` : `url(${bgMob})`, width: '100%', padding: '12px 0', display: new Date() < new Date(fundingNewsTill) ? (window.innerWidth > 800 ? 'flex' : 'block') : 'none', justifyContent: 'center', alignItems: 'center', backgroundSize: '100% 100%'}}> */}
    <div id="top_banner" style={{ background: 'rgb(243, 247, 253)', width: '100%', padding: '12px 0', display: showFunding ? (window.innerWidth > 800 ? 'flex' : 'block') : 'none', justifyContent: 'center', alignItems: 'center', backgroundSize: '100% 100%'}}>
        <p style={{marginRight: '4px'}}>Announcing $8.16M in Series A</p>
        <div style={buttonStyle}><a href='https://blogs.queuebuster.co/bafb09214b87?source=friends_link&sk=92aeafe5c50f827e78e82a2a4ba763da' target={'_blank'}>Read More</a></div>
        {/* <img src={freedom50} style={{width: '102px'}} /> */}
        {/* <a href="#" onClick={(e) => {e.preventDefault(); $('#hta-modal').modal('show')}}>Click here</a> */}
    </div>
)