import React, { useState } from "react";
import {
   Carousel,
   CarouselItem,
   CarouselControl,
   CarouselIndicators,
   CarouselCaption,
} from "reactstrap";
import allInOne from "../../assets/images/All-In-One.png";
import grocery from "../../assets/images/groceryNew.png";
import retail from "../../assets/images/Retail_Slider.png";
import restaurant from "../../assets/images/RestaurantNew.png";
import "./NewPages.css";
import NewLandingPgeFormMobile from "./NewLandingPgeFormMobile";
const items = [
   {
      src: allInOne,
      altText: "Slide 1",
      caption: "Slide 1",
      key: 1,
   },
   {
      src: grocery,
      altText: "Slide 2",
      caption: "Slide 2",
      key: 2,
   },
   {
      src: retail,
      altText: "Slide 3",
      caption: "Slide 3",
      key: 3,
   },
   {
      src: restaurant,
      altText: "Slide 4",
      caption: "Slide 4",
      key: 4,
   },
];
const NewOnePagerMoboile = (args) => {
   const [activeIndex, setActiveIndex] = useState(0);
   const [animating, setAnimating] = useState(false);

   const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
   };

   const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
   };

   const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
   };

   const slides = items.map((item) => {
      return (
         <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
         >
            <img src={item.src} style={{ borderRadius: "2% 2% 0 0" }} alt={item.altText} />
            <CarouselCaption />
         </CarouselItem>
      );
   });
   return (
      <>
         <div
            style={{
            //    padding: "5% 8% 5% 8%",
            padding: "5% 0 5% 0",
               background:"#cbcbd5",
               background: "linear-gradient(130deg,rgb(8, 47, 137), rgba(0, 0, 0, 0.8))",
            //    height: "100vh",
            }}
         >
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                //   border:"2px solid red"
               }}
            >
               {/* <div style={{ width: "100%", height: "100%",border:"2px solid red" }}> */}
                  <Carousel
                     slide={true}
                     enableTouch={true}
                     activeIndex={activeIndex}
                     next={next}
                     previous={previous}
                     {...args}
                  >
                     <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                        className={"CarouselIndicators"}
                     />
                     {slides}
                     {/* <CarouselControl
                           direction="prev"
                           directionText="Previous"
                           onClickHandler={previous}
                        /> */}
                     {/* <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
                  </Carousel>
               {/* </div> */}
               <div
               //   style={{
               //      width: "35%",
               //      height: "80%",
               //      // marginBottom: "30px",
               //      // border:"2px solid red"
               //   }}
               >
                  <NewLandingPgeFormMobile />
               </div>
            </div>
         </div>
      </>
   );
};
export default NewOnePagerMoboile;
