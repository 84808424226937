import React, {Component} from 'react';

import StartTrialTop from './StartTrialTop';
import Features from './Features';
import './GradientSection.scss';

import mostPopular from '../../assets/images/most-popular-software-2022.png'
import Brands from './Brands';
class GradientSection extends Component {
    render() {
        return (
            <div id="gradient_section" style={{
                    position: 'relative'
                }}>
                <StartTrialTop />
                <Brands />
                <div id="best_value_award" style={{padding: '30px 0 40px 0', backgroundColor: '#f3f7fd'}}>
                    <img alt='Software Suggest' src={mostPopular} style={{width: '200px', margin: 'auto', marginBottom: '18px', display: 'block', cursor: 'pointer'}} onClick={() => {window.open('https://www.softwaresuggest.com/queuebuster-pos')}} />
                    <p style={{marginBottom: '12px', fontSize: '30px', fontWeight: 'bold', letterSpacing: '-0.64px', color: '#212b36', textAlign: 'center'}}>Most Popular Software - 2022</p>
                    <p style={{marginBottom: '0px', fontSize: '20px', lineHeight: '1.2', color: '#7b7f82', textAlign: 'center'}}>SoftwareSuggest Recognition Awards 2022 🏆</p>
                </div>
                <Features />
            </div>
        );
    }
}

export default GradientSection;

