import React from "react";
import Logo from "../../assets/images/trustedMerchants/qbLogo.png";
import backgroundImage from "../../assets/images/onePageBackground/retail.png";

import khata from "../../assets/images/onePagerIcons/groceryKhata.svg";
import MobileLandingPageFormV2 from "./IndustryFormMobileV2";
import resturantBackground from "../../assets/images/onePageBackground/restaurantLarge.png";
import retailBackground from "../../assets/images/onePageBackground/retailLarge.png";
import spaBackground from "../../assets/images/onePageBackground/sallon.png";
import groceryBackground from "../../assets/images/onePageBackground/grocery.png";
import electronicBackground from "../../assets/images/onePageBackground/electronics.png";
import grayBackground from "../../assets/images/avatar.jpeg";
import groceryCrm from "../../assets/images/onePagerIcons/groceryCrm.svg";
import groceryEstore from "../../assets/images/onePagerIcons/groceryEstore.svg";
import groceryInventory from "../../assets/images/onePagerIcons/groceryInventory.svg";
import groceryKhata from "../../assets/images/onePagerIcons/groceryKhata.svg";
import groceryManyMore from "../../assets/images/onePagerIcons/groceryManyMore.svg";
import groceryQuickBill from "../../assets/images/onePagerIcons/groceryQuickBill.svg";
import elecBilling from "../../assets/images/onePagerIcons/elecBilling.svg";
import elecCRM from "../../assets/images/onePagerIcons/elecCRM.svg";
import elecEstore from "../../assets/images/onePagerIcons/elecEstore.svg";
import elecInventory from "../../assets/images/onePagerIcons/elecInventory.svg";
import elecMore from "../../assets/images/onePagerIcons/elecMore.svg";
import elecBank from "../../assets/images/onePagerIcons/electBank.svg";
import retailBilling from "../../assets/images/onePagerIcons/retailBilling.svg";
import retailCRM from "../../assets/images/onePagerIcons/retailCRM.svg";
import retailIntegration from "../../assets/images/onePagerIcons/retailIntegration.svg";
import retailEstore from "../../assets/images/onePagerIcons/retailEstore.svg";
import retailMore from "../../assets/images/onePagerIcons/retailMore.svg";
import retailInventory from "../../assets/images/onePagerIcons/retailInventory.svg";
import resMenu from "../../assets/images/onePagerIcons/resMenu.svg";
import resMore from "../../assets/images/onePagerIcons/resMore.svg";
import resRescipe from "../../assets/images/onePagerIcons/resRescipe.svg";
import resTable from "../../assets/images/onePagerIcons/resTable.svg";
import resCRM from "../../assets/images/onePagerIcons/resCRM.svg";
import resIntegration from "../../assets/images/onePagerIcons/resIntegration.svg";
import sallonAppoin from "../../assets/images/onePagerIcons/sallonAppoin.svg";
import sallonEstorem from "../../assets/images/onePagerIcons/sallonEstorem.svg";
import sallonInventory from "../../assets/images/onePagerIcons/sallonInventory.svg";
import sallonMembership from "../../assets/images/onePagerIcons/sallonMembership.svg";
import sallonMore from "../../assets/images/onePagerIcons/sallonMore.svg";
import sallonCRM from "../../assets/images/onePagerIcons/sallonCRM.svg";
import hul from "../../assets/images/trustedMerchants/hul.png";
import itc from "../../assets/images/trustedMerchants/itc.png";
import gullynetwork from "../../assets/images/trustedMerchants/gullyNetwork.png";
import heineken from "../../assets/images/trustedMerchants/heineken.png";
import irctc from "../../assets/images/trustedMerchants/irctc.png";
import reshamandi from "../../assets/images/trustedMerchants/reshaMandi.png";
import candor from "../../assets/images/trustedMerchants/candorFoods.png";
import tajsats from "../../assets/images/trustedMerchants/tajsats.png";
import jain from "../../assets/images/trustedMerchants/jainLarge.png";
import emoi from "../../assets/images/trustedMerchants/emoiLarge.png";
import creamica from "../../assets/images/trustedMerchants/cremicaLarge.png";
import ghee from "../../assets/images/trustedMerchants/gheeLarge.png";
import teaThekaLarge from "../../assets/images/trustedMerchants/teaThekaLarge.png";
import tangeloLarge from "../../assets/images/trustedMerchants/tangeloLarge.png";
import gulati from "../../assets/images/trustedMerchants/theGulatiLarge.png";
import cenabeansLarge from "../../assets/images/trustedMerchants/canabeansLarge.png";
import mcd from "../../assets/images/trustedMerchants/mcd.png";
import cityWalk from "../../assets/images/trustedMerchants/selectCityWalkLarge.png";
import "./CustomIndustryWiseFormV2.css";
import { Button } from "reactstrap";
import ALlinOnePos from "../../assets/images/avatarNew.jpeg"


const CustomIndustryWiseFormV2 = (props) => {
   let { outletTypes, features, resType, color, contentColor } = props;
   let brands = [
      hul,
      itc,
      gullynetwork,
      heineken,
      irctc,
      reshamandi,
      candor,
      tajsats,
      mcd,
      cityWalk,
   ];
   if (resType == "Restaurant")
      brands = [
         jain,
         emoi,
         heineken,
         creamica,
         tajsats,
         ghee,
         teaThekaLarge,
         tangeloLarge,
         cenabeansLarge,
         gulati,
      ];
   const featuresIcons = {
      Retail: [
         retailBilling,
         retailCRM,
         retailIntegration,
         retailEstore,
         retailMore,
         retailInventory,
      ],
      allinPosNew: [
         retailBilling,
         retailCRM,
         retailIntegration,
         retailEstore,
         retailMore,
         retailInventory,
      ],
      Grocery: [
         groceryKhata,
         groceryEstore,
         groceryInventory,
         groceryCrm,
         groceryQuickBill,
         groceryManyMore,
      ],
      "Spa & Salon": [
         sallonAppoin,
         sallonEstorem,
         sallonInventory,
         sallonCRM,
         sallonMembership,
         sallonMore,
      ],
      Restaurant: [resMenu, resCRM, resTable, resRescipe, resIntegration, resMore],
      Electronics: [elecBilling, elecCRM, elecInventory, elecEstore, elecBank, elecMore],
   };
   const icons = featuresIcons[resType];
   return (
      <div className="newFormV2">
         <div id="queueBusterLogo">
            <div
               style={{
                  backgroundImage: `url(${
                     resType == "Restaurant"
                        ? resturantBackground
                        : resType == "Electronics"
                        ? electronicBackground
                        : resType == "Grocery"
                        ? groceryBackground
                        : resType == "Retail"
                        ? retailBackground
                        : resType == "Spa & Salon"
                        ? spaBackground
                        : resType == "allinPosNew"
                        ? ALlinOnePos
                        : grayBackground
                  })`,
                  // height: window.screen.height < 690 ? "85vh" : "77vh",
                  // marginBottom: window.screen.height < 690 ? "2rem" : "0px",
                  width: "100vw",
                  fontSize: "30px",
                  backgroundPosition: "left",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  opacity: 4,
               }}
            >
               <div style={{ padding: "12px 20px 10px 20px" }}>
                  <div
                     style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // padding: "10px 10px 10px 10px",
                     }}
                  >
                     <img src={Logo} className="logoForTab" />
                     <div className="phoneNoOuter">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 24 24"
                           className="mobileIcon"
                           style={{
                              fill: "rgba(0, 0, 0, 1)",
                              backgroundColor: "white",
                              borderRadius: "50%",
                           }}
                        >
                           <path d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z"></path>
                        </svg>
                        <span className="phoneNo">{`+91 78 76 180 180`}</span>
                     </div>
                  </div>
                  <div style={{ paddingTop: "1rem" }}>
                     <span className="headingOnePager" style={{color:contentColor&&contentColor}}>India’s </span>
                     <span style={{ color: color }} className="subHeadingOnePager">
                        No.1
                     </span>
                  </div>
                  <h1 style={{ color:contentColor?contentColor: "#1D1B1B" }} className="possApp">
                     {resType=="allinPosNew"?"All-In-One":resType} POS App
                  </h1>
                  <div className="outletTypes" style={{color:contentColor&&contentColor}}>{resType=="allinPosNew"?"Businees Type":"Outlet Types"}</div>
                  <div style={{ marginBottom: "16px",color:contentColor&&contentColor }} className="differentOutletTypes">
                     {outletTypes.join(" | ")}| & many more
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                     <Button
                        style={{
                           // width: "calc(100% - 1rem)",
                           width: "100%",
                           background: "#3C69AF 0% 0% no-repeat padding-box",
                           height: "3rem",
                           marginTop: "1rem",
                           marginBottom: "2rem",
                           bottom: "10px",
                        }}
                        onClick={() => {
                           var element = document.getElementById("formTemplate").scrollIntoView({
                              behavior: "smooth",
                           });
                        }}
                     >
                        Get Free Demo
                     </Button>
                  </div>
               </div>
            </div>
            <div style={{ paddingLeft: "20px", paddingRight: "30px" }}>
               <h4 style={{ color: "#1D1B1B", fontWeight: "bold", paddingTop: "12px" }}>
                  Features
               </h4>
               <div>
                  {features.map((item, idx) => {
                     return (
                        <div style={{ display: "flex", flexDirection: "row", paddingTop: "1rem" }}>
                           <img
                              src={icons && icons[idx]}
                              alt={"icon"}
                              style={{ height: "3rem", width: "3rem", marginRight: "1rem" }}
                           />
                           <div>
                              <h5 style={{ fontWeight: "bold", color: "#1D1B1B" }}>{item.title}</h5>
                              <p style={{ lineHeight: "normal" }}>{item.content}</p>
                           </div>
                        </div>
                     );
                  })}
               </div>
               <h5 style={{ paddingTop: "1.8rem", paddingBottom: "1.1rem" }}>
                  <span style={{ fontWeight: "bold", color: "#1D1B1B" }}>Trusted by</span>{" "}
                  <span style={{ fontWeight: "bold", color: color }}>50,000+ Merchants</span>
               </h5>
               <div
                  style={{
                     display: "grid",
                     gridTemplateColumns: window.screen.width > 750 ? "33% 33% 33%" : "50% 50%",
                     // display: "flex",
                     // flexDirection: "row",
                     // flexWrap: "wrap",
                     justifyContent: "space-evenly",

                     // alignItems: "flex-start",
                     // gap: "1.1rem",
                     paddingTop: "1rem",
                     marginBottom: "2.2rem",
                  }}
               >
                  {brands.map((item) => {
                     return (
                        <div
                           style={{
                              height: "50px",
                              width: "auto",
                              marginBottom: "0.8rem",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                              boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                              // boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
                              // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                              // border: "0.5px solid #F2F2F2",
                           }}
                        >
                           <center>
                              {/* <div>Hi</div> */}
                              <img
                                 src={item}
                                 style={{
                                    height: resType === "Restaurant" ? "2.8rem" : "4.3rem",
                                    width: "auto",
                                 }}
                              />
                           </center>
                        </div>
                     );
                  })}
               </div>
            </div>
            <div style={{ padding: "12px 20px 50px 20px" }}>
               <MobileLandingPageFormV2 resType={resType} {...props} />
            </div>
         </div>
      </div>
   );
};

export default CustomIndustryWiseFormV2;
