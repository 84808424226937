import CustomIndustryWiseFormV2 from "./CustomIndustryWiseFormV2";
import React from "react";
import NewIndustryWiseForms from "./CustomIndustryWiseFormsDesktopV2";

const RestaurantFormV2 = (props) => {
   const screenWidth = window.screen.width;
   const outletTypes = [
      "Fine Dine ",
      "QSR",
      "Casual Dining ",
      "Cloud",
      "Kitchen",
      "Food Trucks & many more..",
   ];

   const features = [
      {
         title: "Menu Management",
         content: "Manage items, modifiers, combos,and recipes from a single app",
      },
      {
         title: "CRM & Loyalty",
         content: "A suite of CRM & Loyalty tools to manage your customers",
      },
      {
         title: "Table Management",
         content: "Create & manage tables for Fine Dining and Casual Dining restaurants",
      },
      {
         title: "Recipe Management",
         content: "Maintain recipes across multiple outlets for accurate inventory mgmt.",
      },
      {
         title: "Integration",
         content: "50+ software & hardware integrations to manage your business",
      },
      {
         title: "And many more...",
         content: "",
      },
   ];
   if(screenWidth<900){
      return (
         <>
            <CustomIndustryWiseFormV2
               resType={"Restaurant"}
               outletTypes={outletTypes}
               features={features}
               color={"#01646E"}
               {...props}
            />
         </>
      );
   }else{
      return(
         <NewIndustryWiseForms outLetType={outletTypes} features={features} resType={"Restaurant"}   color={"#01646E"}/>
      )
   }

};

export default RestaurantFormV2;
