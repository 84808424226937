import React, { Component, useState } from "react";
import { CountryCodeSelect } from "../common/qbStrap";
import { Button, Card, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const $ = window.$;
const FormIndustryWiseV2 = (props) => {
   const history = useHistory();
   const [state, setState] = useState({
      fullname: "",
      mobileNo: "",
      email: "",
      mobileNoError: false,
      emailError: false,
      nameError: false,
      formResponse: false,
      submittingForm: false
   });
   const handleChangeName = (e) => {
      setState({ ...state, fullname: e.target.value });
   };
   const handleChangeMobile = (e) => {
      setState({ ...state, mobileNo: e.target.value, mobileNoError: false });
   };
   const handleChangeEmail = (e) => {
      setState({ ...state, emailError: false, email: e.target.value });
   };
   const apiCall = () => {
      var EMAIL_VALIDATION_REGEX =
         /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      let { fullname, mobileNo, email } = state;
      if (!fullname.length) {
         setState({ ...state, nameError: true });
         return;
      }
      if (mobileNo[0] == 0) {
			mobileNo = mobileNo.slice(1, mobileNo.length)
		}
      if (mobileNo.length != 10) {
         setState({ ...state, mobileNoError: true });
         return;
      }
      if (email.match(EMAIL_VALIDATION_REGEX) == null) {
         setState({ ...state, emailError: true });
         return;
      }
      setState({
			submittingForm: true
		})
      let obj = {
         name: fullname,
         phoneCode: 91,
         mobile: mobileNo,
         email: email,
         purpose: props.purpose,
      };
      fetch(window.location.origin + "/mail/demo", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
         },
         body: JSON.stringify(obj),
      })
         .then((res) => {
            const data = { resType: props.purpose, fullname: state.fullname };
            history.push("/thankyou", data);
         })
         .catch((e) => console.log("e", e));
   };

   return (
      <div
         style={{
            backgroundColor: "#F4F9FE",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            // border:'1px solid red',
            // paddingBottom:'1rem',
            // height: "55vh",

            alignItems: "left",
            borderRadius: "0.6rem",
         }}
      >
         <h4
            style={{
               textAlign: "left",
               margin: "2rem 0 0 2rem",
               fontStyle: "normal",
               fontVariant: "normal",
               fontWeight: "bold",
               fontSize: "1.56rem",
               lineHeight: "",
               fontFamily: "Poppins, sans-serif",
               color: "#767881",
               letterSpacing: "0%",
            }}
         >
            Request a Demo
         </h4>
         <p
            style={{
               textAlign: "left",
               margin: "0.4rem 0 0 2rem",
               fontStyle: "normal",
               fontVariant: "normal",
               fontWeight: "500",
               fontSize: "1rem",
               lineHeight: "",
               fontFamily: "Poppins, sans-serif",
               color: "#767881",
               letterSpacing: "0%",
            }}
         >
            Our experts are here to help
         </p>
         <Form className="form" style={{ height: "80%", padding: "1rem 2rem 1rem 2rem" }}>
            <FormGroup>
               <Input
                  required={true}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full name"
                  style={
                     state.nameError && !state.fullname
                        ? {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                             boxShadow: "0 0 0.5em red",
                          }
                        : {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                          }
                  }
                  onChange={(e) => handleChangeName(e)}
               />
            </FormGroup>
            <FormGroup>
               <Input
                  type="number"
                  name="mobileNo"
                  id="mobileNo"
                  placeholder="Mobile Number"
                  style={
                     state.mobileNoError
                        ? {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                             boxShadow: "0 0 0.5em red",
                          }
                        : {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                          }
                  }
                  onChange={(e) => {
                     handleChangeMobile(e);
                  }}
                  required={true}
               />
            </FormGroup>
            <FormGroup>
               <Input
                  type="text"
                  name="email"
                  id="emailId"
                  placeholder="E-mail ID"
                  style={
                     state.emailError
                        ? {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                             boxShadow: "0 0 0.5em red",
                          }
                        : {
                             backgroundColor: "#F4F9FE",
                             fontSize: "1.3rem",
                             height: "3rem",
                             borderRadius: "0.4rem",
                          }
                  }
                  onChange={(e) => {
                     handleChangeEmail(e);
                  }}
               />
            </FormGroup>
            <p
               style={{
                  // margin: " 0 0 0",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontSize: "1rem",
                  lineHeight: "1.3rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#767881",
                  letterSpacing: "0%",
               }}
            >
               {" "}
               By submitting my details, I agree to be contacted by QueueBuster via Newsletter,
               Email, WhatsApp, SMS, Phone etc.
            </p>
            <Button
               disabled = {state.submittingForm}
               onClick={apiCall}
               style={{
                  width: "100%",
                  // margin: "8% 0 0 0",
                  backgroundColor: "#3C69AF",
                  borderRadius: "6px",
                  // boxShadow: "0px 16px 32px #FB768829",
                  border: "none",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                  height: "3rem",
                  fontSize: "1.2rem",
               }}
            >
               {state.submittingForm ? "Saving..." : "Submit"}
            </Button>
         </Form>
      </div>
   );
};

export default FormIndustryWiseV2;
