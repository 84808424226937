import { combineReducers } from 'redux';

import {FETCH_SUPPORT_DATA, CHANGE_BUSINESS_TYPES_STATE} from '../actions/types';

export default combineReducers({
    categories: function(state = null, action) {
        switch(action.type) {
            case FETCH_SUPPORT_DATA:
                return action.payload.categories;
            default:
                return state;
        }
    },
    
    subCategories: function(state = null, action) {
        switch(action.type) {
            case FETCH_SUPPORT_DATA:
                return action.payload.subcategories;
            default:
                return state;
        }
    },

    articles: function(state = null, action) {
        switch(action.type) {
            case FETCH_SUPPORT_DATA:
                return action.payload.articles;
            default:
                return state;
        }
    },

    externalBTState: function(state = null, {type, payload}) {
        switch (type) {
            case CHANGE_BUSINESS_TYPES_STATE:
                return {newState: payload, isApplied: false};
            default:
                return state;
        }
    }
}); 