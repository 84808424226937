import React from "react";
import engageBackgroundImage from "../../assets/images/engage/engageBackground.png";
import engageQbLogo from "../../assets/images/engage/engageQbLogo.svg";
import engageHeading from "../../assets/images/engage/engageHeading.png";
import engageBottomLine from "../../assets/images/engage/engageBottomLine.svg";
import engageLinkedin from "../../assets/images/engage/engageLinkedin.png";
import engageTwitter from "../../assets/images/engage/engageTwitter.png";
import engageFacebook from "../../assets/images/engage/engageFacebook.png";
import engageInsta from "../../assets/images/engage/engageInsta.png";
import engageYoutube from "../../assets/images/engage/engageYoutube.png";
import CountdownTimer from "../../utils/CountdownTimer";
import engageMainLogo from "../../assets/images/engage/engageMainLogo.png";

const EngagePampletMobile = () => {
   return (
      <div>
         <div
            style={{
               padding: "1.87rem",
               backgroundImage: `url(${engageBackgroundImage})`,
               // height: window.screen.height < 690 ? "85vh" : "77vh",
               // marginBottom: window.screen.height < 690 ? "2rem" : "0px",
               width: "100vw",
               fontSize: "30px",
               backgroundPosition: "left",
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               // opacity: 4,
            }}
         >
            <center>
               <img src={engageMainLogo} style={{ height: "18.75rem", marginBottom: "1.8rem" }} />
               <img src={engageQbLogo} style={{ height: "3.75rem", marginBottom: "1.8rem" }} />
            </center>
            <div style={{ fontSize: "1.5rem", color: "#FFFFFF", marginBottom: "1.8rem" }}>
               Presents
            </div>
            <img src={engageHeading} style={{ height: "2.3rem", marginBottom: "9px" }} />
            <div>
               <img src={engageBottomLine} style={{ width: "18.75rem", marginBottom: "1.8rem" }} />
            </div>
            <CountdownTimer date={new Date("2023-06-24T00:00:00")} />
            <div style={{ marginBottom: "1.8rem" }}>
               <div style={{ fontSize: "2rem", color: "#FFFFFF" }}>JW Marriott, Aerocity,</div>
               <div style={{ fontSize: "2rem", color: "#FFFFFF" }}>New Delhi</div>
            </div>
            <div
               style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  marginBottom: "2.5rem",
               }}
            >
               24<sup>th</sup> June 2023
            </div>
            <div style={{ display: "flex", gap: "1rem", marginBottom: "2.5rem" }}>
               <img
                  style={{ height: "2.5rem" }}
                  src={engageLinkedin}
                  onClick={() => {
                     window.open("https://www.linkedin.com/company/queuebuster-pos/mycompany/");
                  }}
               />
               <img
                  style={{ height: "2.5rem" }}
                  src={engageTwitter}
                  onClick={() => {
                     window.open("https://twitter.com/QueueBusterPOS");
                  }}
               />
               <img
                  style={{ height: "2.5rem" }}
                  src={engageFacebook}
                  onClick={() => {
                     window.open("https://www.facebook.com/queuebuster/");
                  }}
               />
               <img
                  style={{ height: "2.5rem" }}
                  src={engageInsta}
                  onClick={() => {
                     window.location.assign("https://www.instagram.com/queuebuster_pos/");
                  }}
               />
               <img
                  style={{ height: "2.5rem" }}
                  src={engageYoutube}
                  onClick={() => {
                     window.open("https://www.youtube.com/channel/UC28aqzrEBKvmuDNH7CMidhg");
                  }}
               />
            </div>
            <div style={{ fontWeight: "300", fontSize: "20px", color: "#FFFFFF" }}>
               © 2023 | All rights reserved
            </div>
         </div>
      </div>
   );
};

export { EngagePampletMobile };
