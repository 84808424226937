import React, {Component} from 'react';
import './SignupModal.scss';
import qbMobile from '../../assets/images/mobile_qb_icon.svg';
import {CountryCodeSelect} from '../common/qbStrap';
import cross from '../../assets/images/cross.svg';

const $ = window.$;

class SignupModal extends Component {
    render() {
        return (
            <div id="signup-modal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img loading={'lazy'} src={qbMobile} style={{height: '36px', width: '36px'}} />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img loading={'lazy'} src={cross} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <h3 className="system-font">Let's Get Started</h3>
                                <p className="system-font">Sign up by creating an account with us</p>
                                <form className="signup-form" id="signup-form">
                                    <input type="hidden" name="routeParam" value="signUp"/>
                                    <input type="hidden" name="useEntity" value="0"/>
                                    <input type="hidden" name="checkDevice" value="0"/>
                                    <input type="hidden" name="mainPage" value="1"/>
                                    <input type="hidden" name="check" id="check2" value = ""/>
                                    <input type="hidden" name="check1" id="check3" value = ""/>
                                    <input type="hidden" name="phoneCode" id="phoneCode2" value = "91"/>
                                    <input type="hidden" name="mobile" id="mobile2" value = ""/>
                                    {/* <div>
                                        <input className="system-font" type="email" placeholder="E-mail" name="email" id="signup-email"/>
                                    </div> */}
                                    <div>
                                        <CountryCodeSelect
                                            inputID={'signup-mobile'}
                                            width={'100%'}
                                            height={'46px'}
                                            margin={'0'}
                                            placeholder={'Mobile number'}
                                            inputStyles={{margin: 0}}
                                            onChange={(value) => {
                                                $('#phoneCode2').val(value.phoneCode);
                                                $('#mobile2').val(value.phoneNumber);
                                                $('#signup-country').val(value.country);
                                            }} />
                                        {/* <input className="system-font" type="text" placeholder="Username" name="username" id="signup-username"/> */}
                                    </div>
                                    <div style={{marginBottom: 0 }}>
                                        {/* <input id="signup-password" type="password" placeholder="Enter password (numeric only)" name="password"/>
                                        <input id="signup-confirm-password" type="password" placeholder="Confirm password" name="confirmPassword" style={{marginBottom: 0}} /> */}
                                        <div id="signup-messages-error" className='signup-messages-error' style={{
                                            marginTop: '8px',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            lineHeight: 1.83,
                                            letterSpacing: '-0.26px',
                                            color: '#ff3636',
                                            height: 'fit-content',
                                            display: 'block',
                                            width: '100%'
                                        }}></div>
                                        <div id="signup-messages" className="system-font signup-messages" style={{
                                            marginTop: '8px',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            lineHeight: 1.83,
                                            letterSpacing: '-0.26px',
                                            color: '#ff3636',
                                            height: 'fit-content',
                                            display: 'block',
                                            width: '100%'
                                        }}></div>
                                    </div>
                                    <div>
                                        <input type="hidden" name="country" id="signup-country" value="India" />
                                    </div>
                                    {/* <p className="system-font">
                                        By signing up on QueueBuster, you are agreeing to our <a href="/terms" style={{fontWeight: 700}} target="_blank">Terms of Use</a> and <a href="/privacy" style={{fontWeight: 700}} target="_blank">Privacy Policy</a>
                                    </p> */}
                                    <button type="submit" className="system-font" style={{ marginTop: 0 }}>Sign Up</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    showLoginModal(e) {
        e.preventDefault();

        $('#signup-modal').modal('hide');
        $('#signup-modal').on('hidden.bs.modal', function() {
            $('#login-modal').modal('show');
            $('#signup-modal').off('hidden.bs.modal')
        })
    }
}

export default SignupModal;