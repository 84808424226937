import React from 'react'
import CustomIndustryWiseForms from './CustomIndustryWiseForms'

const SpaSalonIndustryForm = () => {
  return (
    <>
          <CustomIndustryWiseForms resType={"Spa & Salon"} outLetType={["Speciality Salons","Single & Multi - Outlet Salons","Gyms"] }   featuresType={["Packages & Memberships","Appointments", "Service Slip"]} />
    </>
  )
}

export default SpaSalonIndustryForm