import React from "react";
import LOGO from "../../assets/images/trustedMerchants/qbLogo.png";
import resturantBackground from "../../assets/images/onePageBackground/restaurantLarge.png";
import retailBackground from "../../assets/images/onePageBackground/retailLarge.png";
import spaBackground from "../../assets/images/onePageBackground/sallon.png"
import groceryBackground from "../../assets/images/onePageBackground/grocery.png";
import electronicBackground from "../../assets/images/onePageBackground/electronics.png";
import grayBackground from "../../assets/images/avatar.jpeg";
import groceryCrm from "../../assets/images/onePagerIcons/groceryCrm.svg";
import groceryEstore from "../../assets/images/onePagerIcons/groceryEstore.svg";
import groceryInventory from "../../assets/images/onePagerIcons/groceryInventory.svg";
import groceryKhata from "../../assets/images/onePagerIcons/groceryKhata.svg";
import groceryManyMore from "../../assets/images/onePagerIcons/groceryManyMore.svg";
import groceryQuickBill from "../../assets/images/onePagerIcons/groceryQuickBill.svg";
import elecBilling from "../../assets/images/onePagerIcons/elecBilling.svg";
import elecCRM from "../../assets/images/onePagerIcons/elecCRM.svg";
import elecEstore from "../../assets/images/onePagerIcons/elecEstore.svg";
import elecInventory from "../../assets/images/onePagerIcons/elecInventory.svg";
import elecMore from "../../assets/images/onePagerIcons/elecMore.svg";
import elecBank from "../../assets/images/onePagerIcons/electBank.svg";
import retailBilling from "../../assets/images/onePagerIcons/retailBilling.svg";
import retailCRM from "../../assets/images/onePagerIcons/retailCRM.svg";
import retailIntegration from "../../assets/images/onePagerIcons/retailIntegration.svg";
import retailEstore from "../../assets/images/onePagerIcons/retailEstore.svg";
import retailMore from "../../assets/images/onePagerIcons/retailMore.svg";
import retailInventory from "../../assets/images/onePagerIcons/retailInventory.svg";
import resMenu from "../../assets/images/onePagerIcons/resMenu.svg";
import resMore from "../../assets/images/onePagerIcons/resMore.svg";
import resRescipe from "../../assets/images/onePagerIcons/resRescipe.svg";
import resTable from "../../assets/images/onePagerIcons/resTable.svg";
import resCRM from "../../assets/images/onePagerIcons/resCRM.svg";
import resIntegration from "../../assets/images/onePagerIcons/resIntegration.svg";
import sallonAppoin from "../../assets/images/onePagerIcons/sallonAppoin.svg";
import sallonEstorem from "../../assets/images/onePagerIcons/sallonEstorem.svg";
import sallonInventory from "../../assets/images/onePagerIcons/sallonInventory.svg";
import sallonMembership from "../../assets/images/onePagerIcons/sallonMembership.svg";
import sallonMore from "../../assets/images/onePagerIcons/sallonMore.svg";
import sallonCRM from "../../assets/images/onePagerIcons/sallonCRM.svg";
import hul from "../../assets/images/trustedMerchants/hul.png";
import itc from "../../assets/images/trustedMerchants/itc.png";
import gullynetwork from "../../assets/images/trustedMerchants/gullyNetwork.png"
import heineken from "../../assets/images/trustedMerchants/heineken.png"
import irctc from "../../assets/images/trustedMerchants/irctc.png"
import reshamandi from "../../assets/images/trustedMerchants/reshaMandi.png"
import candor from "../../assets/images/trustedMerchants/candorFoods.png"
import tajsats from "../../assets/images/trustedMerchants/tajsats.png"
import jain  from "../../assets/images/trustedMerchants/jainLarge.png"
import emoi  from "../../assets/images/trustedMerchants/emoiLarge.png"
import creamica  from "../../assets/images/trustedMerchants/cremicaLarge.png"
import ghee  from "../../assets/images/trustedMerchants/gheeLarge.png"
import teaThekaLarge  from "../../assets/images/trustedMerchants/teaThekaLarge.png"
import tangeloLarge  from "../../assets/images/trustedMerchants/tangeloLarge.png"
import gulati  from "../../assets/images/trustedMerchants/theGulatiLarge.png"
import cenabeansLarge  from "../../assets/images/trustedMerchants/canabeansLarge.png"
import mcd from "../../assets/images/trustedMerchants/mcd.png"
import cityWalk from "../../assets/images/trustedMerchants/selectCityWalkLarge.png"
import internet from "../../assets/images/onePagerIcons/internet.svg";
import sms from "../../assets/images/onePagerIcons/smsIcon.svg";
import phone from "../../assets/images/onePagerIcons/phoneIcon.svg";
import { useHistory } from "react-router-dom";
import FormIndustryWiseV2 from "../LandingPage/FormIndustryWiseV2";
import ALlinOnePos from "../../assets/images/avatarNew.jpeg"

const NewIndustryWiseForms = (props) => {
  const history = useHistory();
  let brands= [hul,itc,gullynetwork,heineken,irctc,reshamandi,candor,tajsats,mcd,cityWalk]
  if(props.resType=='Restaurant') brands=[jain,emoi,heineken,creamica,tajsats,ghee,teaThekaLarge,tangeloLarge,cenabeansLarge,gulati]
  const featuresIcons={
    Retail:[retailBilling,retailCRM,retailIntegration,retailEstore,retailMore,retailInventory],
    allinPosNew:[retailBilling,retailCRM,retailIntegration,retailEstore,retailMore,retailInventory],
    Grocery:[groceryKhata,groceryEstore,groceryInventory,groceryCrm,groceryQuickBill,groceryManyMore],
    "Spa & Salon":[sallonAppoin,sallonEstorem,sallonInventory,sallonCRM,sallonMembership,sallonMore],
    Restaurant:[resMenu,resCRM,resTable,resRescipe,resIntegration,resMore],
    Electronics:[elecBilling,elecCRM,elecInventory,elecEstore,elecBank,elecMore] 
  }
  const icons= featuresIcons[props.resType]
  const screenWidth = window.screen.width;
  return (
    <div>
      <div
        style={{
          padding: "2rem 0 0 7.5rem",
          height: "78vh",
          width: "100vw",
          backgroundImage: `url(${
            props.resType == "Restaurant"
              ? resturantBackground
              : props.resType == "Electronics"
              ? electronicBackground
              : props.resType == "Grocery"
              ? groceryBackground
              : props.resType == "Retail"
              ? retailBackground
              : props.resType == "Spa & Salon"
              ? spaBackground
              : props.resType == "allinPosNew"
              ? ALlinOnePos
              : grayBackground
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundAttachment: "fixed",
          backgroundPosition: "center center",
        }}
      >
        <div
          className="pageTypeHeading"
          style={{
            width: "100%",
            // height: "40%",
            marginBottom: "2.5rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img src={LOGO} width="auto" height="48" />
          <div
            style={{
              fontSize: "1.5rem",
              width: "24rem",
              height: "55px",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              backgroundColor: "#212121",
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <img
              src={phone}
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "5px",
                marginLeft: "10px",
              }}
              width="35"
              height="35"
            />
            <a 
              href="tel:+917876180180"
              style={{
                color: "white",
              }}
            >+91 78 76 180 180</a>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "55% 40%",
            gap: "5%",
            // height: "60%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%", marginTop: "3.5rem", }}>
            <span
              style={{
                color: props.contentColor?props.contentColor:"black",
                fontWeight: "300",
                fontSize: "3.6rem",
                fontWeight:'400',
                letterSpacing: "0px",
              }}
            >
              India’s&nbsp;
            </span>{" "}
            <span
              style={{
                fontWeight: "700",
                fontSize: "3.6rem",
                letterSpacing: "0px",
                color:props.color,
                // fontWeight: "Bold Italic",
              }}
            >
              No.1 
            </span>
            <div
              className="appType"
              style={{
                // fontFamily: "Poppins, sans-serif",
                // fontStyle: "italic",
                color:props.contentColor&&props.contentColor,
                fontWeight: "700",
                fontSize: "3.6rem",
                marginTop: "-14px",
              }}
            >
              {props.resType=="allinPosNew"?"All-In-One":props.resType} POS App
            </div>
            <div>
              <p
                style={{
                  // fontFamily: "Poppins, sans-serif",
                  // fontStyle: "normal",
                  color:props.contentColor&&props.contentColor,
                  fontWeight: "700",
                  fontSize: "2rem",
                  marginTop:'1.4rem',
                }}
              >
                {props.resType == "All-in-one"
                  ? "Business Types":props.resType == "allinPosNew"?"Business Types"
                  : "Outlet Types"}
              </p>
              <div
                style={{
                  // fontWeight: "500",
                  color:props.contentColor&&props.contentColor,
                  display:'flex',
                  flexWrap:'wrap',
                  width:'85%',
                  fontSize: "1.6rem",
                  marginTop: "-2%",
              
                }}
              >
                {props &&
                  props.outLetType &&
                  props.outLetType.map((el, idx) => {
                    return (
                      <span
                        key={idx}
                        style={
                          idx == 0
                            ? {
                                fontFamily: "Poppins, sans-serif",
                                fontStyle: "normal",
                                // borderRight: "2px solid black",
                                fontWeight:'400',
                                paddingRight: "0.5rem",
                              }
                            : {
                                fontFamily: "Poppins, sans-serif",
                                fontStyle: "normal",
                                fontWeight:'400',
                                // borderRight: "2px solid black",
                                paddingRight: "0.5rem",
                                // paddingLeft: "0.3rem",
                              }
                        }
                      >
                        {el} |
                      </span>
                    );
                  })}
                <span
                  style={{
                    fontWeight:'400',
                    fontFamily: "Poppins, sans-serif",
                    fontStyle: "normal",
                    // paddingLeft: "0.3rem",
                  }}
                >
                  & many more
                </span>
              </div>
            </div>
          </div>
         <div style={{padding:'0 4rem',display:'flex',justifyContent:'center', height: "100%",marginBottom:'30px' }}>
            <FormIndustryWiseV2 purpose={props.resType} />
          </div>
        </div>
      </div>
      <div style={{ padding: "2rem 7.5rem" }}>
        <h2 style={{fontSize:'2.4rem',fontWeight:'700'}}>Features</h2>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "31% 31% 31%",
          gap:'2%',
          padding: "0 7.5rem 1.5rem 7.5rem",
        }}
      >
        {icons&&icons.map((el,idx) => {
          return (
            <div style={{ display: "flex", gap: "20px",marginBottom:'2rem' }}>
              <div>
                <img src={el} height='60px' width='60px' alt="icon" />
              </div>
              <div>
                <h4 style={{fontWeight:'700',fontSize:'1.4rem'}}>{props.features[idx]["title"]}</h4>
                <p style={{fontSize:'1.1rem'}}>
                {props.features[idx]["content"]}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ padding: "0px 7.5rem" }}>
        <p style={{ fontWeight: "700",fontSize: "2rem",}}>
          Trusted by{" "}
          <span style={{ color: props.color,fontWeight: "700",fontSize: "2rem" }}> &nbsp; 50,000+ Merchants</span>
        </p>
      </div>
      <div
        style={{
          margin: "1.5rem 7.5rem",
          display: "grid",
          gridTemplateColumns: "19% 19% 19% 19% 19%",
          gap: "2%",
        }}
      >
        {brands.map((el,idx) => {
          return (
            <div key={idx} style={{ display:'flex', alignItems:'center',gap:'1%',justifyContent:'space-between',marginBottom:'40px'
          }}>
              <img src={el} alt="merchant" 
               height={props.resType=="Restaurant"&&idx==0
               ||props.resType=="Restaurant"&&idx==1
               ||props.resType=="Restaurant"&&idx==3
               ||props.resType=="Restaurant"&&idx==4
               ||props.resType=="Restaurant"&&idx==5
               ||props.resType=="Restaurant"&&idx==6?"55px":
                 props.resType=="Restaurant"&&idx==7?"42px":
                 props.resType=="Restaurant"&&idx==8?"60px":
                 props.resType=="Restaurant"&&idx==9?"60px":
                 idx==0||idx==4?'75px':
                 idx==9||idx==6?'60px':
                 idx==2||idx==3?"70px":
                 idx==5?"85px":
                 idx==8?"100px":
                 "55px"}
               width="auto" 
               />
            </div>
          );
        })}
      </div>
      <div
        style={{
          backgroundColor: "#1D1B1BB3",
          color: "white",
          height: "4rem",
          display: "flex",
          justifyContent: "center",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "5px",
            }}
            src={internet}
            alt="internet"
          />
          <span style={{ fontSize: "1.8rem" }}>www.queuebuster.co</span>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "5px",
            }}
            src={sms}
            alt="sms"
          />
          <spna style={{ fontSize: "1.8rem" }}>contact@queuebuster.co</spna>
        </div>
      </div>
    </div>
  );
};

export default NewIndustryWiseForms;
