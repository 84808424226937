const $ = window.$;
var libphonenumber = window.libphonenumber
export default () => {
	$(document).ready(function () {
		let x = (function (i, s, o, g, r, a, m) {
			i['GoogleAnalyticsObject'] = r;
			i[r] = i[r] || function () {
				(i[r].q = i[r].q || []).push(arguments)
			};
			i[r].l = 1 * new Date();
			a = s.createElement(o);
			m = s.getElementsByTagName(o)[0];
			a.async = 1;
			a.src = g;
			m.parentNode.insertBefore(a, m)
		})(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=UA-91781518-2', 'ga');
		window.dataLayer = window.dataLayer || [];

		// let xGA = (function (i, s, o, g, r, a, m) {
		// 	i['GoogleAnalyticsObject1'] = r;
		// 	i[r] = i[r] || function () {
		// 		(i[r].q = i[r].q || []).push(arguments)
		// 	};
		// 	i[r].l = 1 * new Date();
		// 	a = s.createElement(o);
		// 	m = s.getElementsByTagName(o)[0];
		// 	a.async = 1;
		// 	a.src = g;
		// 	m.parentNode.insertBefore(a, m)
		// })(window, document, 'script', 'https://www.googletagmanager.com/gtag/js?id=AW-10996025145', 'ga');
		// window.dataLayer = window.dataLayer || [];

		function gtag() {
			window.dataLayer.push(arguments);
		}
		gtag('js', new Date());

		gtag('config', 'UA-91781518-2');
		// gtag('config', 'AW-10996025145');

		// google tag manager
		(function (w, d, s, l, i) {
			w[l] = w[l] || []; w[l].push({
				'gtm.start':
					new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
					'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MMMJBGL');

		$('input[type="number"]').on('change', function () {
			// Remove invalid characters
			var sanitized = $(this).val().replace(/[^0-9]/g, '');
			// Update value
			$(this).val(sanitized);
		});

		$('input[type="password"]').on('change', function () {
			// Remove invalid characters
			var sanitized = $(this).val().replace(/[^0-9]/g, '');
			sanitized = sanitized.substr(0, 20);
			// Update value
			$(this).val(sanitized);
		});

		$('input[name="username"]').on('change', function () {
			// Remove invalid characters
			var sanitized = $(this).val().replace(/[^A-Za-z0-9.!@#$%^*_]/g, '');
			sanitized = sanitized.substr(0, 30);
			// Update value
			$(this).val(sanitized);
		});

		$('input[name="user"]').on('change', function () {
			// Remove invalid characters
			var sanitized = $(this).val().replace(/[^A-Za-z0-9.!@#$%^*_]/g, '');
			sanitized = sanitized.substr(0, 30);
			// Update value
			$(this).val(sanitized);
		});

		// Remove header and footer on mobile //
		function getParameterByName(name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		}

		var device = getParameterByName('device');

		if (device != undefined && device != null && device != '' && device == 1) {
			$('#header').remove()
			$('.footer').remove()
			$('.queuebuster-launcher-frame').remove()
		}

		var check = window.check
		var check1 = window.check1
		if (document.getElementById("check"))
			document.getElementById("check").value = check;
		if (document.getElementById("check1"))
			document.getElementById("check1").value = check1;
		if (document.getElementById("check2"))
			document.getElementById("check2").value = check;
		if (document.getElementById("check3"))
			document.getElementById("check3").value = check1;
		if (document.getElementById("check4"))
			document.getElementById("check4").value = check;
		if (document.getElementById("check5"))
			document.getElementById("check5").value = check1;
		if (document.getElementById("check6"))
			document.getElementById("check6").value = check;
		if (document.getElementById("check7"))
			document.getElementById("check7").value = check1;
		if (document.getElementById("check8"))
			document.getElementById("check8").value = check;
		if (document.getElementById("check9"))
			document.getElementById("check9").value = check1;
		if (document.getElementById("check10"))
			document.getElementById("check10").value = check;
		if (document.getElementById("check11"))
			document.getElementById("check11").value = check1;

		// var check = $("input[name=check]").val()
		// var check1 = $("input[name=check1]").val()
		var state = 0;
		var loginState = 0;

		var client = new window.ClientJS();

		/* ======= CSS Animated Hamburger Icon for Bootstrap ======= */
		/* Ref: http://codepen.io/impondesk/pen/EaKoXY */

		$(".navbar-toggle").on("click", function () {
			$(this).toggleClass("active");
		});

		/* ======= Scrollspy ======= */
		$('body').scrollspy({
			target: '#page-nav-wrapper',
			offset: 100
		});

		/* ======= ScrollTo ======= */
		$('.scrollto').on('click', function (e) {

			//store hash
			var target = this.hash;

			e.preventDefault();

			$('body').scrollTo(target, 800, {
				offset: -62,
				'axis': 'y'
			});

		});

		$('.scrollto-no-offset').on('click', function (e) {

			//store hash
			var target = this.hash;

			e.preventDefault();

			$('body').scrollTo(target, 800, {
				offset: 0,
				'axis': 'y'
			});

		});

		/* ======= Fixed page nav when scrolled ======= */
		$(window).on('scroll', function () {

			if ($("#page-nav-wrapper").length) {
				$('#page-nav-wrapper').removeClass('fixed');

				var scrollTop = $(this).scrollTop();
				var topDistance = $('#page-nav-wrapper').offset().top;

				if ((topDistance) > scrollTop) {
					$('#page-nav-wrapper').removeClass('fixed');
					$('body').removeClass('sticky-page-nav');
				} else {
					$('#page-nav-wrapper').addClass('fixed');
					$('body').addClass('sticky-page-nav');
				}
			}

		});

		/* ======= Stop Video Playing When Close the Modal Window ====== */
		$("#modal-video .close").on("click", function () {
			$("#modal-video iframe").attr("src", $("#modal-video iframe").attr("src"));
		});

		$("#modal-video").on("hidden.bs.modal", function () {
			$("#modal-video .home_video").html('');
		});

		/* ======= FAQ accordion ======= */
		function toggleIcon(e) {
			$(e.target)
				.prev('.panel-heading')
				.find('.panel-title a')
				.toggleClass('active')
				.find("i.fa")
				.toggleClass('fa-plus-square fa-minus-square');
		}

		$('.panel').on('hidden.bs.collapse', toggleIcon);
		$('.panel').on('shown.bs.collapse', toggleIcon);

		/* ======= Toggle between Signup & Login & ResetPass Modals ======= */
		$('#signup-link').on('click', function (e) {
			$('#login-modal').modal('toggle');
			$('#signup-modal').modal();

			e.preventDefault();
		});

		$('#login-link').on('click', function (e) {
			$('#signup-modal').modal('toggle');
			$('#login-modal').modal();
			e.preventDefault();
		});

		$('#back-to-login-link').on('click', function (e) {
			$('#resetpass-modal').modal('toggle');
			$('#login-modal').modal();

			e.preventDefault();
		});

		$('#resetpass-link').on('click', function (e) {
			$('#login-modal').modal('hide');
			e.preventDefault();
		});

		/* ======= Testimonial Bootstrap Carousel ======= */
		/* Ref: http://getbootstrap.com/javascript/#carousel */
		// $('#testimonials-carousel').carousel({
		// 	interval: 8000
		// });

		/* ======= Form Submittions ======= */
		/* Login */
		function getPrint() {
			var ua = client.getBrowserData().ua;
			var canvasPrint = client.getCanvasPrint();
			var fingerprint = client.getCustomFingerprint(ua, canvasPrint);
			var browser = client.getBrowser();
			var browserVersion = client.getBrowserVersion();
			var browserMajorVersion = client.getBrowserMajorVersion();
			var engine = client.getEngine();
			var engineVersion = client.getEngineVersion();
			var OS = client.getOS();
			var osVersion = client.getOSVersion();
			var device = client.getDevice();
			var deviceType = client.getDeviceType();
			var isMac = client.isMac();
			var isMobile = client.isMobile();
			var isIphone = client.isIphone();
			var availableResolution = client.getAvailableResolution();
			var colorDepth = client.getColorDepth();
			var deviceVendor = client.getDeviceVendor();
			if (deviceVendor == '' || deviceVendor == null) {
				if (isMac == true) {
					deviceVendor = 'Apple'
				}
			}
			if (deviceType == '' || deviceType == null) {
				if (isMobile == false) {
					deviceType = 'laptop'
				} else {
					deviceType = 'mobile'
				}
			}
			if (device == '' || device == null) {
				if (isIphone == true) {
					device = 'iphone'
				} else if (isMac == true) {
					device = "MacBook"
				}
			}
			$('#fingerprint').val(fingerprint);
			$('#browser').val(browser);
			$('#browserVersion').val(browserVersion);
			$('#browserMajorVersion').val(browserMajorVersion);
			$('#engine').val(engine);
			$('#engineVersion').val(engineVersion);
			$('#os').val(OS);
			$('#osVersion').val(osVersion);
			$('#device').val(device);
			$('#deviceType').val(deviceType);
			$('#deviceVendor').val(deviceVendor);
			$('#availableResolution').val(availableResolution);
			$('#colorDepth').val(colorDepth)
		}

		function setSession(permissions, userID, roleID, username, chainID, storeList, wareList, resellerID, userType, country, fingerprint, manufacturer, modelNumber, currencyCode, isInventoryManaged, isUrbanpiperIntegrationEnabled, allowOnlineOrdering, isBasicInfoUpdated, industry, thirdPartyChainID, chainList, moduleList, isLinkedToHDFC, enableCashDeclaration, enableEcomReservation, enableEcomLocalityOrdering, enableQBPocket, vayanaIntegration, enableExpenseManagement) {
			let data = {
				userID: userID,
				roleID: roleID,
				username: username,
				permissions: permissions,
				check: check,
				check1: check1,
				chainID: chainID,
				storeList: storeList,
				wareList: wareList,
				resellerID: resellerID,
				userType: userType,
				country: country,
				fingerprint: fingerprint,
				manufacturer: manufacturer,
				modelNumber: modelNumber,
				currencyCode: currencyCode,
				isInventoryManaged: isInventoryManaged,
				isUrbanpiperIntegrationEnabled: isUrbanpiperIntegrationEnabled,
				allowOnlineOrdering: allowOnlineOrdering,
				isBasicInfoUpdated: isBasicInfoUpdated,
				industry: industry,
				thirdPartyChainID,
				chainList,
				moduleList,
				isLinkedToHDFC,
				enableCashDeclaration: enableCashDeclaration,
				enableEcomReservation: enableEcomReservation,
				enableEcomLocalityOrdering: enableEcomLocalityOrdering,
				enableQBPocket: enableQBPocket,
				vayanaIntegration: vayanaIntegration,
				enableExpenseManagement: enableExpenseManagement
			}

			$.ajax({
				type: "POST",
				url: "manage/setSession.php",
				data,
				success: function (data) {
					if (data == 1) {
						document.cookie = `ut=${userType}`;
						document.cookie = `un=${username}`;

						var baseURL = window.baseURL
						if (userType == 'THIRD_PARTY_CHAIN_ADMIN') {
							window.location.assign(baseURL + "merchant/thirdPartyDashboard.php");
						}
						if (userType == 'MYSQUARE_ADMIN') {
							window.location.assign(baseURL + "merchant/thirdPartyDashboard.php");
						} else if (userType == 'CHANNEL_PARTNER') {
							window.location.assign(baseURL + "partner/partnerLicenseList.php");
						} else {
							window.location.assign(baseURL + "merchant/dashboard.php");
						}
					}
				}
			});
		}

		$('.login-form').on('submit', function (e) {
			e.preventDefault();

			if ($('#login-email').val().trim() === '') {
				$('#login-email').css('border', 'solid 1px #ba1800');
				$('#login-message-error').html('Please enter username');
				return;
			}

			if ($('#login-password').val().trim() === '') {
				$('#login-password').css('border', 'solid 1px #ba1800');
				$('#login-message-error').html('Please enter password');
				return;
			}

			var url = "/manage/sendData.php";
			$('#login-message-error').html('');
			$(this).find(':input[type=submit]').prop('disabled', true)
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`)
			$('#login-message').html('');
			$.ajax({
				type: "POST",
				url: url,
				data: $(".login-form").serialize(),
				success: function (data) {
					var finalData = JSON.parse(data);
					if (finalData.status == true) {
						var wareList = [];
						var moduleList = [];
						var storeList = finalData.storeList;
						var permissions = finalData.permissions;
						var userID = finalData.userID;
						var roleID = finalData.roleID;
						var username = finalData.username;
						var chainID = finalData.chainID;
						var country = finalData.country;
						wareList = finalData.wareList;
						var resellerID = finalData.resellerID;
						var userType = finalData.userType;
						var fingerprintID = finalData.fingerprint;
						var manufacturer = finalData.manufacturer;
						var modelNumber = finalData.modelNumber;
						var currencyCode = finalData.currencyCode;
						var isInventoryManaged = finalData.isInventoryManaged;
						var isUrbanpiperIntegrationEnabled = finalData.isUrbanpiperIntegrationEnabled;
						var allowOnlineOrdering = finalData.allowOnlineOrdering;
						var isLinkedToHDFC = finalData.isLinkedToHDFC;
						var isBasicInfoUpdated = finalData.isBasicInfoUpdated;
						var enableCashDeclaration = finalData.enableCashDeclaration;
						var enableEcomReservation = finalData.enableEcomReservation;
						var enableEcomLocalityOrdering = finalData.enableEcomLocalityOrdering;
						var industry = finalData.industry;
						var vayanaIntegration = finalData.vayanaIntegration;
						var enableExpenseManagement = finalData.enableExpenseManagement;
						var thirdPartyChainID = 0;
						moduleList = finalData.moduleList;
						var chainList = [];
						var enableQBPocket = null;
						if (typeof finalData.thirdPartyChainID != "undefined") {
							thirdPartyChainID = finalData.thirdPartyChainID;
							enableQBPocket = finalData.enableQBPocket
						}
						if (typeof finalData.chainList != "undefined") {
							chainList = finalData.chainList
						}
						setSession(permissions, userID, roleID, username, chainID, storeList, wareList, resellerID, userType, country, fingerprintID, manufacturer, modelNumber, currencyCode, isInventoryManaged, isUrbanpiperIntegrationEnabled, allowOnlineOrdering, isBasicInfoUpdated, industry, thirdPartyChainID, chainList, moduleList, isLinkedToHDFC, enableCashDeclaration, enableEcomReservation, enableEcomLocalityOrdering, enableQBPocket, vayanaIntegration, enableExpenseManagement)
					} else {
						$('#login-email').css('border', 'solid 1px #ba1800');
						$('#login-password').css('border', 'solid 1px #ba1800');
						$("#login-message-error").html(finalData.message)
						$('.login-form').find(':input[type=submit]').html(`Login`)
						$('.login-form').find(':input[type=submit]').prop('disabled', false)
						$('#login-message').html('');
					}

				},
				error: function (data) {
					$('#login-message-error').html('');
					$('.login-form').find(':input[type=submit]').html(`Login`)
					$('.login-form').find(':input[type=submit]').prop('disabled', false)
					$("#login-message").html('Network error. Please try again.')
				}
			});
		});

		getPrint();

		$(".contact-form").on('submit', function (e) {
			var url = "/mail/contact";

			if ($(this).find('input[name="name"]').val().trim() === '' || $(this).find('input[name="phone"]').val().trim() === '') {
				e.preventDefault();
				$("#form-messages").text('Name or Phone field is empty!');
				return;
			}

			$("#form-messages").html('Contacting our delivery man. Please wait!')
			$.ajax({
				type: "POST",
				url: url,
				data: $(".contact-form").serialize(),
				success: function (data) {
					if (data.status == 1) {
						$(".contact-form").find("input, textarea").val("");
						$("#form-messages").html(data.message)
					} else {
						$("#form-messages").html(data.message)
					}
				},
				error: function (data) {
					$("#form-messages").text('Network error. Please try again.')
				}
			});
			e.preventDefault();
		});

		$(".demo-form").on('submit', function (e) {
			e.preventDefault();
			var url = "/mail/demo";

			if ($(this).find('input[name="name"]').val().trim() === '' || $(this).find('input[name="mobile"]').val().trim() === '') {
				e.preventDefault();
				$(this).find(".demo-messages").text('Name or Mobile field is empty!');
				return;
			}

			if (isNaN(parseInt($(this).find('input[name="mobile"]').val().trim()))) {
				e.preventDefault();
				$(this).find('.demo-messages').text('Mobile Number should only contain numberical digits.');
				return;
			}

			if ($(this).find('input[name="mobile"]').val().trim().length !== 10) {
				e.preventDefault();
				$(this).find('.demo-messages').text('Mobile Number should be of length - 10 digits.');
				return;
			}

			$(this).find(':input[type=submit]').prop('disabled', true)
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`)
			$(".demo-messages").html('');
			let self = this;

			$.ajax({
				type: "POST",
				url: url,
				data: $(this).serialize(),
				success: function (data) {
					if (data.status == 1) {
						$(self).find("input, textarea").val("");
						$(".demo-form").find(':input[type=submit]').prop('disabled', false)
						$(".demo-form").find(':input[type=submit]').html(`Request Demo`)
						$(self).find(".demo-messages").html(data.message)
					} else {
						$(".demo-form").find(':input[type=submit]').prop('disabled', false)
						$(".demo-form").find(':input[type=submit]').html(`Request Demo`)
						$(self).find(".demo-messages").html(data.message)
					}
				},
				error: function (data) {
					$(".demo-form").find(':input[type=submit]').prop('disabled', false)
					$(".demo-form").find(':input[type=submit]').html(`Request Demo`)
					$(self).find(".demo-messages").text('Network error. Please try again.')
				}
			});
		});

		// Confirm Password Check //
		var password = document.getElementById("signup-password"),
			confirm_password = document.getElementById("signup-confirm-password");

		function validatePassword() {
			if (password.value != confirm_password.value) {
				confirm_password.setCustomValidity("Passwords Don't Match");
			} else {
				confirm_password.setCustomValidity('');
			}
		}

		if ($("#signup-password").length) {
			password.onchange = validatePassword;
			confirm_password.onkeyup = validatePassword;
		}

		$('#signup-phoneCode').on('change', function () {
			let reqText = $(`option[value="${$(this).val()}"]`).text();
			let countryName = reqText.split('(')[0].trim();

			$('#signup-country').val(countryName);
		})

		var signUpFormData = ''
		var otpFormData = ''
		var otp = ''

		function signUp(e, source = "modal", resend = false) {
			e.preventDefault()
			var countrySelectID = "signup-country";
			var phoneID = "signup-mobile";
			var errorField1 = "signup-messages-error"
			var errorField2 = "signup-messages"
			var form = "signup-form"
			var btnText = "Sign Up"
			var phoneCode = "phoneCode2"
			if (source !== "modal") {
				countrySelectID = "signup-country1"
				phoneID = "signup-mobile1"
				errorField1 = "signup-messages-error2"
				errorField2 = "signup-messages2"
				form = "signup-form1"
				btnText = "Get started"
				phoneCode = "phoneCode21"
			}
			$(`#${errorField1}`).html('')
			$(`#${errorField2}`).html('')

			if ($(`#${phoneID}`).val() && $(`#${phoneID}`).val().length === 0) {
				$(`#${phoneID}`).css('border', 'solid 1px #ba1800');
				$(`#${errorField1}`).html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Please enter mobile number')
				return
			}

			// if ($(`#${phoneID}`).val().length < 10) {
			// 	$(`#${phoneID}`).css('border', 'solid 1px #ba1800');
			// 	$(`#${errorField1}`).html('Mobile number is less than 10 digits')
			// 	return
			// }

			// if ($(`#${phoneID}`).val().charAt(0) === '0') {
			// 	$(`#${phoneID}`).css('border', 'solid 1px #ba1800');
			// 	$(`#${errorField1}`).html('Mobile Number cannot start with 0')
			// 	return
			// }

			var url = window.baseURL + "sendData.php";

			if ($(`#${countrySelectID}`).val() === undefined || $(`#${countrySelectID}`).val() == null || $(`#${countrySelectID}`).val() === "") {
				$(`#${countrySelectID}`).val("India")
			}

			var customerPhone = $(`#${phoneID}`).val()
			var countryCode = ($(`#${phoneCode}`).val() || 91);

			var isPhoneValid = true
			if (libphonenumber !== undefined) {
				isPhoneValid = libphonenumber.isValidPhoneNumber(`+${countryCode}${customerPhone}`)
			}

			if (!isPhoneValid) {
				$(`#${phoneID}`).css('border', 'solid 1px #ba1800');
				$(`#${errorField1}`).html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Mobile number is invalid.')
				return
			}

			$(this).find(':input[type=submit]').prop('disabled', true)
			$(`#${form}`).find(':input[type=submit]').html(`<div class="loader"></div>`);
			$('.otp-form').find(':input[type=submit]').html(`<div class="loader"></div>`);
			$('#forgot-otp-form').find(':input[type=submit]').html(`<div class="loader"></div>`);

			$(`#${form} > input[name=routeParam]`).val('signUp')
			signUpFormData = $(`#${form}`).serializeArray()
			signUpFormData.push({
				name: 'autoGeneratePassword',
				value: true
			});
			signUpFormData.push({
				name: 'email',
				value: 'support@queuebuster.co'
			});
			signUpFormData.push({
				name: 'username',
				value: customerPhone
			});

			$(`#${form} > input[name=routeParam]`).val('sendOtp')
			otpFormData = $(`#${form}`).serializeArray()
			otpFormData.push({
				name: 'autoGeneratePassword',
				value: true
			});
			otpFormData.push({
				name: 'email',
				value: 'support@queuebuster.co'
			});
			otpFormData.push({
				name: 'username',
				value: customerPhone
			});

			$("#otpPhone").html(`${customerPhone}`)

			if (!resend) {
				gtag('event', 'otp', {
					'event_category': 'signup',
					'event_label': `Started from ${countryCode} ${customerPhone} - ${source}`,
					'value': customerPhone
				});
			}

			// Call for OTP
			$.ajax({
				type: "POST",
				url: url,
				data: otpFormData,
				success: function (data) {

					data = JSON.parse(data)

					if (data.status == 1) {
						$(`#${form}`).find(':input[type=submit]').html(`${btnText}`);
						$('#signup-modal').modal('hide');
						$('#otp-modal').modal({
							backdrop: 'static',
							keyboard: false
						});
						$('.otp-form').find('input').css('border', 'solid 1px #d8d8d8');
						$('.otp-form').find(':input[type=submit]').prop('disabled', false);
						$('.otp-form').find(':input[type=submit]').html(`Confirm`);
						$('#forgot-otp-form').find(':input[type=submit]').html(`Verify OTP`);
						$('#otp-messages').html('');
						$('#otp-modal').modal('show');
						if (!resend) {
							$('#otp-messages-error').html('');
							$('#otp-messages-error').hide();
						}
						otp = data.otp
					} else {
						$(`#${errorField1}`).html(`<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${data.message}`)
						$(`#${form}`).find(':input[type=submit]').prop('disabled', false)
						$(`#${form}`).find(':input[type=submit]').html(`${btnText}`);
						$('.otp-form').find(':input[type=submit]').html(`Confirm`);
						$('#forgot-otp-form').find(':input[type=submit]').html(`Verify OTP`);
					}
				},
				error: function (data) {
					$(`#${errorField2}`).html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Network error. Please try again.')
					$(`#${form}`).find(':input[type=submit]').prop('disabled', false)
					$(`#${form}`).find(':input[type=submit]').html(`${btnText}`);
					$('.otp-form').find(':input[type=submit]').html(`Confirm`);
					$('#forgot-otp-form').find(':input[type=submit]').html(`Verify OTP`);
				}
			});
		}

		$("#resendOtp").on('click', function (e) {
			var error = false
			if (otpFormData.length > 0) {
				var phoneNumber = 0
				var countryCode = 91
				var country = 'India'

				for (let i = 0; i < otpFormData.length; i++) {
					const name = otpFormData[i].name === undefined ? null : otpFormData[i].name
					const value = otpFormData[i].value === undefined ? null : otpFormData[i].value
					if (name != null && value != null) {
						$(`.signup-form`).find(`:input[name=${name}]`).val(value)
					}
					if (name === 'mobile') {
						phoneNumber = value
					}
					if (name === 'country') {
						country = value
					}
					if (name === 'phoneCode') {
						countryCode = value
					}
				}

				$("#signup-country").val(country)
				$("#signup-mobile").val(phoneNumber)
				$("#phoneCode2").val(countryCode)

				if (document.getElementById("signup-country1")) {
					$("#signup-country1").val(country)
					$("#signup-mobile1").val(phoneNumber)
					$("#phoneCode21").val(countryCode)
				}

				if (phoneNumber === "") {
					error = true
				} else {
					gtag('event', 'otp resend', {
						'event_category': 'signup',
						'event_label': `Resend ${phoneNumber}`,
						'value': phoneNumber
					});

					signUp(e, 'page', true)
					$('#otp-messages-error').html(`OTP sent to ${phoneNumber}`);
					$('#otp-messages-error').show()
					return
				}
			} else {
				error = true
			}

			if (error) {
				$('#otp-messages-error').html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Phone number not provided.');
				$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
				$('#otp-messages-error').show()
				$('.otp-form').find(':input[type=submit]').prop('disabled', false);
				$('.otp-form').find(':input[type=submit]').html(`Confirm`);
				$('#otp-messages').html('');
			}
			return
		})

		$(".signup-form").on('submit', function (e) {
			e.preventDefault()
			var type = ($('#signup-modal').hasClass('in') || $('#signup-modal').hasClass('show')) ? "modal" : "page"
			signUp(e, type)
		});

		$(".otp-form").on('submit', function (e) {
			e.preventDefault();
			$(this).find('input').css('border', 'solid 1px #d8d8d8');
			$('#otp-messages-error').html('');
			$(this).find(':input[type=submit]').prop('disabled', true)
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`)
			$('#otp-messages').html('');
			var url = "/checkotp";

			var phoneNumber = ''
			for (let i = 0; i < signUpFormData.length; i++) {
				const name = signUpFormData[i].name === undefined ? null : signUpFormData[i].name
				const value = signUpFormData[i].value === undefined ? null : signUpFormData[i].value
				if (name === 'mobile') {
					phoneNumber = value
					break
				}
			}

			if (phoneNumber === '') {
				$('#otp-messages-error').html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Phone number not provided.');
				$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
				$('#otp-messages-error').show()
				$('.otp-form').find(':input[type=submit]').prop('disabled', false);
				$('.otp-form').find(':input[type=submit]').html(`Confirm`);
				$('#otp-messages').html('');
				return
			}

			if (otp === '') {
				$('#otp-messages-error').html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> OTP not provided.');
				$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
				$('#otp-messages-error').show()
				$('.otp-form').find(':input[type=submit]').prop('disabled', false);
				$('.otp-form').find(':input[type=submit]').html(`Confirm`);
				$('#otp-messages').html('');
				return
			}

			gtag('event', 'otp verification', {
				'event_category': 'signup',
				'event_label': `OTP Verification ${phoneNumber}`,
				'value': phoneNumber
			});

			$.ajax({
				type: "POST",
				url: url,
				data: {
					otp: otp,
					userotp: $("#otp").val()
				},
				success: function (data) {
					if (data.status == 1) {
						var url = "/manage/sendData.php";
						$('.otp-form').find('input').css('border', 'solid 1px #d8d8d8');
						$('#otp-messages-error').html('');
						$('.otp-form').find(':input[type=submit]').html(`<div class="loader"></div>`);
						$("#otp-messages").html(data.message)
						gtag('event', 'signup', {
							'event_category': 'signup',
							'event_label': `${phoneNumber}`,
							'value': phoneNumber
						});
						$.ajax({
							type: "POST",
							url: url,
							data: signUpFormData,
							success: function (data) {
								data = JSON.parse(data)
								if (data.status == 1) {
									$('.otp-form').find('input').css('border', 'solid 1px #d8d8d8');
									$('#otp-messages-error').html('');
									$('.otp-form').find(':input[type=submit]').html(`<div class="loader"></div>`);

									// redirect to login page
									if (window.baseURL) {
										window.location.assign(`${window.baseURL}?autoLogin=1&autoLoginKey=${data.autoLoginKey}`)
										return
									}

									$("#login-email").val(data.username)
									$("#login-password").val($("#signup-password").val())
									$(".signup-form").find("input, textarea").val("");
									$.ajax({
										type: "POST",
										url: url,
										data: $(".login-form").serialize(),
										success: function (data) {
											var finalData = JSON.parse(data);
											if (finalData.status == true) {
												var wareList = [];
												var moduleList = [];
												var storeList = finalData.storeList;
												var permissions = finalData.permissions;
												var userID = finalData.userID;
												var roleID = finalData.roleID;
												var username = finalData.username;
												var chainID = finalData.chainID;
												var country = finalData.country;
												wareList = finalData.wareList;
												var resellerID = finalData.resellerID;
												var userType = finalData.userType;
												var fingerprintID = finalData.fingerprint;
												var manufacturer = finalData.manufacturer;
												var modelNumber = finalData.modelNumber;
												var currencyCode = finalData.currencyCode;
												var isInventoryManaged = finalData.isInventoryManaged;
												var isUrbanpiperIntegrationEnabled = finalData.isUrbanpiperIntegrationEnabled;
												var allowOnlineOrdering = finalData.allowOnlineOrdering;
												var isLinkedToHDFC = finalData.isLinkedToHDFC;
												var isBasicInfoUpdated = finalData.isBasicInfoUpdated;
												var enableCashDeclaration = finalData.enableCashDeclaration;
												var enableEcomReservation = finalData.enableEcomReservation;
												var enableEcomLocalityOrdering = finalData.enableEcomLocalityOrdering;
												var industry = finalData.industry;
												var vayanaIntegration = finalData.vayanaIntegration;
												var enableExpenseManagement = finalData.enableExpenseManagement;
												var thirdPartyChainID = 0;
												moduleList = finalData.moduleList;
												var chainList = [];
												var enableQBPocket = null;
												if (typeof finalData.thirdPartyChainID != "undefined") {
													thirdPartyChainID = finalData.thirdPartyChainID;
													enableQBPocket = finalData.enableQBPocket
												}
												if (typeof finalData.chainList != "undefined") {
													chainList = finalData.chainList
												}
												setSession(permissions, userID, roleID, username, chainID, storeList, wareList, resellerID, userType, country, fingerprintID, manufacturer, modelNumber, currencyCode, isInventoryManaged, isUrbanpiperIntegrationEnabled, allowOnlineOrdering, isBasicInfoUpdated, industry, thirdPartyChainID, chainList, moduleList, isLinkedToHDFC, enableCashDeclaration, enableEcomReservation, enableEcomLocalityOrdering, enableQBPocket, vayanaIntegration, enableExpenseManagement)
											} else {
												$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
												$("#otp-messages-error").html(`<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${finalData.message}`)
												$('#otp-messages-error').show()
												$('.otp-form').find(':input[type=submit]').prop('disabled', false);
												$('.otp-form').find(':input[type=submit]').html(`Confirm`);
												$('#otp-messages').html('');
											}

										},
										error: function (data) {
											$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
											$("#otp-messages-error").html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Network error. Please try again.')
											$('#otp-messages-error').show()
											$('.otp-form').find(':input[type=submit]').prop('disabled', false);
											$('.otp-form').find(':input[type=submit]').html(`Confirm`);
											$('#otp-messages').html('');
										}
									});
								} else {
									$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
									$("#otp-messages-error").html(`<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${data.message}`);
									$('#otp-messages-error').show()
									$('.otp-form').find(':input[type=submit]').prop('disabled', false);
									$('.otp-form').find(':input[type=submit]').html(`Confirm`);
									$('#otp-messages').html('');
								}
							},
							error: function (data) {
								$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
								$("#otp-messages-error").html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Network error. Please try again.')
								$('#otp-messages-error').show()
								$('.otp-form').find(':input[type=submit]').prop('disabled', false);
								$('.otp-form').find(':input[type=submit]').html(`Confirm`);
								$('#otp-messages').html('');
							}
						});
					} else {
						$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
						$("#otp-messages-error").html(`<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${data.message}`);
						$('#otp-messages-error').show()
						$('.otp-form').find(':input[type=submit]').prop('disabled', false);
						$('.otp-form').find(':input[type=submit]').html(`Confirm`);
						$('#otp-messages').html('');
						$("#myOtp-message").html(data.message || "Something went wrong");
					}
				},
				error: function (data) {
					$('.otp-form').find('input').css('border', 'solid 1px #ba1800');
					$("#otp-messages-error").html('<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Network error. Please try again.')
					$('#otp-messages-error').show()
					$('.otp-form').find(':input[type=submit]').prop('disabled', false);
					$('.otp-form').find(':input[type=submit]').html(`Confirm`);
					$('#otp-messages').html('');
					$("#myOtp-message").html(data.message || "Something went wrong");
				}
			});
		});

		$(".signup-form1").on('submit', function (e) {
			var url = "/mail/signup";
			$("#signup-messages1").html('Contacting our delivery man. Please wait!')
			$.ajax({
				type: "POST",
				url: url,
				data: $(".signup-form1").serialize(),
				success: function (data) {
					if (data.status == 1) {
						$(".signup-form1").find("input, textarea").val("");
						$("#signup-messages1").html(data.message)
					} else {
						$("#signup-messages1").html(data.message)
					}
				},
				error: function (data) {
					$("#signup-messages1").text('Network error. Please try again.')
				}
			});
			e.preventDefault();
		});

		// Confirm Password Check //
		var fpassword = document.getElementById("forgot-password"),
			fconfirm_password = document.getElementById("forgot-confirm-password");

		function fvalidatePassword() {
			if (fpassword.value != fconfirm_password.value) {
				fconfirm_password.setCustomValidity("Passwords Don't Match");
			} else {
				fconfirm_password.setCustomValidity('');
			}
		}

		if ($("#forgot-password").length) {
			fpassword.onchange = fvalidatePassword;
			fconfirm_password.onkeyup = fvalidatePassword;
		}

		$("#forgot-form").on('submit', function (e) {
			e.preventDefault();
			$(this).find(':input[type=submit]').prop('disabled', true)
			$("#forgot-form :input").hide()
			$(this).find(':input[type=submit]').show()
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`);
			$("#forgot-messages").html('')
			var url = "/manage/sendData.php";
			if ($('#forgot-username').val().trim() === '') {
				$('#forgot-username').css('border', 'solid 1px #ba1800');
				$("#forgot-messages-error").html('Username field is empty!!!');
				$("#forgot-messages").html('');
				$(this).find(':input[type=submit]').prop('disabled', false)
				$(this).find(':input[type=submit]').html(`Next`);
				$("#forgot-form :input").show()
				return;
			}
			$("#forgot-messages-error").html('');
			$.ajax({
				type: "POST",
				url: url,
				data: $("#forgot-form").serialize(),
				success: function (data) {
					data = JSON.parse(data)
					if (data.status == true) {
						$("#forgot-form").hide();
						// $("#forgot-otp-email").val($("#forgot-email").val())
						$("#forgot-otp-username").val($("#forgot-username").val())
						$('#forgot-otp-form').find(':input[type=submit]').prop('disabled', false)
						$('#forgot-otp-form').find(':input[type=submit]').html(`Verify OTP`)
						$("#forgot-otp-form :input").show()
						$("#forgot-otp-messages-error").html('');
						$("#forgot-otp-messages").html('')
						$("#forgot-otp-form").show();
					} else {
						$('#forgot-username').css('border', 'solid 1px #ba1800');
						$("#forgot-messages-error").html(data.message);
						$("#forgot-messages").html('');
						$("#forgot-form").find(':input[type=submit]').prop('disabled', false)
						$("#forgot-form").find(':input[type=submit]').html(`Next`);
						$("#forgot-form :input").show()
					}
				},
				error: function (data) {
					$("#forgot-messages").text('Network error. Please try again.')
					$("#forgot-form").find(':input[type=submit]').prop('disabled', false)
					$('#forgot-form').find(':input[type=submit]').html(`Next`);
					$("#forgot-form :input").show()
				}
			});
		});

		$("#forgot-otp-form").on('submit', function (e) {
			e.preventDefault();
			$(this).find(':input[type=submit]').prop('disabled', true)
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`)
			$("#forgot-otp-form :input").hide()
			$(this).find(':input[type=submit]').show()
			var url = "/manage/sendData.php";
			$("#forgot-otp-messages-error").html('');
			$("#forgot-otp-messages").html('')
			$.ajax({
				type: "POST",
				url: url,
				data: $("#forgot-otp-form").serialize(),
				success: function (data) {
					data = JSON.parse(data)
					if (data.status == true) {
						$("#forgot-otp-form").toggle();
						// $("#forgot-password-email").val($("#forgot-email").val())
						$("#forgot-password-user").val(data.user);
						$('#forgot-password-form').find(':input[type=submit]').prop('disabled', false)
						$('#forgot-password-form').find(':input[type=submit]').html(`Change password`)
						$("#forgot-password-form :input").show()
						$('#forgot-password-messages-error').html('');
						$("#forgot-password-messages").html('')
						$("#forgot-password-form").toggle();
					} else {
						$('#forgot-otp').css('border', 'solid 1px #ba1800');
						$("#forgot-otp-messages-error").html(data.message);
						$("#forgot-otp-messages").html('');
						$("#forgot-otp-form").find(':input[type=submit]').prop('disabled', false);
						$("#forgot-otp-form").find(':input[type=submit]').html(`Verify OTP`);
						$("#forgot-otp-form :input").show();
					}
				},
				error: function (data) {
					$("#forgot-otp-messages").text('Network error. Please try again.');
					$("#forgot-otp-form").find(':input[type=submit]').prop('disabled', false);
					$("#forgot-otp-form").find(':input[type=submit]').html(`Verify OTP`);
					$("#forgot-otp-form :input").show();
				}
			});
		});

		$("#forgot-password-form").on('submit', function (e) {
			e.preventDefault();
			$(this).find(':input[type=submit]').prop('disabled', true)
			$(this).find(':input[type=submit]').html(`<div class="loader"></div>`)
			$("#forgot-password-form :input").hide()
			$(this).find(':input[type=submit]').show()
			if (document.getElementById("forgot-password").value.length < 6) {
				$('#forgot-password').css('border', 'solid 1px #ba1800')
				$('#forgot-password-messages-error').html('You have entered less than 6 characters for password');
				$("#forgot-password-messages").html('');
				$("#forgot-password-form").find(':input[type=submit]').prop('disabled', false);
				$("#forgot-password-form").find(':input[type=submit]').html(`Change password`);
				$("#forgot-password-form :input").show()
				return false
			} else {
				var url = "/manage/sendData.php";
				$('#forgot-password-messages-error').html('');
				$("#forgot-password-messages").html('Contacting our delivery man. Please wait!')
				$.ajax({
					type: "POST",
					url: url,
					data: $("#forgot-password-form").serialize(),
					success: function (data) {
						data = JSON.parse(data)
						if (data.status == true) {
							$("#forgot-password-form").hide();
							$('#password-updated-div').show();
						} else {
							$('#forgot-password').css('border', 'solid 1px #ba1800')
							$('#forgot-confirm-password').css('border', 'solid 1px #ba1800')
							$("#forgot-password-messages-error").html(data.message)
							$("#forgot-password-messages").html('');
							$("#forgot-password-form").find(':input[type=submit]').prop('disabled', false)
							$("#forgot-password-form").find(':input[type=submit]').html(`Change password`);
							$("#forgot-password-form :input").show()
						}
					},
					error: function (data) {
						$("#forgot-password-messages").text('Network error. Please try again.')
						$("#forgot-password-form").find(':input[type=submit]').prop('disabled', false)
						$("#forgot-password-form").find(':input[type=submit]').html(`Change password`);
						$("#forgot-password-form :input").show()
					}
				});
			}
		});

		// Clear Console
		if (window.location.href.includes("localhost")) {
			// localhost
		} else {
			// Google Analytics for https://queuebuster.co
			// let x = (function (i, s, o, g, r, a, m) {
			// i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
			//     (i[r].q = i[r].q || []).push(arguments)
			// }; i[r].l = 1 * new Date(); a = s.createElement(o);
			//     m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
			// })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

			// window.ga('create', 'UA-91781518-2', 'auto');
			// window.ga('send', 'pageview');


			if (typeof console._commandLineAPI !== 'undefined') {
				console.API = console._commandLineAPI; //chrome
			} else if (typeof console._inspectorCommandLineAPI !== 'undefined') {
				console.API = console._inspectorCommandLineAPI; //Safari
			} else if (typeof console.clear !== 'undefined') {
				console.API = console;
			}

			console.API.clear();
		}
	});

	window.onload = function () {
		function getCountries() {
			var url = "/countries";
			$.ajax({
				type: "GET",
				url: url,
				success: function (data) {
					if (data.status == 1) {
						var options = '';
						for (var i = 0; i < data.countries.length; i++) {
							if (data.countries[i] == 'India') {
								$('#signup-country').append($('<option>', {
									value: data.countries[i],
									text: data.countries[i],
									selected: true
								}));
							} else {
								$('#signup-country').append($('<option>', {
									value: data.countries[i],
									text: data.countries[i]
								}));
							}

						}
					} else {
						console.log(data.message);
					}
				},
				error: function (data) {
					console.log(data);
				}
			});
		}
		//getCountries()
	}
}