import React, {Component} from 'react';
import payment_partners from '../../assets/images/payment_partners.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import './PaymentPartners.scss'

import pp1 from '../../assets/images/ezetap.png'
import pp2 from '../../assets/images/mosambee.png'
import pp3 from '../../assets/images/ezswype.png'
import pp4 from '../../assets/images/mswipe.png'
import pp5 from '../../assets/images/pinelabs_logo.png'
import pp6 from '../../assets/images/payswiff_logo.png'

class PaymentPartners extends Component {
    render() {
        return (
            <div style={{margin: '43px 0 31px 0'}} id="payment_partners_div">
                <h3 style={{width: 'fit-content', margin: 'auto'}}>Payment Partners</h3>
                <p style={{width: 'fit-content', margin: 'auto'}}>Trusted by over 50,000 businesses around the world</p>
                <img loading={'lazy'} style={{margin: 'auto', display: 'block'}} src={payment_partners} alt=""/>

                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    axis={"horizontal"}
                    emulateTouch={true}
                    centerMode
                    centerSlidePercentage={100}
                    emulateTouch>
                        <img loading={'lazy'} src={pp5} style={{height: 'auto'}} />
                        <img loading={'lazy'} src={pp2} style={{height: 'auto'}} />
                        <img loading={'lazy'} src={pp1} style={{height: 'auto'}} />
                        <img loading={'lazy'} src={pp4} style={{height: 'auto'}} />
                        <img loading={'lazy'} src={pp3} style={{height: 'auto'}} />
                        <img loading={'lazy'} src={pp6} style={{height: 'auto'}} />
                </Carousel>
            </div>
        );
    }
}

export default PaymentPartners;