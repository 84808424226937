import CustomIndustryWiseFormV2 from "./CustomIndustryWiseFormV2";
import React from "react";
import NewIndustryWiseForms from "./CustomIndustryWiseFormsDesktopV2";

const SpaAndSalonFormV2 = (props) => {
   const screenWidth = window.screen.width;
   const outletTypes = ["Speciality Spas", "Gyms", "Single & Multi-Outlet Salons"];

   const features = [
      {
         title: "Appointment",
         content: "Manage customer appointments with an easy-to-use interface",
      },
      {
         title: "CRM & Loyalty",
         content: "A suite of CRM & Loyalty tools to manage your customers",
      },
      {
         title: "Inventory Management",
         content: "Record & manage your inventory across all locations in one place",
      },
      {
         title: "eStore",
         content: "Create your own website & start selling online within minutes",
      },
      {
         title: "Membership Management",
         content: "Create and manage packages for your customers",
      },
      {
         title: "And many more...",
         content: "",
      },
   ];

   if(screenWidth<900){
      return (
         <>
            <CustomIndustryWiseFormV2
               outletTypes={outletTypes}
               features={features}
               resType={"Spa & Salon"}
               color={"#7A489C"}
               {...props}
            />
         </>
      );
   }else{
      return(
         <NewIndustryWiseForms outLetType={outletTypes} 
         features={features} 
         resType={"Spa & Salon"}
         color={"#7A489C"}/>
      )
   }

};

export default SpaAndSalonFormV2;
