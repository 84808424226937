import React from 'react'
import CustomIndustryWiseForms from './CustomIndustryWiseForms'
const MainRestaurant = () => {
  return (
    <>
    <CustomIndustryWiseForms resType={"Restaurant"} outLetType={["Fine Dine","QSR","Casual Dinning","Food Court"] }   featuresType={["Table Management","Recipe Management", "Swiggy & Zomato Integration"]} />
    </>
  )
}

export default MainRestaurant