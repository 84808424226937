import React, {Component} from 'react';
import './StartTrialTop.scss';
import googlePlay from '../../assets/images/google_play.svg';
import group73 from '../../assets/images/top_image_pos.webp';
import qbMobile from '../../assets/images/mobile_qb_icon.svg';
import {CountryCodeSelect} from '../common/qbStrap';
import cross from '../../assets/images/cross.svg';

const $ = window.$;

class StartTrialTop extends Component {
    render() {
        return (
            <div id="start_trial_top" style={{minHeight: '648px'}}>
                <div style={{zIndex: 2, width: '55%'}}>
                    <h1 className="system-font" style={{color: 'white', marginBottom: '30px', marginTop: '19px', minWidth: 'fit-content'}}>India's Business Super App</h1>
                    <p className="system-font" style={{color: 'white', fontSize: '28px', lineHeight: '33px', letterSpacing: '0.42px', width: '41.11vw', marginBottom: '40px'}}>
                    Full-Stack POS application to manage your Billing, Inventory, Khata, Online Dukaan, Customers, Loyalty & more.
                    </p>
                    <div className="get-started" style={{ width: '70%' }}>
                        <div>
                            <form className="signup-form" id="signup-form1">
                                <input type="hidden" name="routeParam" value="signUp"/>
                                <input type="hidden" name="useEntity" value="0"/>
                                <input type="hidden" name="checkDevice" value="0"/>
                                <input type="hidden" name="mainPage" value="1"/>
                                <input type="hidden" name="check" id="check10" value = ""/>
                                <input type="hidden" name="check1" id="check11" value = ""/>
                                <input type="hidden" name="phoneCode" id="phoneCode21" value = "91"/>
                                <input type="hidden" name="mobile" id="mobile21" value = ""/>
                                <div style={{ display: 'flex' }}>
                                    <CountryCodeSelect
                                        inputID={'signup-mobile1'}
                                        width={'100%'}
                                        height={'46px'}
                                        margin={'0 0 20px 0'}
                                        placeholder={'Mobile number'}
                                        inputStyles={{ margin: 0 }}
                                        inputgroup={true}
                                        onChange={(value) => {
                                            $('#phoneCode21').val(value.phoneCode);
                                            $('#mobile21').val(value.phoneNumber);
                                            $('#signup-country1').val(value.country);
                                        }} 
                                    />
                                </div>
                                <div style={{marginBottom: '20px'}}>
                                    <div id="signup-messages-error2" className='signup-messages-error' style={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        lineHeight: 1.83,
                                        letterSpacing: '-0.26px',
                                        color: '#FFFFFF',
                                        height: 'fit-content',
                                        display: 'block'
                                    }}></div>
                                    <div id="signup-messages2" style={{
                                            marginTop: 0,
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: 1.83,
                                            letterSpacing: '-0.26px',
                                            color: '#FFFFFF',
                                            height: 'fit-content',
                                            display: 'block'
                                        }} className="system-font signup-messages"></div>
                                </div>
                                <div>
                                    <input type="hidden" name="country" id="signup-country1" value="India" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '70%' }}>
                        <img loading={'lazy'} src={googlePlay} style={{width: 'auto', height: '46px', marginRight: '20px', cursor: 'pointer', maxWidth: '208px'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dpdtech.application.mpos')}} alt="download" />
                        {/* <div style={{width: 'auto', height: '46px', padding: '0 20px', border: 'solid 1.5px #ffffff', borderRadius : '6px', backgroundColor: '#00000000', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', maxWidth: '208px'}} data-id="schedule_demo" onClick={this.scrollToDiv}>
                            <span className="system-font" style={{fontSize: '20px', fontWeight: 500, lineHeight: '26px', pointerEvents: 'none'}}>Request a Demo</span>
                        </div> */}
                    </div>
                </div>
                <div style={{width: '45%',  marginBottom: '128px'}}>
                    <img src={group73} style={{width: '100%', height: 'auto'}} alt='POS' />
                </div>
            </div>
        );
    }

    componentDidMount() {
        $('#start_trial_top').css('padding-top', ($('#main_header').height()+(window.innerWidth <= 800 ? 30 : 50))+'px');
    }

    requestDemoBtn() {
        $('#start_trial_modal').modal('show');
    }

    showSignupModal(e) {
        e.preventDefault();

        $('#signup-modal').modal('show');
    }

    scrollToDiv(e) {
      let $ = window.$;
      let id = $(e.target).data('id');
      if (!$('#'+id).length) return;
      $('html, body').animate({
          scrollTop: $('#'+id).offset().top-68
      }, 1000);
    }
}

export default StartTrialTop;