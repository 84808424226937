import React, {Component} from 'react';

import Header from '../common/Header';
import Footer from '../sections/Footer';
import './Article.scss';
import main from '../main';

const $ = window.$;

class Article extends Component {
    constructor() {
        super()

        this.state = {fetchCall: null}
    }

    render() {
        if (this.state.fetchCall) {
            return (
                <div>
                    <Header/>
                    <h1 id="article_heading" style={{textAlign: 'center', marginTop: '108px'}}></h1>
                    <div id="article_div" style={{marginTop: '70px'}}></div>
                    <Footer/>
                </div>
            )
        } else return null;
    }

    componentDidMount() {
        let self = this;
        let pa = window.location.pathname.split('/');
        let slug = pa[pa.indexOf('article')+1];

        $.ajax({
            url: `//${window.location.host}/support/fetchArticle/${slug}`,
            method: 'get',
            success: function(res) {
                if (res.status) {
                    self.setState({fetchCall: true})
                    $('#article_heading').text(res.article.name);
                    $('#article_div').html(res.article.article);
                }
            }
        })
        
        // var s = document.createElement( 'script' );
        // s.setAttribute( 'type', 'text/javascript' );
        // s.setAttribute( 'src', '/js/client.min.js' );
        // document.body.appendChild( s );
        
        // s = document.createElement( 'script' );
        // s.setAttribute( 'type', 'text/javascript' );
        // s.setAttribute( 'src', '/js/main.js' );
        // document.body.appendChild( s );

        main();
    }
}

export default Article;