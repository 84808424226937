import CustomIndustryWiseFormV2 from "./CustomIndustryWiseFormV2";
import React from "react";
import NewIndustryWiseForms from "./CustomIndustryWiseFormsDesktopV2";

const ElectronicsFormV2 = (props) => {
   const screenWidth = window.screen.width;
   const outletTypes = [
      "Mobile & Accessories Shops",
      "Home",
      "Appliances",
      "Electronics Showrooms",
   ];

   const features = [
      {
         title: "Billing",
         content: "Create a catalog of products to bill your customers with ease",
      },
      {
         title: "CRM & Loyalty",
         content: "A suite of CRM & Loyalty tools to manage your customers",
      },
      {
         title: "Inventory Management",
         content: "Record & manage your inventory across all locations in one place",
      },
      {
         title: "eStore",
         content: "Create your own website & start selling online within minutes",
      },
      {
         title: "Brand and Bank EMI",
         content: "Give customers exciting offers and sell better with Brand and Bank EMI",
      },
      {
         title: "And many more...",
         content: "",
      },
   ];

   if(screenWidth<900){
      return (
         <>
            <CustomIndustryWiseFormV2
               resType={"Electronics"}
               outletTypes={outletTypes}
               features={features}
               color={"#F05C63"}
               {...props}
            />
         </>
      );
   }else{
      return(
         <NewIndustryWiseForms outLetType={outletTypes} features={features}  resType={"Electronics"}   color={"#F05C63"}/>
      )
   }

};
export default ElectronicsFormV2;
