import React, { Component, useEffect, useState } from "react";
import { CountryCodeSelect } from "../common/qbStrap";
import { Button, Card, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { SelectDrop } from "./SelectDrop";
import OTPInput from "react-otp-input";

const $ = window.$;
const LandingPageForm = (props) => {
   const history = useHistory();
   const [state, setState] = useState({
      fullname: "",
      mobileNo: "",
      email: "",
      business: "",
      mobileNoError: false,
      emailError: false,
      businessError: false,
      nameError: false,
      formResponse: false,
      submittingForm: false,
      cityError: false,
      otpErr: false,
   });
   const [city, setCity] = useState(null);
   const [verifyOtp, setVerifyOtp] = useState(false);
   const [myOtpInput, setMyOtpInput] = useState("");
   const [cityOptions, setCityOptions] = useState([]);
   const [otp, setOtp] = useState("");
   const [otpMessage, setOtpMessage] = useState("");
   const [payload, setPayload] = useState({
      state: "",
      errors: {},
   });
   useEffect(() => {
      setState({
         ...state,
         otpErr: false,
      });
   }, [myOtpInput]);
   const handleChangeName = (e) => {
      setState({
         ...state,
         fullname: e.target.value,
      });
   };
   const handleChangeMobile = (e) => {
      setState({
         ...state,
         mobileNo: e.target.value,
         mobileNoError: false,
      });
   };
   //    const handleChangeEmail = (e) => {
   // 	setState({
   // 	   ...state,
   // 	   emailError: false,
   // 	   email: e.target.value,
   // 	});
   //  };
   const handleChangeBusinessName = (e) => {
      setState({
         ...state,
         businessError: false,
         business: e.target.value,
      });
   };
   const getCityData = async (characterEntered) => {
      setState({
         ...state,
         cityError: false,
      });
      let country = "INDIA";
      let city = characterEntered;
      try {
         let cities = [];
         if (city.length > 0) {
            let response = await fetch(
               "https://api.queuebuster.co/API/public/v2/merchant/findCityAll",
               {
                  method: "POST",
                  headers: {
                     Accept: "application.json",
                     "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ city, country }),
               }
            );
   
            const data = await response.json();
            if (data && data.cities) {
               cities = data.cities;
            }
         }
         cities =
            cities &&
            cities.map((el) => {
               el.label = el.city;
               el.value = el.city;
               return el;
            });
         setCityOptions(cities);
      } catch (e) {
         console.log(e);
      }
   };
   const apiCall = () => {
      var EMAIL_VALIDATION_REGEX =
         /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      let { fullname, mobileNo, email, business } = state;
      if (!fullname.length) {
         setState({ ...state, nameError: true });
         return;
      }
      if (mobileNo[0] == 0) {
         mobileNo = mobileNo.slice(1, mobileNo.length);
      }
      if (mobileNo.length != 10) {
         setState({ ...state, mobileNoError: true });
         return;
      }
      if (!business.length) {
         setState({ ...state, businessError: true });
         return;
      }
      if (!city.value.length) {
         setState({ ...state, cityError: true });
         return;
      }
      // if (email.match(EMAIL_VALIDATION_REGEX) == null) {
      //    setState({ ...state, emailError: true });
      //    return;
      // }
      // setState({
      //    submittingForm: true,
      // });
      setVerifyOtp(true);
      let data = {
         phoneCode: 91,
         mobile: mobileNo,
         firstname: fullname,
         lastName: "",
         username: mobileNo,
         country: "India",
         autoGeneratePassword: true,
         email: "support@queuebuster.co",
         isDemoRequest: 1
      };
      fetch("https://api.queuebuster.co/API/public/v2/merchant/sendOtpWeb", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
         },
         body: JSON.stringify(data),
      })
         .then((res) => {
            res.json().then((data) => {
               setOtp(data.otp);
               setOtpMessage(data.message);
            });
         })
         .catch((e) => console.log("e", e));
   };
   const apiCall2 = () => {
      let { fullname, mobileNo, email, business } = state;
      let data = {
         otp: otp,
         userOtp: myOtpInput,
      };

      let obj = {
         countryCode: 91,
         phone: mobileNo,
         name: fullname,
         businessType: business,
         city: city.value,
         purpose: "marketing",
      };

      fetch("https://api.queuebuster.co/API/public/v2/merchant/verifyOtp", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
         },
         body: JSON.stringify(data),
      })
         .then((res) => {
            res.json().then((res) => {
               if (res.status === true) {
                  setState({ ...state, submittingForm: true });
                  fetch("https://api.queuebuster.co/API/public/v2/merchant/demoRequestMarketing", {
                     method: "POST",
                     headers: {
                        "Content-Type": "application/json",
                        // "Access-Control-Allow-Origin": "*",
                     },
                     body: JSON.stringify(obj),
                  })
                     .then((res) => {
                        const data = { resType: "marketing", fullname: fullname };
                        history.push("/thankyou", data);
                     })
                     .catch((e) => console.log("e", e));
               } else {
                  setState({
                     ...state,
                     otpErr: true,
                  });
               }
            });
         })
         .catch((e) => console.log("e", e));
   };

   return (
      <div
         style={{
            backgroundColor: "white",
            // height: "81vh",
            // marginTop: "15%",
            alignItems: "left",
            borderRadius: "0.6rem 0.6rem 0.6rem 0.6rem",
            // border:"2px solid green"
         }}
      >
         <Form
            className="form"
            style={{
               padding: "2rem",
               height: "100%",
               display: "flex",
               justifyContent: "space-between",
               flexDirection: "column",
               // border:"2px solid red"
            }}
         >
            {verifyOtp == true ? (
               <div style={{}}>
                  <h4
                     style={{
                        textAlign: "left",
                        margin: "0 0 2rem",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "1.56rem",
                        lineHeight: "",
                        fontFamily: "Poppins, sans-serif",
                        color: "#767881",
                        letterSpacing: "0%",
                     }}
                  >
                     Please Verify your OTP
                  </h4>

                  <OTPInput
                     value={myOtpInput}
                     onChange={setMyOtpInput}
                     numInputs={4}
                     renderSeparator={<span></span>}
                     renderInput={(props) => <input {...props} />}
                     inputStyle={{ width: "3rem", height: "3rem", marginRight: "1rem" }}
                  />
                  <p
                     style={
                        state.otpErr == true
                           ? { fontSize: "10px", color: "red", marginTop: "10px" }
                           : { fontSize: "10px", color: "red", display: "none" }
                     }
                  >
                     Enter Valid OTP
                  </p>
               </div>
            ) : (
               <div style={{}}>
                  <h4
                     style={{
                        textAlign: "left",
                        margin: "0 0 2rem",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "bold",
                        fontSize: "1.56rem",
                        lineHeight: "",
                        fontFamily: "Poppins, sans-serif",
                        color: "#767881",
                        letterSpacing: "0%",
                     }}
                  >
                     <span
                        style={{
                           color: "#3c69af",
                           fontStyle: "normal",
                           fontVariant: "normal",
                           fontWeight: "900",
                           fontSize: "1.56rem",
                           lineHeight: "3rem",
                        }}
                     >
                        Interested?
                     </span>
                      Let's talk
                     <br />
                     Please share below details and we will call you back.
                  </h4>
                  <FormGroup>
                     <Input
                        required={true}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        style={{
                           height: "3rem",
                           borderRadius: "0.4rem",
                        }}
                        onChange={(e) => handleChangeName(e)}
                     />
                     <p
                        style={
                           state.nameError && !state.fullname
                              ? { fontSize: "10px", color: "red" }
                              : { fontSize: "10px", color: "red", display: "none" }
                        }
                     >
                        Please Enter Your Name
                     </p>
                  </FormGroup>
                  <FormGroup>
                     <Input
                        type="number"
                        name="mobileNo"
                        id="mobileNo"
                        placeholder="Mobile Number"
                        style={{
                           height: "3rem",
                           borderRadius: "0.4rem",
                        }}
                        onChange={(e) => {
                           handleChangeMobile(e);
                        }}
                        required={true}
                     />
                     <p
                        style={
                           state.mobileNoError
                              ? { fontSize: "10px", color: "red" }
                              : { fontSize: "10px", color: "red", display: "none" }
                        }
                     >
                        Please Enter Correct Phone Number
                     </p>
                  </FormGroup>
                  {/* <FormGroup>
               			<Input
               				type="text"
               				name="email"
               				id="emailId"
               				placeholder="E-mail ID"
               				style={{
               					height: "3rem",
               					borderRadius: "0.4rem",
               				}}
               				onChange={(e) => {
               					handleChangeEmail(e)
               				}}
               			/>
               			<p style={state.emailError ? { fontSize: '10px', color: 'red' } : { fontSize: '10px', color: 'red', display: 'none' }}>Please Enter Correct Email Address</p>
               		</FormGroup> */}
                  <FormGroup>
                     <Input
                        type="text"
                        name="businessName"
                        id="business"
                        placeholder="Business Name"
                        style={{
                           height: "3rem",
                           borderRadius: "0.4rem",
                        }}
                        onChange={(e) => {
                           handleChangeBusinessName(e);
                        }}
                     />
                     <p
                        style={
                           state.businessError
                              ? { fontSize: "10px", color: "red" }
                              : { fontSize: "10px", color: "red", display: "none" }
                        }
                     >
                        Please Enter business name
                     </p>
                  </FormGroup>
                  <FormGroup>
                     <SelectDrop
                        name="addCity"
                        classes="no-sep-select"
                        value={city}
                        options={cityOptions}
                        noOptionsMessage={"Enter your city name"}
                        placeholder="Enter your city name"
                        onChange={(value) => {
                           setPayload({ ...payload, state: value.state });
                           setCity(value);
                        }}
                        onInputChange={(characterEntered) => {
                           getCityData(characterEntered);
                        }}
                     />
                     <p
                        style={
                           state.cityError
                              ? { fontSize: "10px", color: "red" }
                              : { fontSize: "10px", color: "red", display: "none" }
                        }
                     >
                        Please select city
                     </p>
                  </FormGroup>
               </div>
            )}

            <div style={{}}>
               <p
                  style={{
                     margin: "8% 0 0 0",
                     fontStyle: "normal",
                     fontVariant: "normal",
                     fontSize: "1rem",
                     lineHeight: "1.3rem",
                     fontFamily: "Poppins, sans-serif",
                     color: "#767881",
                     letterSpacing: "0%",
                  }}
               >
                  {" "}
                  By submitting my details, I agree to QueueBuster's{" "}
                  <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
               </p>
               <Button
                  disabled={state.submittingForm}
                  onClick={verifyOtp == true ? apiCall2 : apiCall}
                  style={{
                     width: "100%",
                     margin: "8% 0 20px 0",
                     backgroundColor: "#3C69AF",
                     borderRadius: "6px",
                     boxShadow: "0px 16px 32px #FB768829",
                     border: "none",
                     fontFamily: "Poppins, sans-serif",
                     fontWeight: 600,
                     height: "3rem",
                     fontSize: "1.2rem",
                  }}
               >
                  {state.submittingForm == true
                     ? "...saving"
                     : verifyOtp == true
                     ? "Verify"
                     : "Request Demo"}
               </Button>
            </div>
         </Form>
      </div>
   );
};

export default LandingPageForm;
