import React, { Component } from 'react'
import './Header.scss'

import logo from "../../assets/images/logo.svg";
import logo_mobile from "../../assets/images/mobile_qb_icon.svg";
import hb from "../../assets/images/hb.svg";
import cross from "../../assets/images/cross.svg";
import user from "../../assets/images/user2.svg";
import dashboard from "../../assets/images/dashboard.svg";
import logout from "../../assets/images/logout.svg";
import { connect } from 'react-redux';
import { changeBusinessTypesState } from '../../actions';
// import {FreeBadge} from './qbStrap';
import rounded from '../../assets/images/rounded.svg';
import TopBanner from './TopBanner';
import { NavLink } from 'react-router-dom';

const $ = window.$;

class Header extends Component {
  constructor() {
    super()

    this.isDropdownVisible = false;
    this.isMobileMenuCollapsed = true;
    this.expandMobileMenu = this.expandMobileMenu.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.scrollToDivMobile = this.scrollToDivMobile.bind(this);
    this.showLoginModal = this.showLoginModal.bind(this);

    window.getCookie = function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  }

  render() {
    return (
      <div id="main_header" className="header fixed-header flex-row-center" style={{flexWrap: 'wrap'}}>

        <TopBanner />
        <div className="container flex-row-center" style={{ justifyContent: "space-between", padding: 0, margin: '16px 8.3% 15px' }}>
          <div>
            <img loading={'lazy'} src={window.innerWidth > 1004 ? logo : logo_mobile} style={{ cursor: 'pointer' }} className="header-logo" onClick={() => { window.location.assign('/') }} />
          </div>
          <div className="header-right">
            <ul className='font-medium-14-dark flex-row-center' style={{paddingLeft: 0}}>
              <li className='flex-row-center system-font' style={{ height: '30px', position: 'relative', display: 'flex' }} id="business_types_li">
                Business Types

                <div id="header_dropdown" style={{ display: 'flex', visibility: 'hidden', flexDirection: 'column', position: 'absolute', top: '31px', left: '-4px', boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.2)', backgroundColor: 'white', padding: '9px 0', borderRadius: '8px', width: '126px' }}>
                  <a href="#" style={{ padding: '7px 14px', fontSize: '13px', fontWeight: 'bold' }} data-id="retail" onClick={this.scrollToDiv}>General Retail</a>
                  <a href="#" style={{ padding: '7px 14px', fontSize: '13px', fontWeight: 'bold' }} data-id="restaurants" onClick={this.scrollToDiv}>Restaurants</a>
                  <a href="#" style={{ padding: '7px 14px', fontSize: '13px', fontWeight: 'bold' }} data-id="grocery" onClick={this.scrollToDiv}>Grocery</a>
                  <a href="#" style={{ padding: '7px 14px', fontSize: '13px', fontWeight: 'bold' }} data-id="spa" onClick={this.scrollToDiv}>Spa & Salon</a>
                </div>
              </li>
              {/* <li className='flex-row-center' style={liStyle}>
                <a className="system-font" href="/solutions" style={{ color: window.location.pathname === '/solutions' ? '#498bdc' : 'black' }}>Solutions</a>
              </li> */}
              <li className='flex-row-center system-font' style={{
                marginLeft: '20px',
                height: '30px',
                position: 'relative'
              }}>
                <a className="system-font" href="/estore" style={{ color: window.location.pathname === '/estore' ? '#498bdc' : 'black' }}>eStore</a>
                {/* <NewBadge /> */}
              </li>
              <li className='flex-row-center system-font' style={{
                marginLeft: '20px',
                height: '30px',
                position: 'relative'
              }}>
                <a className="system-font" href="/khata" style={{ color: window.location.pathname === '/khata' ? '#498bdc' : 'black' }}>Khata</a>
                {/* <NewBadge /> */}
              </li>
              {/* <li className='flex-row-center' style={{
                marginLeft: '20px',
                height: '30px',
              }}>
                <a className="system-font" href="https://blogs.queuebuster.co" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>Blog</a>
              </li> */}
              {/* <li className='flex-row-center' style={liStyle}>
                <a className="system-font" href="/support" style={{ color: window.location.pathname === '/support' ? '#498bdc' : 'black' }}>Support</a>
              </li> */}
              <li className='flex-row-center' style={liStyle}>
                <a className="system-font" href="/pricing" style={{ color: window.location.pathname === '/pricing' ? '#498bdc' : 'black' }}>Pricing</a>
              </li>
              {/* <li className='flex-row-center' style={liStyle}>
                <NavLink className="system-font" to="/team" style={{ color: window.location.pathname === '/team' ? '#498bdc' : 'black' }}>Our Team</NavLink>
              </li> */}
              <li className='flex-row-center' style={liStyle} id="account_li">
                <a className="system-font" href={window.loginURL}>Login</a>
              </li>
              <li className='flex-row-center' style={{
                marginLeft: '20px',
                height: '30px',
              }}>
                <button className="system-font" onClick={this.showSignupModal}
                  style={{
                    width: 'fit-content',
                    borderRadius: '6px',
                    border: 'solid 1px #212b36',
                    padding: '0 10px',
                    backgroundColor: 'white',
                    height: '30px',
                    fontWeight: 500,
                    lineHeight: '100%'
                  }}>Get Started Free</button>
              </li>
            </ul>

            <button
              id="get_started_mobile"
              className="system-font"
              onClick={this.showSignupModal}
              style={{
                width: 'fit-content',
                height: '30px',
                borderRadius: '6px',
                border: 'solid 1px #212b36',
                padding: '0 10px',
                backgroundColor: 'white',
                height: '24px',
                margin: '0 7px 0 0',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '100%',
                color: '#212b36',
                display: 'none'
              }}>
              Get Started Free
            </button>
            <button id="menu_btn" style={{ display: 'none', backgroundColor: '#00000000', border: 'none', outline: 'none' }} onClick={this.expandMobileMenu}><img loading={'lazy'} src={hb} style={{}} /></button>
          </div>
        </div>

        <div id="mobile_menu" style={{ width: '0', height: '0', display: 'none', flexDirection: 'column', overflow: 'hidden', backgroundColor: 'white', position: 'absolute', borderBottomLeftRadius: '14px', borderBottomRightRadius: '14px', alignItems: 'center' }}>
          <div style={{ display: 'block' }}><a href="#" data-id="business_types_2" onClick={this.scrollToDivMobile} className="system-font">Business Types</a></div>
          {/* <div><a href="/solutions" className="system-font" style={{ color: window.location.pathname === '/solutions' ? '#498bdc' : '#212b36' }}>Solutions</a></div> */}
          <div>
            <a href="/estore" className="system-font" style={{ color: window.location.pathname === '/estore' ? '#498bdc' : '#212b36' }}>
              <span style={{position: 'relative'}}>
                eStore
                {/* <NewBadge top={'-8px'} right={'-26px'} /> */}
              </span>
            </a>
          </div>
          <div>
            <a href="/khata" className="system-font" style={{ color: window.location.pathname === '/khata' ? '#498bdc' : '#212b36' }}>
              <span style={{position: 'relative'}}>
                Khata
                {/* <NewBadge top={'-8px'} right={'-26px'} /> */}
              </span>
            </a>
          </div>
          {/* <div><a href="/support" className="system-font" style={{ color: window.location.pathname === '/support' ? '#498bdc' : '#212b36' }}>Support</a></div> */}
          <div><a href="/pricing" className="system-font" style={{ color: window.location.pathname === '/pricing' ? '#498bdc' : '#212b36' }}>Pricing</a></div>
          {/* <div><a href="/team" className="system-font" style={{ color: window.location.pathname === '/team' ? '#498bdc' : '#212b36' }}>Our Team</a></div> */}
          <div style={{ borderBottom: 'none' }}><a href={window.loginURL} className="system-font">Login</a></div>
        </div>
      </div>
    )
  }

  expandMobileMenu() {
    let mobileMenu = document.getElementById('mobile_menu');
    mobileMenu.style.top = `${document.getElementById('main_header').scrollHeight}px`;
    let menuBtn = document.getElementById('menu_btn');

    if (this.isMobileMenuCollapsed) {
      mobileMenu.style.height = 'fit-content';
      mobileMenu.style.width = '100vw';
      menuBtn.innerHTML = `<img loading={'lazy'} src="${cross}" style="margin-bottom: 4px;" />`;
    } else {
      mobileMenu.style.height = '0';
      mobileMenu.style.width = '0';
      menuBtn.innerHTML = `<img loading={'lazy'} src="${hb}" />`;
    }

    this.isMobileMenuCollapsed = !this.isMobileMenuCollapsed;
  }

  toggleDropdown() {
    let self = this;
    let dropdown = document.getElementById('header_dropdown');
    if (this.isDropdownVisible) {
      dropdown.style.visibility = 'hidden';
    }
    else {
      dropdown.style.visibility = 'visible';

      let fun = function () {
        dropdown.style.visibility = 'hidden';
        self.isDropdownVisible = false;
        document.removeEventListener('click', fun);
      }
      document.addEventListener('click', fun)
    }

    this.isDropdownVisible = !this.isDropdownVisible;
  }

  scrollToDiv = (e) => {
    let $ = window.$;
    let id = $(e.target).data('id');
    let n = 0;
    if (id === 'retail')
      n = 0;
    else if (id === 'restaurants')
      n = 1;
    else if (id === 'grocery')
      n = 2;
    else if (id === 'spa')
      n = 3;
    if (window.location.pathname !== '/') {
      localStorage.setItem('landingPageState', `web-scroll-${n}`);
      window.location.assign('/');
      return
    };
    $('html, body').animate({
      scrollTop: $('#business_types_2').offset().top - 68 + 190
    }, 1000);
    this.props.changeBusinessTypesState(n);
  }

  scrollToDivMobile(e) {
    let $ = window.$;
    let id = $(e.target).data('id');
    //  + '.business-type-mobile-item'
    if (window.location.pathname !== '/') {
      localStorage.setItem('landingPageState', `mob-scroll`);
      window.location.assign('/');
      return
    };
    if (!$('#' + id).length) return;
    $('html, body').animate({
      scrollTop: $('#' + id).offset().top - 68
    }, 1000);

    this.expandMobileMenu();
  }

  showLoginModal(e) {
    e.preventDefault();

    $('#login-modal').modal('show');
  }

  showSignupModal(e) {
    e.preventDefault();

    $('#signup-modal').modal('show');
  }

  dashboardRedirection() {
    let userType = window.getCookie('ut');

    if (userType == 'THIRD_PARTY_CHAIN_ADMIN') {
      window.location.assign(window.baseURL + "merchant/thirdPartyDashboard.php");
    } else if (userType == 'CHANNEL_PARTNER') {
      window.location.assign(window.baseURL + "partner/partnerLicenseList.php");
    } else {
      window.location.assign(window.baseURL + "merchant/dashboard.php");
    }
  }

  outsideClick = (e) => {
    if (!$('#account_menu').find(e.target).length && $(e.target) !== $('#account_menu')) {
      $('#account_menu').css('visibility', 'hidden');
      $(document).off('click', this.outsideClick);
    }
  }
}

const btnStyle = {
  width: 'fit-content',
  height: '30px',
  borderRadius: '15px',
  border: 'solid 1px #212b36',
  padding: '0 10px'
}

const liStyle = {
  marginLeft: '20px',
  height: '30px',
}

export default connect(null, { changeBusinessTypesState })(Header);