import React, {Component} from 'react';
import setuppos from '../../assets/images/setuppos.svg';
import signup from '../../assets/images/signup.svg';
import downloadapp from '../../assets/images/downloadapp.svg';
import phone from '../../assets/images/pos_smartphone.webp';
import device from '../../assets/images/pos_device.webp';
import './GetStarted.scss';

class GetStarted extends Component {
    render() {
        return (
            <div style={{height: 'auto', padding: '60px 0 80px 0', position: 'relative'}} id="get_started_div">
                <div style={{margin: 'auto', width: '60%'}}>
                    <h3 style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '30px', fontWeight: 700, color: '#212b36', margin: '0 0 60px 0', lineHeight: 'normal', letterSpacing: '-0.64px', textAlign: 'center'}}>Three Steps to get you Started</h3>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{width: '30%'}}>
                            <img loading={'lazy'} src={downloadapp} style={{display: 'block', margin: 'auto', height: '80px', width: '80px', marginBottom: '20px'}} />
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '22px', fontWeight: 600, color: '#2c2e30', marginBottom: '10px', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center'}}>Download the App</p>
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '0', lineHeight: 1.5, letterSpacing: 'normal', textAlign: 'center'}}>Get started by downloading the app on any of your android devices.</p>
                        </div>
                        <div style={{width: '30%'}}>
                            <img loading={'lazy'} src={signup} style={{display: 'block', margin: 'auto', height: '80px', width: '80px', marginBottom: '20px'}} />
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '22px', fontWeight: 600, color: '#2c2e30', marginBottom: '10px', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center'}}>Sign up for free</p>
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '0', lineHeight: 1.5, letterSpacing: 'normal', textAlign: 'center'}}>Enter your business details to activate a free trial of 30 days.</p>
                        </div>
                        <div style={{width: '30%'}}>
                            <img loading={'lazy'} src={setuppos} style={{display: 'block', margin: 'auto', height: '80px', width: '80px', marginBottom: '20px'}} />
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '22px', fontWeight: 600, color: '#2c2e30', marginBottom: '10px', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center'}}>Set up your POS</p>
                            <p style={{display: 'block', margin: 'auto', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '0', lineHeight: 1.5, letterSpacing: 'normal', textAlign: 'center'}}>Set up fast and easy to start selling in minutes.</p>
                        </div>
                    </div>
                </div>
                <img loading={'lazy'} src={phone} style={{position: 'absolute', left: 0, top: 0, zIndex: -2}} />
                <img loading={'lazy'} src={device} style={{position: 'absolute', right: 0, bottom: 0, zIndex: -2}} />
            </div>
        )
    }
}

export default GetStarted;