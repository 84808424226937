import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import resturantBackground from "../../assets/images/restaurantBackground.jpg";
import retailBackground from "../../assets/images/retail_background.jpg";
import spaBackground from "../../assets/images/spa_background.jpg";
import groceryBackground from "../../assets/images/grocery_background.jpg";
import electronicBackground from "../../assets/images/electronics_background.jpg";
import grayBackground from "../../assets/images/avatar.jpeg";
class MobileLandingPageForm extends Component {
	state = {
		fullname: "",
		mobileNo: "",
		email: "",
		purpose: this.props.match.params.purpose,
		mobileNoError: false,
		emailError: false,
		nameError: false,
		formResponse: false,
		submittingForm: false
	};

	handleChangeName = (e) => {
		this.setState({
			fullname: e.target.value,
		});
	};
	handleChangeMobile = (e) => {
		this.setState({
			mobileNo: e.target.value,
		});
	};

	handleChangeEmail = (e) => {
		this.setState({
			email: e.target.value,
		});
	};

	apiCall = () => {
		var EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
		let { fullname, mobileNo, email } = this.state;
		if (!fullname.length) {
			this.setState({ nameError: true })
			return;
		}
		if (mobileNo[0] == 0) {
			mobileNo = mobileNo.slice(1, mobileNo.length)
		}
		if (mobileNo.length != 10) {
			this.setState({ mobileNoError: true })
			return
		}
		if (email.match(EMAIL_VALIDATION_REGEX) == null) {
			this.setState({ emailError: true })
			return
		}
		this.setState({
			submittingForm: true
		})
		let obj = {
			name: fullname,
			phoneCode: 91,
			mobile: mobileNo,
			email: email,
			purpose: this.state.purpose,
		};
		if (fullname, mobileNo, email) {

			fetch(window.location.origin + "/mail/demo", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				body: JSON.stringify(obj),
			})
			.then((res) => {
				if (res) {
					const data = { resType: obj.purpose, fullname: obj.fullname }
					this.props.history.push('/thankyou', data)
				}
			})
			.catch((e) => console.log("e", e));
		};
	}


	render() {
		let { fullname, mobileNo, email } = this.state;
		var urlMobile = ""
		if (window.location.search && window.location.search != "") {
			urlMobile = (window.location.search).replace("?phone=", "")
		}
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					alignItems: "left",
				}}
			>
				<Form className="form" style={{
					padding: "2rem",
					height: "100%",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
				}}>
					<div style={{}}>
						<h4
							style={{
								textAlign: "left",
								margin: "0 0 2rem",
								fontStyle: "normal",
								fontVariant: "normal",
								fontWeight: "bold",
								fontSize: "1.56rem",
								lineHeight: "",
								fontFamily: "Poppins, sans-serif",
								color: "#767881",
								letterSpacing: "0%",
							}}
						>
							Please share your details
						</h4>
						<FormGroup>
							<Input
								type="text"
								name="name"
								id="name"
								placeholder="Name"
								style={{
									height: "4rem",
									borderRadius: "0.4rem",
								}}
								onChange={(e) => this.handleChangeName(e)}
							/>
							<p style={this.state.nameError && !this.state.fullname ? { fontSize: '10px', color: 'red' } : { fontSize: '10px', color: 'red', display: 'none' }}>Please Enter Your Name</p>
						</FormGroup>
						<FormGroup>
							<Input
								type="number"
								name="mobileNo"
								id="mobileNo"
								placeholder="Mobile Number"
								style={{
									height: "4rem",
									borderRadius: "0.4rem",
								}}
								onChange={(e) => {
									this.handleChangeMobile(e)
									this.setState({ mobileNoError: false })
								}}
								required={true}
								value={urlMobile}
							/>
							<p style={this.state.mobileNoError ? { fontSize: '10px', color: 'red' } : { fontSize: '10px', color: 'red', display: 'none' }}>Please Enter Correct Phone Number</p>
						</FormGroup>
						<FormGroup>
							<Input
								type="text"
								name="email"
								id="emailId"
								placeholder="E-mail ID"
								style={{
									height: "4rem",
									borderRadius: "0.4rem",
								}}
								onChange={(e) => {
									this.handleChangeEmail(e)
									this.setState({ emailError: false })
								}}
							/>
							<p style={this.state.emailError ? { fontSize: '10px', color: 'red' } : { fontSize: '10px', color: 'red', display: 'none' }}>Please Enter Correct Email Address</p>
						</FormGroup>
					</div>
					<div>
						<p
							style={{
								fontStyle: "normal",
								fontVariant: "normal",
								fontSize: "1rem",
								lineHeight: "1.3rem",
								fontFamily: "Poppins, sans-serif",
								color: "#767881",
								letterSpacing: "0%",
								width: "100%"
							}}
						>
							{" "}
							By submitting my details, I agree to QueueBuster's <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>
						</p>
						<Button
							disabled = {this.state.submittingForm}
							onClick={this.apiCall}
							style={{
								// width: "100%",
								margin: "2rem 0 0",
								backgroundColor: "#3C69AF",
								borderRadius: "6px",
								boxShadow: "0px 16px 32px #FB768829",
								border: "none",
								fontFamily: "Poppins, sans-serif",
								fontWeight: 600,
								height: "4.5rem",
								fontSize: "1.2rem",
								width: "100%",
							}}
						>
							{this.state.submittingForm ? "Saving..." : "Request Demo"}
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}
export default MobileLandingPageForm;