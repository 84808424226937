import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import './partners.scss'

import p1 from '../../assets/images/pinelabs.webp'
import p2 from '../../assets/images/mosambee.webp'
import p3 from '../../assets/images/ezetap.webp'
import p4 from '../../assets/images/hdfcbank.webp'
import p5 from '../../assets/images/imin.webp'
import p6 from '../../assets/images/sunmi.webp'
import p7 from '../../assets/images/pinetree.webp'
import p8 from '../../assets/images/epson.webp'

class Partners extends Component {
    render() {
        let l = window.location.pathname === '/business';
        return (
            <div className='width-70 mg-100' id="partners">
                <h3 style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '30px', fontWeight: 700, color: '#212b36', marginBottom: '10px', lineHeight: 'normal', letterSpacing: '-0.64px'}}>Our Partners</h3>
                <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '50px', lineHeight: 1.5, letterSpacing: 'normal'}}>Partnered with some of the best in class companies across India</p>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <img loading={'lazy'} src={p1} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p2} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p3} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p4} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p5} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p6} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p7} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                    <img loading={'lazy'} src={p8} style={{width: '24%', height: 'auto', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px'}}/>
                </div>

                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={false}
                    infiniteLoop={true}
                    axis={"horizontal"}
                    centerMode={true}
                    centerSlidePercentage={70}>
                        <img loading={'lazy'} src={p1} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p2} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p3} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p4} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p5} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p6} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p7} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                        <img loading={'lazy'} src={p8} style={{height: 'auto', boxShadow: '0 0 10px 0 #ddd', margin: '10px'}} />
                </Carousel>
            </div>
        )
    }

    renderSlides() {

    }
}

export default Partners