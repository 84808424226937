import React, { Component, Fragment } from "react";
import "./SignupModal.scss";
import qbMobile from "../../assets/images/mobile_qb_icon.svg";
import { CountryCodeSelect } from "../common/qbStrap";
import cross from "../../assets/images/cross.svg";
import bars from "../../assets/images/md-image.svg";
import ms from "../../assets/images/merchantSignup.svg";
import "./MerchantSignup.scss";
import Header from "../common/Header";
import Footer from "../sections/Footer";
import main from '../main';
const $ = window.$;

class SignupModal extends Component {
  state = {soErr: false };
  

  componentDidMount() {
    main();
    const ths = this;
    const leadID = new URL(window.location.href).searchParams.get("leadID");

    const data = { check: window.check, check1:window.check1 , useEntity: 1, mainPage: 1, leadID, routeParam: "leadDetail" };

    if(!leadID) window.location.assign("/");

    $.ajax({
      url: `manage/sendData.php`,
      type: "POST",
      data,
      success: function (res) {
        const state = JSON.parse(res).data[0];
        ths.setState({ ...state });
      },
      error: function (err) {
        console.log(err.message || "Something went wrong!");
        window.location.assign("/");
      },
    });


  }

  setInputValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { firstName, leadAddLine1, leadAddLine2, companyName, storeName, phone, soErr, email, otp, yPlan1} = this.state;
    return (    
      <div style={{backgroundSize: "contain",backgroundImage:  "url(" + ms + ")"}}>
          <Header/>
            <section id="bothContainer">
              <aside class="rightContainer">
                  <img src={bars} alt="image" />
              </aside>
              <section class="leftContainer">
                  <form class="needs-validation signup-form" id="signup-form1"  noValidate>
                      <input type="hidden" name="routeParam" />
                  <header>
                    <h2 id="title"><bold>Let's Get Started</bold></h2>
                    <h5>Sign up by creating an account with us</h5>
                  </header>
                  <article>
                      <label>Merchant Details</label>
                      <div class="form-row">
                      <input type="hidden" name="check" value={window.check}/>
                      <input type="hidden" name="check1" value={window.check1}/>
                      <input name="useEntity" type="hidden" value = "1"/>
                      <input name="mainPage"  type="hidden" value = "1"/>
                        
                        <div class="col-md-4 mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="validationCustom01"
                            placeholder="Name"
                            name="firstName"
                            value={firstName}
                            onChange={this.setInputValue}
                            required
                          />
                        </div>

                      <input name="mobile"  type="hidden" value = {phone}/>

                        <div class="col-md-4 mb-3">
                          <CountryCodeSelect
                            inputID="signup-mobile1"
                            width={"100%"}
                            height={"38px"}
                            margin={"0 0 20px 0"}
                            placeholder={"Mobile number"}
                            inputStyles={{ margin: 0 }}
                            pointerEvents={false}
                            value={phone}
                            name="phone"

                          />
                        </div>

                        <div class="col-md-4 mb-3">
                          <input
                             type="email"
                             class="form-control"
                             name={"email"}
                             value={email}
                             id="validationCustom02"
                             placeholder="email@gmail.com"
                             required
                             disabled
                           />
                        </div>

                        </div>

                      <label>Business Details</label>
                      <div class="form-row mb-3">

                      <div class="col-md-4 mb-3">
                        <input
                           type="text"
                           class="form-control"
                           id="validationCustom05"
                           placeholder="Company Name"
                           name="companyName"
                           value={companyName}
                           onChange={this.setInputValue}
                           required
                         />
                         <div class="invalid-feedback">
                           Please provide a valid Company Name.
                         </div>
                       </div>

                      <div class="col-md-4 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom05"
                          placeholder="Store Name"
                          name="storeName"
                          value={storeName}
                          onChange={this.setInputValue}
                          required
                        />
                        <div class="invalid-feedback">
                          Please provide a valid Store Name.
                        </div>
                      </div>
                      
                      <div class="col-md-4 mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom03"
                          placeholder="Address Line 1"
                          name="leadAddLine1"
                          value={leadAddLine1}
                          onChange={this.setInputValue}
                          required
                        />
                        <div class="invalid-feedback">
                          Please provide a valid address.
                        </div>
                      </div>

                    {/* </div> */}

                    {/* <div class="form-row"> */}
                        <div class="col-md-4 mb-3">
                           <input
                             type="text"
                             class="form-control"
                             id="validationCustom04"
                             placeholder="Address Line 2"
                             name="leadAddLine2"
                             value={leadAddLine2}
                             onChange={this.setInputValue}
                             required
                           />
                           <div class="invalid-feedback">
                             Please provide a valid address.
                           </div>
                        </div>
                    </div>
              
                    <label>License Details</label>
                    <div class="form-row">
                      <div class="mb-3">
                        <h6><bold>Annual Plan Price: {yPlan1}</bold></h6>
                      </div>
                    </div>

                  </article>
                  <footer>
                      <p><small>By signing up on QueueBuster, you are agreeing to our
                        <a href="/termsAndServices" style={{ fontWeight: 700 }} target="_blank" > Terms of Service</a>
                        {" and "}<a href="/privacyPolicy" style={{ fontWeight: 700 }} target="_blank">Privacy Policy</a>
                        </small></p>
                      <button class="btn btn-primary signupBtn" type="submit">Submit</button>
                  </footer>
                  </form>
              </section>
            </section>
          <Footer/>
      </div>
  )}
}

export default SignupModal;
