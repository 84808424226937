import React,{Component} from 'react'
import './startTrial.scss'
import stbg from '../../assets/images/trustedby_bg.webp';
import googlePlay from '../../assets/images/google_play.svg';

const $ = window.$;

class StartTrial extends Component{
    render(){
        return(
            <div className="trial-div" style={{background: 'url('+ stbg + ')', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%'}}>
                <div>
                    <h4 className="system-font">Start your 30-days trial today!</h4>
                    <img loading={'lazy'} src={googlePlay} style={{width: '231px', height: '67px', margin: 'auto', marginTop: '45px', display: 'block', cursor: 'pointer'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dpdtech.application.mpos')}} />
                </div>
            </div>
        )
    }

    requestDemoBtn() {
        $('#start_trial_modal').modal('show');
    }

    showSignupModal(e) {
        e.preventDefault();

        $('#signup-modal').modal('show');
    }
}

export default StartTrial