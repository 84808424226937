import React, {Component} from 'react';
import spa2 from '../../assets/images/salon.webp';
import retail from '../../assets/images/retail.webp';
import grocery from '../../assets/images/grocery.webp';
import restaurant from '../../assets/images/restaurant.webp';

import beauty from '../../assets/images/beauty_salon.webp';
import spa from '../../assets/images/spa.webp';
import massage from '../../assets/images/massage_center.webp';
import salon from '../../assets/images/hair_salon.webp';

import fruitveg from '../../assets/images/fruitandvegetables.webp';
import hypermarket from '../../assets/images/hypermarket.webp';
import supermarket from '../../assets/images/supermarket.webp';
import kirana from '../../assets/images/kirana.webp';

import buffet from '../../assets/images/buffets.webp';
import foodtrucks from '../../assets/images/food_truck.webp';
import QSR from '../../assets/images/qsr.webp';
import finedine from '../../assets/images/fine_dine.webp';

import electronics from '../../assets/images/electronics.webp';
import toys from '../../assets/images/toys.webp';
import cosmetics from '../../assets/images/cosmetics.webp';
import apparel from '../../assets/images/apparel.webp';

import check from '../../assets/icons/green_check.svg';

import foodcart from '../../assets/images/food_cart.webp';
import cafes from '../../assets/images/cafes.webp';
import bars from '../../assets/images/bars.webp';
import bakery from '../../assets/images/bakery.webp';

import jwellery from '../../assets/images/jewellery.webp';
import stationery from '../../assets/images/stationery.webp';
import homerdecor from '../../assets/images/home_decor.webp';
import footwear from '../../assets/images/footwear.webp';
import {connect} from 'react-redux';
import './BusinessTypes2.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

class BusinessTypes2 extends Component {
    types = [
        {name: 'General Retail', image: retail, description: 'From mom & pop outlet to large format retail chain, QueueBuster is highly customizable for all retail formats. Just tap on some settings and you are good to go.', bullets: [
            {title: 'Inventory management', desc: 'Generate your own barcodes, manage your inventory at product / SKU level, batch wise stock-in/out'},
            {title: 'Customer relationship management', desc: 'Design and run loyalty programs, promotions, and spot discounts'},
            {title: 'Track Sales', desc: 'Manage and review complete sales with detailed analytics and unified reporting'}
        ], supportedFormats: [
            {title: 'Apparel', image: apparel},
            {title: 'Cosmetics', image: cosmetics},
            {title: 'Electronics', image: electronics},
            {title: 'Toys', image: toys},
            {title: 'Footwear', image: footwear},
            {title: 'Home & Decor', image: homerdecor},
            {title: 'Jewellery', image: jwellery},
            {title: 'Stationary', image: stationery}
        ]},
        {name: 'Restaurants', image: restaurant, description: 'Managing tables, kitchen, and payments was never so easy without our incredibly intuitive and robust Restaurant POS.', bullets: [
            {title: 'Menu management', desc: 'Manage modifiers, combos, and recipes'},
            {title: 'Ordering', desc: 'Table reservation, split bill, and online orders'},
            {title: 'Manage KOT', desc: 'Print KOT automatically on a same or separate printer'}
        ], supportedFormats: [
            {title: 'Fine Dine', image: finedine},
            {title: 'QSR', image: QSR},
            {title: 'Food Trucks', image: foodtrucks},
            {title: 'Buffets', image: buffet},
            {title: 'Food Carts', image: foodcart},
            {title: 'Cafes', image: cafes},
            {title: 'Bars', image: bars},
            {title: 'Bakery', image: bakery}
        ]},
        {name: 'Grocery Stores', image: grocery, description: 'QueueBuster is customized to handle the scale, speed, and accuracy required in the Grocery segment. Simplify your business and handle the store rush with ease', bullets: [
            {title: 'Credit sales (Khata)', desc: 'Easy maintainence of credit history & settlement, SMS based invoicing'},
            {title: 'Vendor Management', desc: 'Manage vendor accounts, invoices, payment schedules.'},
            {title: 'Other highlights', desc: 'Barcode based sales & inventory management, mobile salesforce, pre-loaded list of 50,000+ products (India)'}
        ], supportedFormats: [
            {title: 'Kirana', image: kirana},
            {title: 'Supermarket', image: supermarket},
            {title: 'Hypermarket', image: hypermarket},
            {title: 'Vegetable & Fruit Stall', image: fruitveg}
        ]},
        {name: 'Spa & Salon', image: spa2, description: 'Use QueueBuster as one stop shop solution to manage customer appointments and sell in-house products.', bullets: [
            {title: 'Appointments', desc: 'Manage your customer appointments with an intuitive and easy-to-use interface'},
            {title: 'Service Slip', desc: 'Quick & easy access to the services availed by a customer'},
            {title: 'Packages & Memberships', desc: 'Manage your client packages, make them silver, gold, or platinum members based on their purchase history'}
        ], supportedFormats: [
            {title: 'Hair Salon', image: salon},
            {title: 'Massage Centers', image: massage},
            {title: 'Beauty Salon', image: beauty},
            {title: 'Spas', image: spa}
        ]},
    ];

    state = {active: 0, photoGrid: this.types[0].supportedFormats.map(v => {return {src: v.image, opacity: 1, transition: true, title: v.title}})}

    lastAnimationCycle = -1;

    render() {
        return (
            <div id="business_types_2" style={{backgroundColor: '#f3f7fd', padding: '60px 16.32vw 93px 15.63vw'}}>
                <h1 style={{color: '#212b36', fontSize: '30px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontWeight: 700, marginBottom: '10px'}}>Business Types</h1>

                <p style={{fontSize: '16px', lineHeight: 1.5, color: '#7b7f82', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', width: '550px', textAlign: 'center', margin: 'auto', marginBottom: '74px'}}>
                                QueueBuster is customized for the unique requirements of
                General Retail, Restaurants, Grocery, Spa & Salon, and many other verticals.
                </p>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '50px'}}>
                    {
                        this.types.map((v, i) => (
                            <div 
                                style={{height: '180px', width: '180px', borderRadius: '8px', position: 'relative', overflow: 'hidden', cursor: 'pointer', boxShadow: this.state.active === i ? '0 2px 40px 0 #cfcfd4' : '0 2px 20px 0 #cfcfd4', transform: this.state.active === i ? 'scale(1.17, 1.17)' : 'none', transition: 'transform 0.3s ease'}} 
                                onClick={() => {
                                    if (this.nextAnimTimeout) {
                                        clearTimeout(this.nextAnimTimeout);
                                        this.nextAnimTimeout = null;
                                    }
                                    let currentPhotoGrid = [];
                                    for (let j = 0; j < 4; j++)
                                        currentPhotoGrid.push({src: this.types[i].supportedFormats[j].image, opacity: 0, transition: false, title: this.types[i].supportedFormats[j].title});
                                    this.setState({active: i, photoGrid: currentPhotoGrid}, () => {
                                        setTimeout(() => {
                                            this.setState({active: i, photoGrid: currentPhotoGrid.map(v => {v.opacity = 1; v.transition = true; return v;})})
                                        }, 100)
                                    })
                                    this.lastAnimationCycle = 3;
                                }}>
                                <img loading={'lazy'} src={v.image} style={{position: 'absolute', height: '253px', width: '253px', top: '-36px', left: '-27px'}} />
                                <div className="system-font" style={{backgroundColor: 'white', position: 'absolute', bottom: 0, textAlign: 'center', padding: '9px 0', fontSize: '21px', color: '#2c2e30', fontWeight: 600, width: '100%'}}>
                                    {v.name}
                                </div>
                            </div>
                        ))
                    }
                </div>

                <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={false}
                    showArrows={false}
                    centerMode={true}
                    selectedItem={this.state.active}
                    onChange={(i) => {
                        if (this.nextAnimTimeout) {
                            clearTimeout(this.nextAnimTimeout);
                            this.nextAnimTimeout = null;
                        }
                        let currentPhotoGrid = [];
                        for (let j = 0; j < 4; j++)
                            currentPhotoGrid.push({src: this.types[i].supportedFormats[j].image, opacity: 0, transition: false, title: this.types[i].supportedFormats[j].title});
                        this.setState({active: i, photoGrid: currentPhotoGrid}, () => {
                            setTimeout(() => {
                                this.setState({active: i, photoGrid: currentPhotoGrid.map(v => {v.opacity = 1; v.transition = true; return v;})})
                            }, 100)
                        })
                        this.lastAnimationCycle = 3;
                    }}
                    onClickItem={(i) => {this.setState({active: i})}}
                    centerSlidePercentage={60}>
                        {
                            this.types.map((v, i) => (
                                <div 
                                    style={{height: '180px', width: '180px', borderRadius: '8px', position: 'relative', overflow: 'hidden', cursor: 'pointer', boxShadow: this.state.active === i ? '0 2px 27px 0 #cfcfd4' : '0 2px 20px 0 #cfcfd4', transform: this.state.active === i ? 'scale(1.17, 1.17)' : 'none', transition: 'transform 0.3s ease', margin: 'auto', marginTop: '10px'}}>

                                    <img loading={'lazy'} src={v.image} style={{position: 'absolute', height: '253px', width: '253px', top: '-36px', left: '-27px'}} />
                                    <div className="system-font" style={{backgroundColor: 'white', position: 'absolute', bottom: 0, textAlign: 'center', padding: '9px 0', fontSize: '20px', color: '#2c2e30', fontWeight: 600, width: '100%'}}>
                                        {v.name}
                                    </div>
                                </div>
                            ))
                        }
                </Carousel>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{width: '48%'}}>
                        <p style={{paddingLeft: '28px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '30px', fontWeight: 600, color: '#2c2e30', marginBottom: '10px'}}>{this.types[this.state.active].name}</p>

                        <p style={{paddingLeft: '28px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 600, color: '#7b7f82', marginBottom: '24px', lineHeight: 1.38, letterSpacing: '-0.34px'}}>{this.types[this.state.active].description}</p>
                        {
                            this.types[this.state.active].bullets.map(va => (
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}><img loading={'lazy'} src={check} style={{height: '18px', width: '18px', marginRight: '10px', marginTop: '-3px'}} /><p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '22px', fontWeight: 600, color: '#2c2e30', marginBottom: '6px'}}>{va.title}</p></div>

                                    <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 600, color: '#7b7f82', marginBottom: '30px', lineHeight: 1.38, letterSpacing: '-0.34px', paddingLeft: '28px'}}>{va.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{width: '40%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{opacity: this.state.photoGrid[0].opacity, transition: this.state.photoGrid[0].transition ? 'opacity 1s ease' : 'none', width: '140px'}}>
                                <img loading={'lazy'} src={this.state.photoGrid[0].src} style={{width: '140px', display: 'block', margin: 'auto', marginBottom: '18px'}} />
                                <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontSize: '19.6px', fontWeight: 600, color: '#7b7f82'}}>{this.state.photoGrid[0].title}</p>
                            </div>
                            <div style={{opacity: this.state.photoGrid[1].opacity, transition: this.state.photoGrid[1].transition ? 'opacity 1s ease 2s' : 'none', width: '140px'}}>
                                <img loading={'lazy'} src={this.state.photoGrid[1].src} style={{width: '140px', display: 'block', margin: 'auto', marginBottom: '18px'}} />
                                <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontSize: '19.6px', fontWeight: 600, color: '#7b7f82', marginBottom: '20px'}}>{this.state.photoGrid[1].title}</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{opacity: this.state.photoGrid[2].opacity, transition: this.state.photoGrid[2].transition ? 'opacity 1s ease 1s' : 'none', width: '140px'}}>
                                <img loading={'lazy'} src={this.state.photoGrid[2].src} style={{width: '140px', display: 'block', margin: 'auto', marginBottom: '18px'}} />
                                <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontSize: '19.6px', fontWeight: 600, color: '#7b7f82'}}>{this.state.photoGrid[2].title}</p>
                            </div>
                            <div style={{opacity: this.state.photoGrid[3].opacity, transition: this.state.photoGrid[3].transition ? 'opacity 1s ease 3s' : 'none', width: '140px'}} onTransitionEnd={() => {
                                if (this.types[this.state.active].supportedFormats.length > 4) {
                                    this.nextAnimTimeout = setTimeout(() => {
                                        let currentPhotoGrid = [];
                                        let n = this.lastAnimationCycle === 3 ? 8 : 4;
                                        for (let j = this.lastAnimationCycle === 3 ? 4 : 0; j < n; j++)
                                            currentPhotoGrid.push({src: this.types[this.state.active].supportedFormats[j].image, opacity: 0, transition: false, title: this.types[this.state.active].supportedFormats[j].title});
                                        this.setState({photoGrid: currentPhotoGrid}, () => {
                                            setTimeout(() => {
                                                this.setState({photoGrid: currentPhotoGrid.map(v => {v.opacity = 1; v.transition = true; return v;})})
                                            }, 100)
                                        })
                                        this.lastAnimationCycle = this.lastAnimationCycle === 3 ? 7 : 3;
                                    }, 5000);
                                }
                            }}>
                                <img loading={'lazy'} src={this.state.photoGrid[3].src} style={{width: '140px', display: 'block', margin: 'auto', marginBottom: '18px'}} />
                                <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontSize: '19.6px', fontWeight: 600, color: '#7b7f82', marginBottom: '20px'}}>{this.state.photoGrid[3].title}</p>
                            </div>
                        </div>
                        <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', textAlign: 'center', fontSize: '22px', fontWeight: 600, color: '#2c2e30', marginBottom: '0', marginTop: '10px', flexBasis: '100%'}}>Supported Formats</p>
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        if (this.props.externalBTState && this.props.externalBTState.isApplied === false) {
            let i = this.props.externalBTState.newState;
            let currentPhotoGrid = [];
            for (let j = 0; j < 4; j++)
                currentPhotoGrid.push({src: this.types[i].supportedFormats[j].image, opacity: 0, transition: false, title: this.types[i].supportedFormats[j].title});
            this.setState({active: i, photoGrid: currentPhotoGrid}, () => {
                setTimeout(() => {
                    this.setState({active: i, photoGrid: currentPhotoGrid.map(v => {v.opacity = 1; v.transition = true; return v;})})
                }, 100)
            })
            this.lastAnimationCycle = 3;
            this.props.externalBTState.isApplied = true;
        }
    }
}

function mapStateToProps({externalBTState}) {
    return {externalBTState};
}

export default connect(mapStateToProps)(BusinessTypes2);