import React, {Component} from 'react';
import './ScheduleYourDemo.scss';
import {CountryCodeSelect} from '../common/qbStrap';
import rounded from '../../assets/images/rounded.svg';

const $ = window.$;

class ScheduleYourDemo extends Component {
    render() {
        return (
            <div id="schedule_demo" style={{position: 'relative'}}>
                <h4 style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '30px', fontWeight: 700, color: '#212b36', marginBottom: '10px', lineHeight: 'normal', letterSpacing: '-0.64px'}}>Schedule a Demo</h4>
                <label style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '30px', lineHeight: 1.5, letterSpacing: 'normal'}}>Tell us a little about yourself and we’ll show you a lot more about us.</label>
                <form className="demo-form">
                    <input type="text" placeholder="Name" style={{flexBasis: '48%', outline: 'none', boxShadow: 'none'}} name="name"/>
                    <input type="hidden" name="phoneCode" id="phoneCode" />
                    <input type="hidden" name="mobile" id="mobile" />
                    <CountryCodeSelect
                        width={'48%'}
                        margin={'1%'}
                        placeholder={'Mobile number'}
                        inputStyles={{
                            margin: 0
                        }}
                        onChange={(value) => {
                            $('#phoneCode').val(value.phoneCode);
                            $('#mobile').val(value.phoneNumber);
                        }} />
                    <input type="email" placeholder="E-mail address" style={{flexBasis: '100%', outline: 'none', boxShadow: 'none'}} name="email"/>
                    <textarea placeholder="What would you be using QueueBuster for?" style={{flexBasis: '98%', outline: 'none', boxShadow: 'none'}} name="purpose"/>
                    <button className="system-font" type="submit" style={{outline: 'none', boxShadow: 'none'}}>Request Demo</button>
                    <div className="demo-messages" style={{flexBasis: '100%', marginTop: '12px'}}></div>
                </form>
            </div>
        )
    }
}

export default ScheduleYourDemo;