import React from "react";
import engageBackgroundImage from "../../assets/images/engage/engageBackground.png";
import engageQbLogo from "../../assets/images/engage/engageQbLogo.svg";
import engageHeading from "../../assets/images/engage/engageHeading.png";
import engageBottomLine from "../../assets/images/engage/engageBottomLine.svg";
import engageLinkedin from "../../assets/images/engage/engageLinkedin.png";
import engageTwitter from "../../assets/images/engage/engageTwitter.png";
import engageFacebook from "../../assets/images/engage/engageFacebook.png";
import engageInsta from "../../assets/images/engage/engageInsta.png";
import engageYoutube from "../../assets/images/engage/engageYoutube.png";
import CountdownTimer from "../../utils/CountdownTimer";
import engageMainLogo from "../../assets/images/engage/engageMainLogo.png";

const EngagePampletDesktop = () => {
   return (
      <div
         style={{
            padding: "4rem 0 0 4rem",
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${engageBackgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            overflow: "hidden"
         }}
      >
         <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
               <img src={engageQbLogo} style={{ width: "25rem", marginBottom: "1.5rem" }} />
               <div style={{ fontSize: "2rem", color: "#FFFFFF", marginBottom: "2.5rem" }}>
                  Presents
               </div>
               <img src={engageHeading} style={{ width: "35rem", marginBottom: "1.5rem" }} />
               <div>
                  <img
                     src={engageBottomLine}
                     style={{ width: "37rem", marginBottom: "2.5rem" }}
                  />
               </div>
               <CountdownTimer date={new Date("2023-06-24T09:00:00")} />
               <div style={{ fontSize: "2rem", color: "#FFFFFF", marginBottom: "10px" }}>
                  JW Marriott, Aerocity, New Delhi
               </div>
               <div
                  style={{
                     fontSize: "2rem",
                     fontWeight: "bold",
                     color: "#FFFFFF",
                     marginBottom: "2.5rem",
                  }}
               >
                  24<sup>th</sup> June 2023
               </div>
               <div style={{ display: "flex", gap: "1.87rem", marginBottom: "2.5rem" }}>
                  <img
                     style={{ height: "3rem", cursor: "pointer" }}
                     src={engageLinkedin}
                     onClick={() => {
                        window.open("https://www.linkedin.com/company/queuebuster-pos/mycompany/");
                     }}
                  />
                  <img
                     style={{ height: "3rem", cursor: "pointer" }}
                     src={engageTwitter}
                     onClick={() => {
                        window.open("https://twitter.com/QueueBusterPOS");
                     }}
                  />
                  <img
                     style={{ height: "3rem", cursor: "pointer" }}
                     src={engageFacebook}
                     onClick={() => {
                        window.open("https://www.facebook.com/queuebuster/");
                     }}
                  />
                  <img
                     style={{ height: "3rem", cursor: "pointer" }}
                     src={engageInsta}
                     onClick={() => {
                        window.open("https://www.instagram.com/queuebuster_pos/");
                     }}
                  />
                  <img
                     style={{ height: "3rem", cursor: "pointer" }}
                     src={engageYoutube}
                     onClick={() => {
                        window.open("https://www.youtube.com/channel/UC28aqzrEBKvmuDNH7CMidhg");
                     }}
                  />
               </div>
               <div style={{ font: "normal normal 300 26px/41px", color: "#FFFFFF" }}>
                  © 2023 | All rights reserved
               </div>
            </div>
            <img
               src={engageMainLogo}
               style={{ height: "35rem", width: 'auto', marginTop: "5rem", marginRight: "5rem" }}
            />
         </div>
      </div>
   );
};

export { EngagePampletDesktop };
