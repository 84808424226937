import {FETCH_SUPPORT_DATA, CHANGE_BUSINESS_TYPES_STATE} from './types';

const $ = window.$;

export const fetchSupportData = () => dispatch => {
    $.ajax({
        url: window.location.protocol + '//' + window.location.host + '/support/fetchSupportData',
        method: 'get',
        success: function(res) {
            dispatch({
                type: FETCH_SUPPORT_DATA,
                payload: res
            })
        }
    })
}

export const changeBusinessTypesState = (state) => dispatch => {
    dispatch({
        type: CHANGE_BUSINESS_TYPES_STATE,
        payload: state
    })
}