import React from 'react'
import CustomIndustryWiseForms from './CustomIndustryWiseForms'

const ElectronicsPOSForm = () => {
  return (
    <>
        <CustomIndustryWiseForms resType={"Electronics"} featuresType={["Inventory Management","CRM & Loyalty","Billing"] }   outLetType={["Mobile & Accessories Shops","Home Appliances", "Electronics Showrooms"]} />
    </>
  )
}

export default ElectronicsPOSForm