import React from 'react'
import CustomIndustryWiseForms from './CustomIndustryWiseForms'

const RetailIndustryFrom = () => {
  return (
    <>
    <CustomIndustryWiseForms resType={"Retail"} outLetType={["Fashion & Apparel","Cosmetics","Footwear","Accessories Showrooms"] }   featuresType={["Inventory Management","CRM & Loyalty", "Billing"]} />
    </>
  )
}

export default RetailIndustryFrom