import React, { Component } from 'react';
import GenericPage from '../GenericPage';
import QuerySection from '../sections/QuerySection';
import Footer from '../sections/Footer';
import Header from '../common/Header';
import './ExtraPages.scss'
import main from '../main'

var policy = ""

class TermsAndServices extends Component {
	constructor() {
		super()
		this.state = {
			fetchCall: null
		}
	}

	componentWillMount() {
		// Fetch policy via API
		fetch(`/fetchTerms`)
			.then(response => response.json())
			.then(json => {
				if (json && json.policy) {
					policy = json.policy
					this.setState({ fetchCall: true })
				}
			})
	}

	render() {
		if (this.state.fetchCall) {
			return (
				<div className="extra-page">
					<Header />
					<div dangerouslySetInnerHTML={{ __html: policy }} />
					<QuerySection />
					<Footer />
				</div>
			);
		} else return null;
	}

	componentDidMount() {
		main();
	}
}

export default TermsAndServices;