import React, { useEffect } from "react";
import resturantBackground from "../../assets/images/restaurantBackground.jpg";
import retailBackground from "../../assets/images/retail_background.jpg";
import spaBackground from "../../assets/images/spa_background.jpg";
import groceryBackground from "../../assets/images/grocery_background.jpg";
import electronicBackground from "../../assets/images/electronics_background.jpg";
import grayBackground from "../../assets/images/avatar.jpeg";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";

const ThankYou = (props) => {
 const history= useHistory()
 const userData= (history && history.location && history.location.state) ? history.location.state : {
  resType : "",
  fullname: ""
 }
 useEffect(()=>{
    setTimeout(()=>{
       history.push('/')
    },2000)
 },[])
  return (
    <div
      style={{
        padding: "60px",
        height: " 100vh",
        width: "100vw",
        backgroundImage: `url(${
            userData.resType == "Restaurant"
            ? resturantBackground
            : userData.resType == "Electronics"
            ? electronicBackground
            : userData.resType == "Grocery"
            ? groceryBackground
            : userData.resType == "Retail"
            ? retailBackground
            : userData.resType == "Spa & Salon"
            ? spaBackground
            : grayBackground
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center center",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <Card style={{ height: "20rem" ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding: "1rem 2rem 1rem 2rem",borderRadius: "0.6rem",opacity:'0.7' }}>
        <p
          style={{
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "3.5rem",
            letterSpacing: "0px",
          }}
        >
          Thank You!
        </p>
        <p
          style={{
            textAlign: "center",
            fontStyle: "normal",
            fontVariant: "normal",
            fontSize: "1rem",
            lineHeight: "1.3rem",
            fontFamily: "Poppins, sans-serif",
            color: "#767881",
            letterSpacing: "0%",
          }}
        >
          Hey, {userData.fullname} ! Thank you for your interest in QueueBuster. We
          will contact you soon!
        </p>
      </Card>
    </div>
  );
};

export default ThankYou;
