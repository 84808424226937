import React, {Component} from 'react';
import restaurantPixel from '../../assets/images/restaurant_illustration.svg'
import groceryPixel from '../../assets/images/spa&saloon_illustration.svg'
import spaPixel from '../../assets/images/grocery_illustration.svg'
import retailPixel from '../../assets/images/generalretail_illustration.svg'
import restaurantPixelBgr from '../../assets/images/restaurant_illustration.svg'
import groceryPixelBgr from '../../assets/images/spa&saloon_illustration.svg'
import spaPixelBgr from '../../assets/images/grocery_illustration.svg'
import retailPixelBgr from '../../assets/images/generalretail_illustration.svg'
import check from '../../assets/icons/green_check.svg'
import bulk_data_mgmt from '../../assets/images/bulk_data_mgmt.svg'
import cloud_backup from '../../assets/images/cloud_backup.svg'
import crm_loyalty from '../../assets/images/crm_loyalty.svg'
import customer_invoices from '../../assets/images/customer_invoices.svg'
import integration_iocn from '../../assets/images/integration_iocn.svg'
import inventory_management from '../../assets/images/inventory_management.svg'
import multiple_currencies from '../../assets/images/multiple_currencies2.svg'
import multiple_location from '../../assets/images/multiple_location.svg'
import offline_mode from '../../assets/images/offline_mode.svg'
import partners from '../../assets/images/partners.png'
import paymenmt_icon from '../../assets/images/paymenmt_icon.svg'
import product_catalouge from '../../assets/images/product_catalogue.svg'
import promotion_disocunyt from '../../assets/images/promotion_disocunyt.svg'
import reports_icon from '../../assets/images/reports_icon.svg'
import restaurant from '../../assets/images/restaurant.png'
import roles_permission from '../../assets/images/roles_permisssion.svg'
import supported_business from '../../assets/images/supported_business.png';
import arrowDown from '../../assets/images/arrow_down2.svg';

import './Features.scss';

class Features extends Component {
    startHeight = null;

    render() {
        let self = this;
    
        let featureGrid = [
            {image: product_catalouge, title: 'Product Catalog', content: 'Manage product catalog with SKU level information on prices, taxes, charges, and more'},
            {image: customer_invoices, title: 'Customer Invoices', content: 'Generate proforma invoices, final invoices, credit sales, and no charge orders'},
            {image: inventory_management, title: 'Inventory Management', content: 'A dedicated module to manage outlet level, SKU level stock information of the entire catalog'},
            {image: paymenmt_icon, title: 'Payments', content: 'Accept payments through cash, card, online wallets, UPI, vouchers, credit notes, and cheque'},
            {image: crm_loyalty, title: 'CRM & Loyalty', content: 'Manage your customers, reward them with loyalty points and discounts based on their purchase history'},
            {image: promotion_disocunyt, title: 'Promotions & Discounts', content: 'Give spot discounts or apply them from a pre-defined list created at product or customer level'},
            {image: reports_icon, title: 'Reports', content: 'Get real-time sales updates or dig deeper to analyse your business with our exhaustive set of business reports'},
            {image: roles_permission, title: 'Roles & Permissions', content: 'Create unlimited users (staff) and manage their roles and permissions through your admin dashboard.'},
            {image: cloud_backup, title: 'Cloud Backup', content: 'Hosted on Amazon cloud infrastructure. Loss of your device will not lead to loss of your data.'},
            {image: offline_mode, title: 'Offline Mode', content: 'Works seamlessly without internet. Automatically syncs data once online.'},
            {image: integration_iocn, title: 'Integrations', content: 'Integrated with hundreds of devices, printers, barcode scanners, payment providers & softwares across the globe.'},
            {image: bulk_data_mgmt, title: 'Bulk Data Management', content: 'Managing catalog of hundreds of products was never so easy without our Excel and CSV based bulk upload tools.'},
            {image: multiple_location, title: 'Multiple Locations', content: 'Add a new outlet by the click of a button. Get all your reports sorted automatically without any hassle.'},
            {image: multiple_currencies, title: 'Multiple Currencies', content: 'Go global. Run your business in any available currency.'},
        ]
    
        let isCollapsed = true;
    
        let isCollapsedArray = [true, true, true, true];
    
        function showMore(e) {
            let collapseDiv = document.getElementById('collapse_div');
            let featuresDiv = document.getElementById('features_div');
            let showMoreDiv = document.getElementById('show_more');
    
            if (isCollapsed) {
                collapseDiv.style.height = (collapseDiv.scrollHeight < 1000 ? 1000 : collapseDiv.scrollHeight) + 'px';
                // featuresDiv.style.marginTop = (featuresDiv.offsetHeight * 0.1) + 'px';
                showMoreDiv.innerHTML = `Show Less<img loading={'lazy'} src="${arrowDown}" style="width: 16px; height: 10px; margin: -1px 0 0 8px; transform: rotateX(180deg);" />`;
            } else {
                collapseDiv.style.height = window.innerWidth < 800 ? '1000px' : '460px';
                // featuresDiv.style.marginTop = (self.startHeight * 0.095) + 'px';
                showMoreDiv.innerHTML = `Show More<img loading={'lazy'} src="${arrowDown}" style="width: 16px; height: 10px; margin: -1px 0 0 8px;)" />`;
            }
    
            isCollapsed = !isCollapsed;
        }
    
        function showMoreItem(e) {
            let $ = window.$;
            let self;
            if (e.target.className === 'show-more')
                self = e.target;
            else if (e.target.className === 'triangle-up' || e.target.className === 'triangle-down')
                self = e.target.parentNode;
    
            let pos = +$(self).data('pos');
            
            if (isCollapsedArray[pos]) {
                $(self).prev().css('height', $(self).prev()[0].scrollHeight);
                $(self).html('Show Less <div class="triangle-up"></div>');
            } else {
                $(self).prev().css('height', '30px');
                $(self).html('Show More <div class="triangle-down"></div>');
            }
    
            isCollapsedArray[pos] = !isCollapsedArray[pos];
        }
    
        return (
            <div className='mg-100' id="features_div" style={{marginTop: '80px', marginBottom: '50px', width: '73%'}}>
                <div id="features_div_title" className='width-100 flex-column '>
                    <label className="system-font" style={{fontSize: '30px', fontWeight: 700, marginBottom: 0, color: '#212b36', letterSpacing: '-0.64px'}}>Everything is POSsible with QueueBuster POS</label>
                </div>
    
                <div id="collapse_div" style={{overflow: 'hidden', height: window.innerWidth < 800 ? '1000px' : '460px', transition: 'height .5s ease'}}>
                    <div id="features_list" style={{display: 'flex', flexWrap: 'wrap', marginTop: '50px', justifyContent: 'space-between'}}>
                        {
                            (function() {
                                return featureGrid.map(function(v, i) {
                                    return (
                                        <div className="feature-item" style={{display: 'flex', flexBasis: '49%', alignItems: 'center', marginBottom: '35px'}} key={i}>
                                            <img loading={'lazy'} src={v.image} alt="" />
                                            <div style={{margin: '10px', marginLeft: '28px'}}>
                                                <div className="system-font" style={{fontSize: '18px', fontWeight: 600, marginBottom: '8px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', color: '#212b36'}}>{v.title}</div>
                                                <div className="system-font" style={{fontSize: '15px', color: '#7b7f82', fontWeight: 600, lineHeight: 1.38, letterSpacing: '-0.34px'}}>{v.content}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            })()
                        }
                    </div>
    
                    {/* <div id="supported_business_div" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '82px'}}>
                        <h3 style={{fontWeight: 'bolder'}}>Supported Businesses</h3>
                        <p style={{width: '70%', textAlign: 'center'}}>QueueBuster<sup>TM</sup> is customized for the unique requirements of
    General Retail, Restaurants, Grocery, Spa & Salon, and many other verticals.</p>
                        <img loading={'lazy'} id="supported_business_img" src={supported_business} alt=""/>
                    </div> */}
                </div>
    
                <div id="show_more" style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 600, lineHeight: 1.38, letterSpacing: '-0.34px', color: '#7b7f82'}} onClick={showMore} className="system-font">Show More<img loading={'lazy'} src={arrowDown} style={{width: '16px', height: '10px', margin: '-1px 0 0 8px'}} /></div>
    
                {/* <div className='flex-row mg-80 business-type-pc-item' id="retail">
                    <div className='width-45 text-left' style={{display: 'flex', alignItems: 'center', flexBasis: '50%'}}>
                        <img loading={'lazy'} src={retailPixelBgr} style={{width: '100%'}}/>
                    </div>
                    <div className='width-65' style={{flexBasis: '50%', paddingLeft: '50px'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            General Retail
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            From mom & pop outlet to large format retail chain, QueueBuster&trade; is highly customizable for all retail formats. Just tap on some settings and you are good to go.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold',color: '#415366'}}>
                                    Inventory management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Generate your own barcodes, manage your inventory at product / SKU level, batch wise stock-in/out
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold',color: '#415366'}}>
                                    Customer relationship management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Design and run loyalty programs, promotions, and spot discounts
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold',color: '#415366'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Single / multi outlet, exhibitions, mobile salesforce, distributor and retailer network management
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-pc-item' id="restaurants">
                    <div className='width-55' style={{flexBasis: '50%', paddingRight: '20px'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Restaurants
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            Managing  tables, kitchen, and payments was never so easy without our incredibly intuitive and robust Restaurant POS.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Menu management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage modifiers, combos, and recipes
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Ordering
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Table reservation, split bill, and online orders
                                </span>
                            </li>
                            <li style={{marginBottom: '10px', color: '#415366'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    QSR, fine dine, and food trucks
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='width-45 text-right' style={{display: 'flex', alignItems: 'center', flexBasis: '50%'}}>
                        <img loading={'lazy'} src={restaurantPixelBgr} style={{width: '100%'}}/>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-pc-item' id="grocery">
                    <div className='width-40 text-left' style={{display: 'flex', alignItems: 'center', flexBasis: '50%'}}>
                        <img loading={'lazy'} src={groceryPixelBgr} style={{width: '100%'}}/>
                    </div>
                    <div className='width-60' style={{flexBasis: '50%', paddingLeft: '40px'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Grocery Stores
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            QB is customized to handle the scale, speed, and accuracy required in the Grocery segment. Simplify your business and handle the store rush with ease
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Credit sales (Khata)
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Easy maintainence of credit history & settlement, SMS based invoicing
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Other highlights
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Barcode based sales & inventory management, mobile salesforce, pre-loaded list of 50,000+ products (India)
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Kirana stores, flea markets, exhibitions
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-pc-item' id="spa">
                    <div className='width-60' style={{flexBasis: '50%'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Spa & Salon
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            User QueueBuster&trade; as one stop shop solution to manage customer appointments and sell in-house products.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Appointments
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage your customer appointments with an intuitive and easy-to-use interface
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Service Slip
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Quick & easy access to the services availed by a customer
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Packages & Memberships
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage your client packages, make them silver, gold, or platinum members based on their purchase history
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='width-45 text-right' style={{paddingLeft: '0px', display: 'flex', alignItems: 'center', flexBasis: '50%'}}>
                        <img loading={'lazy'} src={spaPixelBgr} style={{width: '100%'}}/>
                    </div>
                </div>
    
                <div className='flex-row mg-80 business-type-mobile-item' style={{display: 'none', padding: '0 30px'}} id="retail">
                    <div className='width-45 text-left' style={{paddingRight: '0', width: '100%'}}>
                        <img loading={'lazy'} src={retailPixel}/>
                    </div>
                    <div className='width-65' style={{height: '30px', overflow: 'hidden', transition: 'height 0.3s ease'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            General Retail
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            From mom & pop outlet to large format retail chain, QueueBuster&trade; is highly customizable for all retail formats. Just tap on some settings and you are good to go.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Inventory management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Generate your own barcodes, manage your inventory at product / SKU level, batch wise stock-in/out
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Customer relationship management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Design and run loyalty programs, promotions, and spot discounts
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Single / multi outlet, exhibitions, mobile salesforce, distributor and retailer network management
                                </span>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="show-more" 
                        style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                        onClick={showMoreItem} data-pos="0">
                        Show More <div className="triangle-down"></div>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-mobile-item' style={{display: 'none', padding: '0 30px'}} id="restaurants">
                    <div className='width-45 text-right' style={{paddingLeft: '0', width: '100%'}}>
                        <img loading={'lazy'} src={restaurantPixel}/>
                    </div>
                    <div className='width-55' style={{height: '30px', overflow: 'hidden', transition: 'height 0.3s ease'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Restaurants
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            Managing  tables, kitchen, and payments was never so easy without our incredibly intuitive and robust Restaurant POS.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Menu management
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage modifiers, combos, and recipes
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Ordering
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Table reservation, split bill, and online orders
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    QSR, fine dine, and food trucks
                                </span>
                            </li>
                        </ul>
                    </div>
                
                    <div className="show-more" 
                        style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                        onClick={showMoreItem} data-pos="1">
                        Show More <div className="triangle-down"></div>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-mobile-item' style={{display: 'none', padding: '0 30px'}} id="grocery">
                    <div className='text-left' style={{paddingRight: '0', width: '100%'}}>
                        <img loading={'lazy'} src={groceryPixel}/>
                    </div>
                    <div className='width-60' style={{height: '30px', overflow: 'hidden', transition: 'height 0.3s ease'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Grocery Stores
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            QB is customized to handle the scale, speed, and accuracy required in the Grocery segment. Simplify your business and handle the store rush with ease
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Credit sales (Khata)
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Easy maintainence of credit history & settlement, SMS based invoicing
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Other highlights
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Barcode based sales & inventory management, mobile salesforce, pre-loaded list of 50,000+ products (India)
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Supported formats
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Kirana stores, flea markets, exhibitions
                                </span>
                            </li>
                        </ul>
                    </div>
                
                    <div className="show-more" 
                        style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                        onClick={showMoreItem} data-pos="2">
                        Show More <div className="triangle-down"></div>
                    </div>
                </div>
                <div className='flex-row mg-80 business-type-mobile-item' style={{display: 'none', padding: '0 30px'}} id="spa">
                    <div className='width-45 text-right' style={{paddingRight: '0', width: '100%'}}>
                        <img loading={'lazy'} src={spaPixel}/>
                    </div>
                    <div className='width-60' style={{height: '30px', overflow: 'hidden', transition: 'height 0.3s ease'}}>
                        <label className='font-medium-24-dark-weighted mg-bot-18' style={{color: '#415366'}}>
                            Spa & Salon
                        </label>
                        <p className='font-medium-16-dark' style={{color: '#415366'}}>
                            User QueueBuster&trade; as one stop shop solution to manage customer appointments and sell in-house products.
                        </p>
                        <ul style={ulStyle}>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Appointments
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage your customer appointments with an intuitive and easy-to-use interface
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Service Slip
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Quick & easy access to the services availed by a customer
                                </span>
                            </li>
                            <li style={{marginBottom: '10px'}}>
                                <span style={{display: 'block', fontSize: '15px', marginBottom: '5px', fontWeight: 'bold', color: '#415366'}}>
                                    Packages & Memberships
                                </span>
                                <span style={{display: 'block', fontSize: '14px'}}>
                                    Manage your client packages, make them silver, gold, or platinum members based on their purchase history
                                </span>
                            </li>
                        </ul>
                    </div>
                
                    <div className="show-more" 
                        style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'flex', alignItems: 'center'}} 
                        onClick={showMoreItem} data-pos="0">
                        Show More <div className="triangle-down"></div>
                    </div>
                </div> */}
            </div>
        )
    }

    componentDidMount() {
        // let featuresDiv = document.getElementById('features_div');
        // this.startHeight = featuresDiv.offsetHeight;
        // featuresDiv.style.marginTop = (featuresDiv.offsetHeight * 0.095) + 'px';
    }
}

export default Features;

const ulStyle = {
    listStyleImage: `url(${check})`,
    padding: '20px'
}