import React, {Component} from 'react';
import pic1 from '../../assets/images/small_enterprise.webp'
import pic2 from '../../assets/images/medium_enterprise.webp'
import pic3 from '../../assets/images/long_enterprise.webp'
import check from '../../assets/icons/green_check.svg'
import './EnterpriseSolutions.scss'

const $ = window.$;

class EnterpriseSolutions extends Component {
    dataArray = [
        {title: 'Small Enterprises', 
        content: 'Go for our mobile device if you have a small business operation, a mobile workforce, or less space on your counter.' ,
        image: pic1,
        li: [
            {title: 'Ideal Usage', content: <p>Less than 10,000 product SKUs <br/>100-500 invoices / device / day</p>},
            {title: 'Business Types', content: <p>Small retail outlets, exhibitions, food trucks, spa & salon,        mobile workforce, in-store billing by support staff</p>},
            {title: 'Available Configurations', content: <p>1. Mobile device + Printer <br/> 2. Mobile device + Printer + Payment Terminal</p>}
        ]},

        {title: 'Medium Enterprises', 
        content: 'Go for a bigger screen if your operations are complex, require your customers to stand in long queues, or deal with higher number of products where barcode scanning becomes essential.' , 
        image: pic2,
        li: [
            {title: 'Ideal Usage', content: <p>More than 10,000 product SKUs <br/>More than 200-250 invoices / device / day</p>},
            {title: 'Business Types', content: <p>Fine Dine / QSR restaurants, grocery & retail outlets with huge number of customer invoices & inventory</p>},
            {title: 'Available Configurations', content: <p>1. 11.6” - 14” touch screen + Printer<br/>2. 11.6” - 14” touch screen + Printer + Payment Terminal</p>}
        ]},

        {title: 'Large Enterprises', 
        content: 'A large enterprise often requires a combination of devices from a permanently stationed screen on cash counter to mobile devices for in-store as well as out-stores executives. Reach out to us to design a solution specific to your requirements.' , 
        image: pic3,
        li: [
            {title: 'Ideal Usage', content: <p>More than 100,000 product SKUs<br/>More than 10,000 invoices / day</p>},
            {title: 'Business Types', content: <p>Large format retail chains, manufacturers, wholesaler, distributors</p>}
        ]}
    ]

    constructor() {
        super();
        this.renderSubSections = this.renderSubSections.bind(this);
    }

    render() {
        return (
            <div id="enterprise_solutions">
                {this.renderSubSections()}
            </div>
        );
    }

    componentDidMount() {
        if (window.innerWidth < 800) {
            $('.ss-div>div:nth-child(2)').each(function() {
                $(this).css('flex-basis', this.scrollHeight);
            })
        }
    }

    renderSubSections() {
        let isCollapsedArray = [false, false, false];
        function showMoreItem(e) {
            let $ = window.$;
            let self;
            if (e.target.className === 'show-more')
                self = e.target;
            else if (e.target.className === 'triangle-up' || e.target.className === 'triangle-down')
                self = e.target.parentNode;
    
            let pos = +$(self).data('pos');
            
            if (isCollapsedArray[pos]) {
                $(self).prev().css('flex-basis', $(self).prev()[0].scrollHeight);
                $(self).html('Show Less <div class="triangle-up"></div>');
            } else {
                $(self).prev().css('flex-basis', '30px');
                $(self).html('Show More <div class="triangle-down"></div>');
            }
    
            isCollapsedArray[pos] = !isCollapsedArray[pos];
        }

        return this.dataArray.map(function(v, i) {
            return (
                <div className="ss-div" style={{backgroundColor: i%2!==0 ? '#f3f7fd' : 'white'}} key={i}>
                    <div style={{order: i%2!==0 ? 2 : 1, flexBasis: '41.38%'}}>
                        <img loading={'lazy'} src={v.image} style={{margin: 'auto', display: 'block'}} alt=""/>
                    </div>
                    <div style={{order: 1, flexBasis: '44.4%'}}>
                        <h5>{v.title}</h5>
                        <p>{v.content}</p>
                        <ul style={{listStyleImage: `url(${check})`}}>
                            {
                                (function() {
                                    return v.li.map(function(v1, i1) {
                                        return (
                                            <li key={i1}>
                                                <p>{v1.title}</p>
                                                {v1.content}
                                            </li>
                                        );
                                    })
                                })()
                            }
                        </ul>
                    </div>
                    <div className="show-more" 
                        style={{margin: 'auto', width: 'fit-content', marginTop: '7px', fontSize: '13px', color: '#acb3ba', cursor: 'pointer', display: 'none', alignItems: 'center'}} 
                        onClick={showMoreItem} data-pos={i}>
                        Show Less <div className="triangle-up"></div>
                    </div>
                </div>
            )
        })
    }
}

export default EnterpriseSolutions;