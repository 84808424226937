import React, {Component} from 'react';
import mall from '../../assets/images/mall_bg.webp';
import retail from '../../assets/images/retail_bg.webp';
import restaurant from '../../assets/images/restaurant_bg.webp';
import grocery from '../../assets/images/grocery_bg.webp';
import mall_icon from '../../assets/images/mall.svg';
import retail_icon from '../../assets/images/retail.svg';
import restaurant_icon from '../../assets/images/restaurants.svg';
import grocery_icon from '../../assets/images/grocery.svg';
import mall_iphone from '../../assets/images/iphone_mall.webp';
import retail_iphone from '../../assets/images/iphone_retail.webp';
import restaurant_iphone from '../../assets/images/iphone_restaurant.webp';
import grocery_iphone from '../../assets/images/iphone_grocery.webp';
import check from '../../assets/images/white_check.svg';
import mall_mobile_bg from '../../assets/images/mall_mobile.webp';
import retail_mobile_bg from '../../assets/images/retail_mobile.webp';
import restaurant_mobile_bg from '../../assets/images/restaurant_mobile.webp';
import grocery_mobile_bg from '../../assets/images/grocery_mobile.webp';
import './BusinessUseCase.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

let businesses = [
    {name: 'Malls', icon: mall_icon, bullets: ['List your stores under a single hood and provide a virtual shopping experience to your customers.', 'Make your mall virtually available 24*7 throughout the year. Business never stops with QB eStore.', 'Use banners to promote your upcoming events, sales, promotions, and boost brand recognition.'], bg: mall, image: mall_iphone, mobile_bg: mall_mobile_bg},
    {name: 'Retail', icon: retail_icon, bullets: ['Manage your catalog by listing all your products online.', 'Keep your orders, products, and inventory in sync for streamlined inventory management.', 'Cater all customers by providing flexible payment and delivery options.'], bg: retail, image: retail_iphone, mobile_bg: retail_mobile_bg},
    {name: 'Restaurants', icon: restaurant_icon, bullets: ['Perfect for QSRs, kiosks, bakery, food trucks, takeout, or food delivery.', 'Simple and user-friendly interface similar to most popular delivery apps, making it easy for the customers to order.', 'Save high commission by directly receiving the food orders and instead market your Qb eStore online.'], bg: restaurant, image: restaurant_iphone, mobile_bg: restaurant_mobile_bg},
    {name: 'Grocery', icon: grocery_icon, bullets: ['Maintain social distancing norms by avoiding long-standing queues at your shop', 'Keep in sync with your store inventory and prevent overselling via QB eStore.', 'Receive an alert and take action via Qb POS on instant sync of an order.'], bg: grocery, image: grocery_iphone, mobile_bg: grocery_mobile_bg}
]

class BusinessUseCase extends Component {
    state = {active: 0};
    
    render() {
        let {active} = this.state;

        return (
            <div style={{padding: '30px 16.6% 43px 16.6%', background: window.innerWidth <= 800 ? `url(${businesses[active].mobile_bg}) 0 0 / 100% 100% no-repeat` : `url(${businesses[active].bg}) 0 0 / 100% 100% no-repeat`}} id="business_use_case">
                <p style={{
                    fontSize: '34px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#ffffff',
                    marginBottom: '34px'
                }}>Business Use Cases</p>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                    {
                        businesses.map((v, i) => (
                            <div style={{width: '25%', borderBottom: active === i ? 'solid 6px white' : 'solid 1px white', cursor: 'pointer'}} onClick={() => {this.setState({active: i})}}>
                                <img loading={'lazy'} src={v.icon} style={{width: '40px', height: '40px', marginBottom: '8px', display: 'block', margin: 'auto'}} />
                                <p style={{
                                    marginBottom: '18px',
                                    fontSize: '21px',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    color: '#ffffff'
                                }}>{v.name}</p>
                            </div>
                        ))
                    }
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 60px'}}>
                    <img loading={'lazy'} src={businesses[active].image} style={{width: '17.86%', height: 'auto'}} />

                    <Carousel
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={false}
                    showArrows={true}
                    centerMode={false}
                    swipeable={false}
                    selectedItem={active}
                    onChange={(i) => {
                        this.setState({active: i})
                    }}>
                        {
                            businesses.map(v => (
                                <div>
                                    <img loading={'lazy'} src={v.icon} style={{width: '40px', height: '40px', display: 'block', margin: '0 auto 8px auto'}} />
                                    <p style={{
                                        marginBottom: 0,
                                        fontSize: '21px',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        color: '#ffffff',
                                    }}>{v.name}</p>
                                </div>
                            ))
                        }
                    </Carousel>

                    <div style={{width: '70.71%'}}>
                        {
                            businesses[active].bullets.map(v => (
                                <div style={{marginBottom: '10px', display: 'flex'}}>
                                    <img loading={'lazy'} src={check} style={{width: '24px', height: '24px', margin: '5px 20px 0 0'}} />
                                    <p style={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        lineHeight: 1.89,
                                        color: '#ffffff',
                                        marginBottom: 0
                                    }}>{v}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        businesses.map(v => {
            let preloadLink = document.createElement("link");
            if (window.innerWidth <= 800)
                preloadLink.href = v.mobile_bg;
            else
                preloadLink.href = v.bg;
            preloadLink.rel = "prefetch";
            document.head.appendChild(preloadLink);
        })
    }
}

export default BusinessUseCase;