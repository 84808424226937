import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Footer.scss'
import logo from '../../assets/images/logo-footer.svg';
import googlePlay from '../../assets/images/google_play.svg';
import location from '../../assets/images/location.svg';
import phone from '../../assets/images/phone_icon.svg';
import {changeBusinessTypesState} from '../../actions';

const $ = window.$;

class Footer extends Component {
    render() {
        const currentYear = String(new Date().getFullYear()).slice(2,4);
        return (
            <div>
                <div id="footer">
                    <div id="lists">
                        <div className="list" style={{flex: 2.5, paddingRight: '10%'}}>
                            <div className="heading system-font">About QueueBuster</div>
                            <ul>
                                <li className="system-font" style={{cursor: 'default'}}>
                                    QueueBuster™ is India's Business Super App. Manage invoices, inventory, customers, loyalty, khata, online dukaan and more with an extremely simple yet comprehensive solution.
                                </li>
                            </ul>
                            <img loading={'lazy'} src={googlePlay} style={{width: '125px', height: '37px', marginTop: '40px', cursor: 'pointer'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dpdtech.application.mpos')}} />
                        </div>
                        <div className="list">
                            <div className="heading system-font">About Us</div>
                            <ul>
                                <li className="system-font" onClick={() => {window.location.assign('/team')}}>Our Team</li>
                                <li className="system-font"><a href='https://blogs.queuebuster.co' target='_blank' rel="noopener noreferrer" style={{ color: '#7b7f82' }}>Blog</a></li>
                                <li className="system-font" onClick={() => {window.location.assign('/careers')}}>Join Us</li>
                            </ul>
                        </div>
                        <div className="list">
                            <div className="heading system-font">Business Types</div>
                            <ul>
                                <li className="system-font" data-id="retail" onClick={this.scrollToDiv}>General Retail</li>
                                <li className="system-font" data-id="restaurants" onClick={this.scrollToDiv}>Restaurants</li>
                                <li className="system-font" data-id="grocery" onClick={this.scrollToDiv}>Grocery</li>
                                <li className="system-font" data-id="spa" onClick={this.scrollToDiv}>Spa & Salon</li>
                            </ul>
                        </div>
                        <div className="list">
                            <div className="heading system-font">Useful Links</div>
                            <ul>
                                <li className="system-font" onClick={() => {window.location.assign('/support')}}>Support</li>
                                <li className="system-font" onClick={() => {window.location.assign('/helpCenter')}}>Knowledge Base</li>
                                <li className="system-font" onClick={() => {window.location.assign('/solutions')}}>Solutions</li>
                            </ul>
                        </div>
                        <div className="list">
                            <div className="heading system-font">Policies</div>
                            <ul>
                                <li><a href="/privacy" className="system-font">Privacy Policy</a></li>
                                <li><a href="/terms" className="system-font">Terms of Use</a></li>
                                <li><a href="/policies" className="system-font">Policies</a></li>
                            </ul>
                        </div>
                    </div>
                    <div id="contact_us" style={{marginTop: '-4px', justifyContent: 'flex-end'}}>
                        <div className="list" style={{flex: '0 0 30%'}}>
                            <div className="heading system-font"><img loading={'lazy'} src={location} style={{height: '16px', width: '16px', marginRight: '8px', marginTop: '-4px'}} />Address</div>
                            <ul>
                                <li className="system-font" style={{paddingRight: '10px', cursor: 'default'}}>401, Fourth Floor, Pegasus Tower, Plot No. A-10, Block A, Sector 68, Noida, Uttar Pradesh 201307</li>
                            </ul>
                        </div>
                        <div className="list" style={{flex: '0 0 19%'}}>
                            <div className="heading system-font"><img loading={'lazy'} src={phone} style={{height: '16px', width: '16px', marginRight: '8px', marginTop: '-4px'}} />Contact</div>
                            <ul>
                                <li className="system-font" style={{cursor: 'default'}}>+91 78 76 180 180</li>
                                <li className="system-font" onClick={() => {window.open('mailto:support@queuebuster.co')}}>support@queuebuster.co</li>
                            </ul>
                        </div>
                    </div>
                    <div id="social">
                        <img loading={'lazy'} src={logo}/>
                        <div style={{width: '19%'}}>
                            <i className="fa fa-twitter" style={{cursor: 'pointer', marginLeft: 0}} onClick={() => {window.open('https://twitter.com/QueueBusterPOS')}}></i>
                            <i className="fa fa-facebook-f" style={{cursor: 'pointer'}} onClick={() => {window.open('https://www.facebook.com/queuebuster/')}}></i>
                            <i className="fa fa-youtube-play" style={{cursor: 'pointer'}} onClick={() => {window.open('https://www.youtube.com/channel/UC28aqzrEBKvmuDNH7CMidhg')}}></i>
                            <i className="fa fa-linkedin" style={{cursor: 'pointer'}} onClick={() => {window.open('https://www.linkedin.com/company/queuebuster-co/')}}></i>
                            <i className="fa fa-instagram" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://www.instagram.com/queuebuster_pos/')}}></i>
                        </div>
                    </div>
                    <div id="copyright" className="system-font">
                        Copyright 2015-{currentYear} © DPD Technologies Pvt Ltd
                    </div>
                </div>
                
                <div id="footer2" style={{display: 'none'}}>
                    <img loading={'lazy'} src={logo} />
                    <div className="list" style={{flex: 2.5, marginBottom: '30px'}}>
                        <div className="heading system-font">About QueueBuster</div>
                        <ul style={{padding: '0 5%'}}>
                            <li className="system-font">QueueBuster™ is India's Business Super App. Manage invoices, inventory, customers, loyalty, khata, online dukaan and more with an extremely simple yet comprehensive solution.</li>
                        </ul>
                    </div>
                    <div style={{display: 'flex', marginBottom: '30px'}}>
                        <div className="list">
                            <div className="heading system-font">About Us</div>
                            <ul>
                                <li className="system-font" onClick={() => {window.location.assign('/team')}}>Our Team</li>
                                <li className="system-font">
                                    <a className="system-font" href="https://blogs.queuebuster.co" target="_blank" rel="noopener noreferrer" style={{ color: '#7b7f82' }}>Blog</a>
                                </li>
                                <li className="system-font" onClick={() => {window.location.assign('/careers')}}>Join Us</li>
                            </ul>
                        </div>
                        <div className="list">
                            <div className="heading system-font">Business Types</div>
                            <ul>
                                <li className="system-font" data-id="retail" onClick={this.scrollToDivMobile}>General Retail</li>
                                <li className="system-font" data-id="restaurants" onClick={this.scrollToDivMobile}>Restaurants</li>
                                <li className="system-font" data-id="grocery" onClick={this.scrollToDivMobile}>Grocery</li>
                                <li className="system-font" data-id="spa" onClick={this.scrollToDivMobile}>Spa & Salon</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div style={{display: 'flex', marginBottom: '30px'}}>
                        <div className="list">
                            <div className="heading system-font">Policies</div>
                            <ul>
                                <li className="system-font" onClick={() => {window.location.assign('/privacy')}}>Privacy Policy</li>
                                <li className="system-font" onClick={() => {window.location.assign('/terms')}}>Terms of Use</li>
                                <li className="system-font" onClick={() => {window.location.assign('/policies')}}>Policies</li>
                            </ul>
                        </div>
                        <div className="list">
                            <div className="heading system-font">Useful Links</div>
                            <ul>
                                <li className="system-font" onClick={() => {window.location.assign('/support')}}>Support</li>
                                <li className="system-font" onClick={() => {window.location.assign('/helpCenter')}}>Knowledge Base</li>
                                <li className="system-font" onClick={() => {window.location.assign('/solutions')}}>Solutions</li>
                            </ul>
                        </div>
                    </div>
                    <div className="list" style={{marginBottom: '30px'}}>
                        <div className="heading system-font"><img loading={'lazy'} src={location} style={{height: '12px', width: '12px', marginRight: '6px', marginTop: '-4px', marginBottom: 0}} />Address</div>
                        <ul style={{padding: '0 5%'}}>
                            <li className="system-font">401, Fourth Floor, Pegasus Tower, Plot No. A-10, Block A, Sector 68, Noida, Uttar Pradesh 201307</li>
                        </ul>
                    </div>
                    <div className="list" style={{marginBottom: '30px'}}>
                        <div className="heading system-font"><img loading={'lazy'} src={phone} style={{height: '12px', width: '12px', marginRight: '6px', marginTop: '-4px', marginBottom: 0}} />Contact</div>
                        <ul>
                            <li className="system-font">+91 78 76 180 180</li>
                            <li className="system-font">support@queuebuster.co</li>
                        </ul>
                    </div>
                    <img loading={'lazy'} src={googlePlay} style={{width: '125px', height: '37px', display: 'block', margin: 'auto', marginBottom: '40px'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dpdtech.application.mpos')}} />
                    <div id="social">
                        <div style={{marginRight: '14px'}}>
                            <i className="fa fa-twitter" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://twitter.com/QueueBusterPOS')}}></i>
                            <i className="fa fa-facebook-f" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://www.facebook.com/queuebuster/')}}></i>
                            <i className="fa fa-youtube-play" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://www.youtube.com/channel/UC28aqzrEBKvmuDNH7CMidhg')}}></i>
                            <i className="fa fa-linkedin" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://www.linkedin.com/company/queuebuster-co/?originalSubdomain=in')}}></i>
                            <i className="fa fa-instagram" style={{cursor: 'pointer'}} onClick={() => {window.location.assign('https://www.instagram.com/queuebuster_pos/')}}></i>
                        </div>
                    </div>
                    <div className="system-font" id="copyright">
                        Copyright 2015-{currentYear} © DPD Technologies Pvt Ltd
                    </div>
                </div>
            </div>
        )
    }

    scrollToDiv = (e) => {
        let $ = window.$;
        let id = $(e.target).data('id');
        let n = 0;
        if (id === 'retail')
          n = 0;
        else if (id === 'restaurants')
          n = 1;
        else if (id === 'grocery')
          n = 2;
        else if (id === 'spa')
          n = 3;
        if (window.location.pathname !== '/') {
          localStorage.setItem('landingPageState', `web-scroll-${n}`);
          window.location.assign('/');
          return
        };
        $('html, body').animate({
            scrollTop: $('#'+'business_types_2').offset().top-68+190
        }, 1000);
        if (id === 'retail')
          this.props.changeBusinessTypesState(0);
        else if (id === 'restaurants')
          this.props.changeBusinessTypesState(1);
        else if (id === 'grocery')
          this.props.changeBusinessTypesState(2);
        else if (id === 'spa')
          this.props.changeBusinessTypesState(3);
    }

    scrollToDivMobile = (e) => {
        let $ = window.$;
        let id = $(e.target).data('id');
        let n = 0;
        if (id === 'retail')
          n = 0;
        else if (id === 'restaurants')
          n = 1;
        else if (id === 'grocery')
          n = 2;
        else if (id === 'spa')
          n = 3;
        if (window.location.pathname !== '/') {
          localStorage.setItem('landingPageState', `web-scroll-${n}`);
          window.location.assign('/');
          return
        };
        $('html, body').animate({
            scrollTop: $('#'+'business_types_2').offset().top-68
        }, 1000);
        if (id === 'retail')
          this.props.changeBusinessTypesState(0);
        else if (id === 'restaurants')
          this.props.changeBusinessTypesState(1);
        else if (id === 'grocery')
          this.props.changeBusinessTypesState(2);
        else if (id === 'spa')
          this.props.changeBusinessTypesState(3);
    }

    showSignupModal(e) {
        e.preventDefault();

        $('#signup-modal').modal('show');
    }

    showLoginModal(e) {
        e.preventDefault();

        $('#login-modal').modal('show');
    }
}

export default connect(null, {changeBusinessTypesState})(Footer);