import React, {Component} from 'react';
import Header from '../common/Header';
import top from '../../assets/images/merchant-image@3x.jpg';
import eStore from '../../assets/images/khata-logo.svg';
import iphone from '../../assets/images/khata-device@3x.jpg';
import check from '../../assets/icons/green_check.svg';
import ScheduleDemo from '../sections/ScheduleYourDemo';
import smartphone from '../../assets/images/qucikandeasy.svg';
import settings from '../../assets/images/multilingual-support.svg';
import drag from '../../assets/images/zerocalculation.svg';
import responsive from '../../assets/images/databackup.svg';
import responsive2 from '../../assets/images/scalable.svg';
import Footer from '../sections/Footer';
import './EStore.scss';
import main from '../main';
import { GooglePlayButton } from '../common/qbStrap';

const $ = window.$;

let bullets1 = [
    {title: 'Add Customer', desc: 'Add or manage countless customers with their separate accounting.'},
    {title: 'Record Transactions', desc: 'Record their credit and debit transactions with optional bill & remark upload.  '},
    {title: 'Send Payment Reminders', desc: 'Share reminders with the customer from multiple channels like SMS, Email, or Whatsapp.'},
    {title: 'Settle Payments', desc: 'Collect and record payments from a wide range of payment modes.'},
    {title: 'View Reports', desc: 'Access all reports and transaction history anytime without any hassle.'},
]

class EStore extends Component {
    render() {
        return (
            <div id="eStore">
                <Header />

                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: ' 0 8.3% 90px 8.3%'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap', flexBasis: '47.03%'}}>
                            <img loading={'lazy'} src={eStore} style={{height: '45px', margin: '6px 13px 20px 0'}} />
                            <p style={{
                                fontSize: '39px',
                                fontWeight: 'bold',
                                lineHeight: 1.05,
                                letterSpacing: '-0.37px',
                                color: '#242424',
                                flexBasis: '100%',
                                margin: '30px 0 40px 0',
                                display: 'none'
                            }}>We care for your customers</p>
                            <p style={{
                                fontSize: '21px',
                                lineWeight: 1.73,
                                color: '#242424',
                                flexBasis: '100%',
                                marginBottom: '20px'
                            }}>Get rid of the traditional Hisab Kitab ledger and digitize your Khata. Record every Credit-Jama and Debit-Udhar transaction and make your management simpler.</p>
                            <GooglePlayButton styles={{width: '207px'}} />
                        </div>
                        <img loading={'lazy'} src={top} style={{width: '47.03%', height: 'auto'}} />
                    </div>
                </div>

                <div style={{padding: '0 8.3% 0 8.3%', marginBottom: '160px'}}>
                    <p style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        letterSpacing: '-0.64px',
                        textAlign: 'center',
                        color: '#212b36',
                        marginBottom: '60px'
                    }}>
                        Managing Khata cannot be simpler
                    </p>

                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                        <div style={{width: '41.66%', paddingTop: '43px', order: window.innerWidth > 800 ? 2 : 1}}>
                            {
                                bullets1.map(v => (
                                    <div style={{marginBottom: '30px'}}>
                                        <div style={{marginBottom: '2px'}}>
                                            <img loading={'lazy'} src={check} style={{height: '18px', width: '18px', marginRight: '10px'}} />
                                            <span style={{
                                                fontSize: '20px',
                                                fontWeight: 600,
                                                color: '#2c2e30'
                                            }}>{v.title}</span>
                                        </div>
                                        <p 
                                            style={{
                                                marginBottom: 0, 
                                                paddingLeft: '28px',
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                lineHeight: 1.38,
                                                letterSpacing: '-0.34px',
                                                color: '#7b7f82'
                                            }}>{v.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <img loading={'lazy'} src={iphone} style={{width: '50.66%', height: 'auto', order: window.innerWidth > 800 ? 1 : 2}} />
                    </div>
                </div>

                <div style={{display: 'none'}}></div>
                <div style={{display: 'none'}}></div>

                <div style={{backgroundColor: '#f3f7fd', padding: '57px 8.3% 80px 8.3%', display: 'flex', flexWrap: 'wrap'}}>
                    <p style={{
                        fontSize: '34px',
                        fontWeight: 600,
                        textAlign: 'center',
                        color: '#2c2e30',
                        marginBottom: '60px',
                        flexBasis: '100%'
                    }}>Why use QB Khata ?</p>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexBasis: '100%'}}>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={smartphone} style={{display: 'block', margin: '20px auto 0 auto', height: '80px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Quick & Easy
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0,
                                display: 'none'
                            }}>
                                Download the QueueBuster POS application from the Google play store.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={settings} style={{display: 'block', margin: '20px auto 0 auto', height: '80px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Multilingual Support
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0,
                                display: 'none'
                            }}>
                                Enable the eStore feature from the POS application to start managing your eStore.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={drag} style={{display: 'block', margin: '20px auto 0 auto', height: '80px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Zero Calculation
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0,
                                display: 'none'
                            }}>
                                Prepare & publish the catalog of your products for customers to browse.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={responsive} style={{display: 'block', margin: '20px auto 0 auto', height: '80px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Full Data Backup
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0,
                                display: 'none'
                            }}>
                                Now, share and promote your online store for more customers to discover.
                            </p>
                        </div>
                        <div style={{flexBasis: '22.22%'}}>
                            <div style={{
                                margin: '0 auto 20px auto'
                            }}>
                                <img loading={'lazy'} src={responsive2} style={{display: 'block', margin: '20px auto 0 auto', height: '80px'}} />
                            </div>
                            <p style={{
                                fontSize: '22px',
                                color: '#2c2e30',
                                textAlign: 'center',
                                marginBottom: '10px'
                            }}>
                                Highly Scalable
                            </p>
                            <p style={{
                                fontSize: '16px',
                                lineHeight: 1.5,
                                textAlign: 'center',
                                color: '#7b7f82',
                                marginBottom: 0,
                                display: 'none'
                            }}>
                                Now, share and promote your online store for more customers to discover.
                            </p>
                        </div>
                    </div>
                    <GooglePlayButton styles={{margin: window.innerWidth > 800 ? '60px auto 0 auto' : '0 auto 40px auto', width: window.innerWidth > 800 ? '207px' : '167px'}} />
                </div>

                <div style={{display: 'none'}}></div>

                <div style={{height: '30px'}}></div>
                <ScheduleDemo />
                <Footer />
            </div>
        )
    }

    componentDidMount() {
        main();

        $('#eStore>div:nth-child(2)').css('padding-top', $('#main_header').height() + (window.innerWidth < 800 ? 30 : 46));
    }
}

export default EStore;