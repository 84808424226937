import React, {Component,Fragment} from 'react';
import Header from '../common/Header';
import './Pricing.scss';
import phone from '../../assets/images/phone_icon.svg';
import Footer from '../sections/Footer';
import StartTrial from '../sections/StartTrial';
import main from '../main';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import check from '../../assets/icons/green_check.svg'
import zoho from '../../assets/images/zoho.png'
import mdn from '../../assets/images/mdn.png'
import tally from '../../assets/images/tally.svg'
import urbanpiper from '../../assets/images/urban-piper.svg'
import { Button } from 'reactstrap';
import Integrations from './Integrations';

const $ = window.$;
let pricingInput = null;
if ($('#pricingInput').val()) {
    pricingInput = JSON.parse($('#pricingInput').val());
}

class Pricing extends Component {
    constructor(props){
        super(props)
        this.state = {selected: 1,filteredOptions: [],optionsArray: []}
    }

     filterAddons = (x) => {
        const term = x.target.value;
            const filteredOptions = this.state.optionsArray.filter(i => i.name.toLowerCase().includes(term.toLowerCase()));
            this.setState({filteredOptions});
        }
        
    plans = [
        { title: 'Monthly', price: pricingInput ? pricingInput.planCurrency + pricingInput.plans.mPlan1 : '', rate: '', content: 'Pay us on a monthly basis. Most flexible plan to let you operate on a minimum budget.' },
        // { title: 'Half Yearly', price: pricingInput ? pricingInput.planCurrency + pricingInput.plans.hPlan1 : '', rate: '', content: 'Pay us every six months. Get 15 days worth of FREE usage with our half-yearly plan.' },
        { title: 'Annually', price: pricingInput ? pricingInput.planCurrency + pricingInput.plans.yPlan1 : '', rate: '', content: 'Pay us every year. Get 2 months worth of FREE usage with our yearly plan.' },
        { title: 'Enterprise', price: 'Custom', rate: '', content: 'Suitable for multi-chain outlets with back-end integrations and customization.' }
    ]
     optionsArray = [
        {name:"UrbanPiper",price: 199,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: urbanpiper,moreUrl: ''},
        {name:"Zoho",price: 299,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: zoho,moreUrl: ''},
        {name:"Tally",price: 399,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: tally,moreUrl: ''},
        {name:"Navision",price: 499,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: mdn,moreUrl: ''}
        ];
        
    render() {
        let { selected } = this.state;
        let self = this;
        const width = window.screen.width;
        return (
            <div id="pricing_page">
                <Header />
                <div style={{ textAlign: 'center' }}>
                    <h3>Choose the plan that fits your business need</h3>
                    <p style={{ fontSize: '14px', fontWeight: 500, padding: '0 70px' }}>Priced just perfect for business of every size</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '45px' }}>
                        {
                            (function () {
                                return self.plans.map(function (v, i) {
                                    return (
                                        <div
                                            style={{
                                                width: '274px',
                                                marginRight: i !== 3 ? '30px' : 0,
                                                boxShadow: '0 2px 20px 0 #e3e3e7',
                                                borderRadius: '8px',
                                                padding: '20px 20px 40px 20px',
                                                backgroundColor: 'white',
                                                position: 'relative',
                                                border: selected === i ? 'solid 3px #498bdc' : 'none'
                                            }}
                                            onClick={() => { self.setState({ selected: i }) }}>
                                            <p style={{ marginBottom: i === 1 ? '47px' : '47px' }}>{i === 1 ? '' : ''}</p>
                                            <p style={{ fontWeight: selected === i ? 600 : 'normal' }}>{v.title}</p>
                                            <h3>{(v.price.length > 4 && v.price !== 'Custom') ? v.price.substring(0, v.price.length - 3) + ',' + v.price.substring(v.price.length - 3) : v.price}</h3>
                                            <p>{v.content}</p>
                                            <button style={v.title === 'Enterprise' ? enterpriseStyle : {}} onClick={v.title === 'Enterprise' ? self.goToContact : self.showSignupModal}>{v.title === 'Enterprise' ? 'Contact Us' : 'Choose'}</button>
                                        </div>
                                    )
                                })
                            })()
                        }
                    </div>
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        autoPlay={false}
                        showArrows={false}
                        centerMode={true}
                        selectedItem={selected}
                        onChange={(i) => { }}
                        onClickItem={(i) => { self.setState({ selected: i }) }}
                        centerSlidePercentage={50}>
                        {
                            (function () {
                                return self.plans.map(function (v, i) {
                                    return (
                                        <div
                                            style={{
                                                width: '39.37vw',
                                                margin: '0 auto',
                                                boxShadow: '0 1px 12px 0 #e3e3e7',
                                                borderRadius: '8px',
                                                padding: '10px 12px 25px 12px',
                                                backgroundColor: 'white',
                                                position: 'relative',
                                                border: selected === i ? 'solid 3px #498bdc' : 'none',
                                                transform: selected === i ? 'scale(1.27, 1.27)' : 'none'
                                            }}>
                                            <p style={{ marginBottom: i === 1 ? '30px' : '30px' }}>{i === 1 ? '' : ''}</p>
                                            <p style={{ fontWeight: selected === i ? 600 : 'normal' }}>{v.title}</p>
                                            <h3>{(v.price.length > 4 && v.price !== 'Custom') ? v.price.substring(0, v.price.length - 3) + ',' + v.price.substring(v.price.length - 3) : v.price}</h3>
                                            <p>{v.content}</p>
                                            <button style={v.title === 'Enterprise' ? enterpriseStyle : {}} onClick={v.title === 'Enterprise' ? '' : self.showSignupModal}>{v.title === 'Enterprise' ? 'Contact Us' : 'Choose'}</button>
                                        </div>
                                    )
                                })
                            })()
                        }
                    </Carousel>

                    <div class="width-80"><p style={{ fontSize: "12px" }}>(All prices are exclusive of taxes.)</p></div>
             
                    {/* Addon Services */}
                    <Integrations isIndividual={true}/>
       
                    <h3 className='needhelp'>Need help with choosing your plan?</h3>
                    <div  style={{display: "flex",justifyContent: "center"}}><img src={phone} className="phoneimg" /><span style={{fontSize: (width > 600) ? "28px" : "20px",fontWeight: "bold"}}>+91 78 76 180 180</span></div>
                </div>

                <StartTrial />
                <div style={{ display: 'none' }}>
                    <div>
                        <p>Not sure which plan will suit you best?</p>
                    </div>
                    <button onClick={() => { window.open('mailto:support@queuebuster.co') }}>Email Us</button>
                </div>
                <Footer />
            </div>
        );
    }

    componentDidMount() {
        main();
        this.setState({filteredOptions: this.optionsArray,optionsArray: this.optionsArray});
        $('#pricing_page>div:nth-child(2)').css('padding-top', $('#main_header').height() + 40)
    }

    showSignupModal(e) {
        e.preventDefault();
        $('#signup-modal').modal('show');
    }
   
    goToContact () {
        localStorage.setItem('landingPageState', `schedule_demo`);
        window.location.assign('/');
        return
    }
}

const enterpriseStyle = {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(73, 139, 220)',
    borderWidth: '2px',
    borderColor: 'rgb(73, 139, 220)',
    borderStyle: 'solid'
}

export default Pricing;


