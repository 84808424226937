import React, {Component} from 'react';
import './OtpModal.scss'
import qbMobile from '../../assets/images/mobile_qb_icon.svg';
import cross from '../../assets/images/cross.svg';
import main from '../main';

const $ = window.$;

class OtpModal extends Component {
    render() {
        return (
            <div id="otp-modal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img loading={'lazy'} src={qbMobile} style={{height: '36px', width: '36px'}} />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img loading={'lazy'} src={cross} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <h3>Verify Phone Number</h3>
                                <p>Please enter the OTP sent to <span id='otpPhone'></span></p>
                                <form className="otp-form">
                                    <input type="number" placeholder="Enter OTP" name="otp" id="otp" min="1" max="9999" style={{ minHeight: '46px' }} />
                                    <div style={{display: 'inline-block', textAlign: 'end', fontSize: '14px', marginTop: '8px'}}>
                                        <span>
                                            Didn't receive OTP?&nbsp;
                                        </span>
                                        <span 
                                            style={{ color: '#488cdd', cursor: 'pointer', marginLeft: 'auto'}} 
                                            id="resendOtp">Resend</span>
                                    </div>
                                    <div id="otp-messages-error" style={{
                                        marginTop: '8px',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        lineHeight: 1.83,
                                        letterSpacing: '-0.26px',
                                        color: '#ff3636',
                                        height: 'fit-content',
                                        display: 'block'
                                    }}></div>
                                    <p className="system-font" style={{ marginBottom: 0, marginTop: '20px' }}>
                                        By signing up on QueueBuster, you are agreeing to our <a href="/terms" style={{fontWeight: 400}} target="_blank">Terms of Use</a> and <a href="/privacy" style={{fontWeight: 400}} target="_blank">Privacy Policy</a>
                                    </p>
                                    <button type="submit">Verify OTP</button>
                                    <div className="lead-text text-center" id="otp-messages"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OtpModal;