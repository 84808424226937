// import React,{Component,Fragment} from 'react';

// import check from '../../assets/icons/green_check.svg';

// import zoho from '../../assets/images/zoho.png';
// import mdn from '../../assets/images/mdn.png';
// import tally from '../../assets/images/tally.svg';
// import urbanpiper from '../../assets/images/urban-piper.svg';

// import shopify from '../../assets/images/shopify.svg';
// import gSP from '../../assets/images/gsp.png';
// import ePOS from '../../assets/images/epos.png';
// import pathfinder from '../../assets/images/pathFinder.png';
// import razorPay from '../../assets/images/razorpay.svg';
// import pinelabsPlural from '../../assets/images/pinelabsLoyal.png';
// import payU from '../../assets/images/payU.png';


// import Header from '../common/Header';
// import Footer from '../sections/Footer';
// import { Button, Input } from 'reactstrap';
// import "./Integrations.scss";
// import { Link } from 'react-router-dom';

// class Integrations extends Component {
//     constructor(props){
//         super(props)
//         this.state ={
//             filteredItemsList: [],
//             itemsList: []
//         }
//     }

//     componentDidMount() {
//         const  optionsArray = [
//             {name:"UrbanPiper",price: 199,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: urbanpiper,moreUrl: ''},
//             {name:"Zoho",price: 299,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: zoho,moreUrl: ''},
//             {name:"Tally",price: 399,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: tally,moreUrl: ''},
//             {name:"Navision",price: 499,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: mdn,moreUrl: ''},

//             {name:"Shopify",price: 199,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: shopify,moreUrl: ''},
//             {name:"GSP",price: 299,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: gSP,moreUrl: ''},
//             {name:"EPOS",price: 399,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: ePOS,moreUrl: ''},
//             {name:"PathFinder",price: 499,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: pathfinder,moreUrl: ''},
           
//             {name:"Razorpay",price: 199,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: razorPay,moreUrl: ''},
//             {name:"PineLabsPlural",price: 299,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: pinelabsPlural,moreUrl: ''},
//             {name:"PayU",price: 399,cartOptions:["Sales Management","Accounting","Payables Management"],imgUrl: payU,moreUrl: ''},
//             ];
//         this.setState({filteredItemsList: optionsArray,itemsList: optionsArray});
//     }

//     filterAddons = () => {  
//         let haveTimeOut = false;
        
//        return ((x) => {
//             let term = x.target.value;
//             if(haveTimeOut) clearTimeout(haveTimeOut);
//             haveTimeOut = setTimeout(() => {
//                   const filteredItemsList = this.state.itemsList.filter(i => i.name.toLowerCase().includes(term.toLowerCase()));
//                   this.setState({filteredItemsList});
//             },300);
//        });
//     }


//     render(){
//         const {filteredItemsList} = this.state;
//         const filterAddons = this.filterAddons();
//         const showAll = this.showAll;
//         return (
//             <Fragment>
//            {!(this.props.isIndividual) && <Header />}  
//            {/* show header and footer only when isIndividual is false   --->    on (/integrations) route  */}
//             <div className='main-container'>
//                   <section className='addons-title'>
//                       <h1 className='title'>Add on Services</h1>
//                       <p className='title-desc'>Value Add On Services to improve & ease out your business</p>
//                   </section>

//                   <Input type="text" placeholder="Search for Services" className='search' onChange={filterAddons} name="searchAddon"/> 

//                   <section className='addons-container'>
//                       { 
//                       this.props.isIndividual ? 
//                       filteredItemsList.slice(0,8).map((item) =>  <AddOnCard checkIcon={check} {...item} />) 
//                      :
//                       filteredItemsList.map((item) => <AddOnCard checkIcon={check} {...item} />)
//                       }
//                   </section>
//                  {Boolean(this.props.isIndividual && !!(filteredItemsList.length > 7)) && <Link to="/integrations"> <Button className="btn btn-primary show-all">Show All</Button> </Link> }
//             </div>
//             {!(this.props.isIndividual) && <Footer/> }
//             </Fragment>
//         );
//     }


   
// }


// export default Integrations;


// export const AddOnCard = ({imgUrl,name,check,price,checkIcon}) => {
//     const cartOptions =  ["Sales Management","Accounting","Payables Management"];

//     return (
//         <div className='card-container'>
//             <div className='imgSection'>
//                 <img src={imgUrl} className='addimg' alt="Add On Image" />
//             </div>
//             <div className='desc-price-container'>
//                 <div className='desc-section'>
//                     <h3 className='name'>{name}</h3>
//                     {cartOptions.map(item => <span className='optionsitem'><img src={checkIcon} className="checkimg" alt =""/> {item} </span>)}
//                 </div>
//                 <div className='price-section'>
//                     <h3 className='price'>₹ {price}</h3>
//                 </div>
//             </div>
//         </div>
//     );
// }








import React, {Component} from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

import zoho from '../../assets/images/zoho.png';
import mdn from '../../assets/images/mdn.png';
import tally from '../../assets/images/tally.svg';
import urbanpiper from '../../assets/images/urban-piper.svg';

import shopify from '../../assets/images/shopify.svg';
import gSP from '../../assets/images/gsp.png';
import ePOS from '../../assets/images/graymatter.png';
import pathfinder from '../../assets/images/pathFinder.png';
import razorPay from '../../assets/images/razorpay.svg';
import pinelabsPlural from '../../assets/images/pinelabs.webp';
import payU from '../../assets/images/payU.png';
import payTM from '../../assets/images/paytmMLogal.png';


class Integrations extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        
    }

    render(){
        return (
        <div className='width-80 mg-100' id="partners" style={{ marginTop: "50px" }}>
            <h3 style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '30px', fontWeight: 700, color: '#212b36', marginBottom: '10px', lineHeight: 'normal', letterSpacing: '-0.64px'}}>Add on Services - Integrations and Connected Platforms</h3>
            <p style={{fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif', fontSize: '16px', fontWeight: 'normal', color: '#7b7f82', marginBottom: '50px', lineHeight: 1.5, letterSpacing: 'normal'}}>Value Add On Services to improve & ease out your business - Connect with your existing tools without any change in your business process.</p>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <img loading={'lazy'} src={zoho} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '18px',objectFit: "contain",padding: "5%"}}/>
                <img loading={'lazy'} src={mdn} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '18px',objectFit: "scale-down",padding: "5%"}}/>
                <img loading={'lazy'} src={tally} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '18px',objectFit: "scale-down",padding: "5%"}}/>
                <img loading={'lazy'} src={urbanpiper} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '18px',objectFit: "scale-down"}}/>

                <img loading={'lazy'} src={shopify} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "contain",padding: '4%'}}/>
                <img loading={'lazy'} src={gSP} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding: "3%"}}/>
                <img loading={'lazy'} src={ePOS} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding:"4%"}}/>
                <img loading={'lazy'} src={razorPay} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding: "4%"}}/>
                
                <img loading={'lazy'} src={pathfinder} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding: "3%"}}/>
                <img loading={'lazy'} src={pinelabsPlural} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding: "1%"}}/>
                <img loading={'lazy'} src={payU} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down",padding: "4%"}}/>
                <img loading={'lazy'} src={payTM} style={{width: '24%', height: '180px', boxShadow: '0 0 10px 0 #ddd', marginBottom: '11px',objectFit: "scale-down"}}/>
            </div>

            <Carousel
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                autoPlay={false}
                infiniteLoop={true}
                axis={"horizontal"}
                centerMode={true}
                centerSlidePercentage={70}>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={zoho} style={{height: 'auto', margin: '10px'}} /> </div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={mdn} style={{height: 'auto',  margin: '10px',}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={tally} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={urbanpiper} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={shopify} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={gSP} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={ePOS} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={razorPay} style={{height: 'auto',  margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={pathfinder} style={{height: 'auto',  margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={pinelabsPlural} style={{height: 'auto', margin: '10px'}} /></div>
                    <div style={{height: "100px",display: "flex",alignItems: 'center'}}><img loading={'lazy'} src={payU} style={{height: 'auto', margin: '10px'}} /></div>
            </Carousel>
        </div>
        );
    }
}



export default Integrations;
