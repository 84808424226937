import React, {Component} from 'react';
import './HowToApplyModal.scss';
import qbMobile from '../../assets/images/mobile_qb_icon.svg';
import cross from '../../assets/images/cross.svg';

const $ = window.$;

let bullets = [
    <p>Sign up with QueueBuster, from our Android application or our website.</p>,
    <p>Start with your 30 days free trial.</p>,
    <p>Purchase the QueueBuster licenses from the  License Payment page during or after the trial ends.</p>,
    <p>Select the license with its payment plan, and apply the promo code <strong>FREEDOM50</strong> to avail the discount. Use it before 15th September to reap the benefits.</p>,
]

class HowToApplyModal extends Component {
    render() {
        return (
            <div id="hta-modal" className="modal fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img loading={'lazy'} src={qbMobile} style={{height: '36px', width: '36px'}} />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img loading={'lazy'} src={cross} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <h3>How to apply</h3>
                            <div>
                                {
                                    bullets.map(v => (
                                        <div style={{display: 'flex'}}>
                                            <p>•</p>
                                            {v}
                                        </div>
                                    ))
                                }
                            </div>
                            <div style={{display: 'flex'}}>
                                <p>*</p>
                                <p>Applicable only for new sign ups.</p>
                            </div>
                            <p>Call us on  <strong>+91 78 76 180 180</strong> or
write to us at <strong>support@queuebuster.co</strong> in case you counter any issues, we'll love to help you out.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowToApplyModal;