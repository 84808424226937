import React from "react";
import { EngagePampletDesktop } from "./EngagePampletDesktop";
import { EngagePampletMobile } from "./EngagePampletMobile";

const EngagePamplet = () => {
   const screenWidth = window.screen.width;
   const _desc = "Witness the latest trends in Retail Technology & connect with industry stalwarts";
   const _title = "QueueBuster ENGAGE 2023 - India's Biggest Retail Technology Event"
   document.title = _title;
   document.description = _desc;
   document.querySelector('meta[property="og:title"]').setAttribute("content", _title);
   document.querySelector('meta[property="og:description"]').setAttribute("content", _desc);
   if (screenWidth < 900) {
      return (
         <>
            <EngagePampletMobile />
         </>
      );
   } else {
      return (
         <>
            <EngagePampletDesktop />
         </>
      );
   }
};

export { EngagePamplet };
