import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

let brands = ['https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/itc.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/mother-dairy.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/gullymart.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/cococart.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/bayars-coffee.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/unilever-logo.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/reshamandi.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/sneha.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/heineken.webp', 'https://quebuster.s3.ap-south-1.amazonaws.com/website/brands/scw.webp'];

class Brands extends Component {

    constructor() {
        super()
        this.state = {
            fetchCall: null
        }
    }

    componentWillMount() {
        // Fetch brands via API
        fetch(`/fetchBrands`)
            .then(response => response.json())
            .then(json => {
                if (json && json.brands) {
                    brands = json.brands.split(",")
                    this.setState({ fetchCall: true })
                }
            })
    }

    render() {
        if (this.state.fetchCall) {
            const imgs = brands;
            const itemsInRow = window.screen.width > 1400 ? 5 : window.screen.width > 700 ? 3 : 2;
            return (
                <div style={{ marginTop: '-30px' }} id="trusted_brands">
                    <h2 className="system-font" style={{ textAlign: 'center', color: '#212b36', fontWeight: 900, fontSize: '30px', marginBottom: '30px', fontFamily: 'Gotham-Medium', fontWeight: 700, color: '#212b36', letterSpacing: '-0.64px' }}>Trusted By 50,000+ Brands</h2>
                    <Carousel
                        interval={3000}
                        infiniteLoop={true}
                        autoPlay={true}
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                    >
                        {
                            sliceIntoChunks(imgs, itemsInRow).map(resArr => {
                                return (
                                    <div className={`brands-imagecontainer`}>
                                        {resArr.map(itm => <div style={{ minHeight: '100px', display: 'flex', alignItems: "center" }}><img loading='lazy' src={itm} alt="brandLogo" /> </div>)}
                                    </div>
                                );
                            })
                        }

                    </Carousel>
                </div>
            )
        } else return (
            <div style={{ minHeight: '100px' }}></div>
        );
    }
}

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export default Brands;