import CustomIndustryWiseFormV2 from "./CustomIndustryWiseFormV2";
import React from "react";
import NewIndustryWiseForms from "./CustomIndustryWiseFormsDesktopV2";

const GroceryFormV2 = () => {
   const screenWidth = window.screen.width;
   const outletTypes = [
      "Kirana Stores",
      "Supermarkets",
      "Hypermarkets",
      "FMCG",
      "Fruits & Vegetables",
   ];

   const features = [
      {
         title: "Khata",
         content: "Get rid of the traditional Hisab Kitab and digitize your daily ledger",
      },
      {
         title: "CRM & Loyalty",
         content: "A suite of CRM & Loyalty tools to manage your customers",
      },
      {
         title: "Inventory Management",
         content: "Record & manage your inventory across all locations in one place",
      },
      {
         title: "eStore",
         content: "Create your own website & start selling online within minutes",
      },
      {
         title: "Quick Bill",
         content: "Create invoices without adding product catalog",
      },
      {
         title: "And many more...",
         content: "",
      },
   ];
   if(screenWidth<900){
      return(
         <>
         <CustomIndustryWiseFormV2
            resType={"Grocery"}
            outletTypes={outletTypes}
            features={features}
            color={"#3FA860"}
         />
      </>
      )
   }else{
      return(
         <NewIndustryWiseForms 
         resType={"Grocery"}
         outLetType={outletTypes}
         features={features}
         color={"#3FA860"}
         />
      )
   }

};

export default GroceryFormV2;
