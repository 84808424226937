import React, {Component} from 'react';
import Header from '../common/Header';
import Footer from '../sections/Footer';
import GenericPage from '../GenericPage';
import partnership_inquiries from '../../assets/images/partnership-inquiries.svg'
import write_to_us from '../../assets/images/write-tous.svg'
import lets_talk from '../../assets/images/lets-talk.svg'
import get_support from '../../assets/images/get-support.svg'
import './GetInTouch.scss'
import main from '../main';

const $ = window.$;

class GetInTouch extends Component {
    arrayData = [
        {image: partnership_inquiries, title: 'Partnership Inquiries', content: (<p>We are always open to collaborate with Global and Local partners. Send us an email at <b>contact@queuebuster.co</b></p>)},
        {image: get_support, title: 'Chat Support', content: (<p>Reach out to us right from QueueBuster application through our chat module.</p>)},
        {image: lets_talk, title: 'Lets Talk', content: (<p>In case our support center cannot address your queries, call us on our number at <b>+91 78 76 180 180</b></p>)},
        {image: write_to_us, title: 'Write To Us', content: (<p>For those who love writing, send us an email at <b>support@queuebuster.co</b> and we will get back to you soon.</p>)},
    ]

    render() {
        let self = this;
        return (
            <div id="get_in_touch">
                <Header />
                <div style={{backgroundColor: '#f3f7fd', textAlign: 'center'}}>
                    <h3>Support</h3>
                    <p>We are dedicated to providing our partners with exceptional partnership opportunities and sustainable business and revenue growth through our value-add programs and benefits.</p>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', padding: '80px 17.92% 180px 17.92%'}} id="contact_us_list">
                    {
                        (function() {
                            return self.arrayData.map(function(v) {
                                return (
                                    <div style={{flexBasis: '50%', display: 'flex', marginBottom: '40px', justifyContent: 'space-around', alignItems: 'center'}}>
                                        <img loading={'lazy'} src={v.image} alt=""  style={{flexBasis: 'auto'}}/>
                                        <div style={{flexBasis: '75%', paddingRight: '20px'}}>
                                            <p>{v.title}</p>
                                            {v.content}
                                        </div>
                                    </div>
                                );
                            })
                        })()
                    }
                </div>
                <Footer/>
            </div>
        );
    }

    componentDidMount() {
        // var s = document.createElement( 'script' );
        // s.setAttribute( 'type', 'text/javascript' );
        // s.setAttribute( 'src', '/js/client.min.js' );
        // document.body.appendChild( s );
        
        // s = document.createElement( 'script' );
        // s.setAttribute( 'type', 'text/javascript' );
        // s.setAttribute( 'src', '/js/main.js' );
        // document.body.appendChild( s );

        main();
        $('#get_in_touch>div:nth-child(2)').css('padding-top', $('#main_header').height() + (window.innerWidth <= 800 ? 40 : 48))
    }
}

export default GetInTouch;