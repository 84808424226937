import React, { Component } from "react";
import Header from "../common/Header";
import LinkedIn from "../../assets/icons/linkedIn.png";

import "./OurTeam.scss";
import Footer from "../sections/Footer";

import TeamWebp from "../../assets/images/QB-Team.webp";
import Team from "../../assets/images/QB-Team.jpg";

import main from '../main';

let founder = {};

const team_Persons = [
];

const fundingNewsTill = '2022-07-31';

class OurTeam extends Component {

	constructor() {
        super()
        this.state = {
			fetchCall: null
		}
    }

	componentWillMount () {
		let teamMembers = []
		// Fetch team details via API
		fetch(`/fetchTeam`)
			.then(response => response.json())
			.then(json => {
				if (json && json.teams) {
					teamMembers = json.teams
					for (let i = 0; i < teamMembers.length; i++) {
						const tempPerson = teamMembers[i]
						if (tempPerson.sorting == 1) {
							founder = {
								src: tempPerson.image,
								name: tempPerson.name,
								tag: tempPerson.position,
								desc: tempPerson.description,
								socials: [
									{
										name: LinkedIn,
										url: tempPerson.linkedin,
									},
								],
							}
						} else {
							team_Persons.push({
								src: tempPerson.image,
								name: tempPerson.name,
								tag: tempPerson.position,
								socials: [
									{
										name: LinkedIn,
										url: tempPerson.linkedin,
									},
								],
							})
						}
					}
					this.setState({fetchCall: true})
				}
			})
	}

	render () {
		if (this.state.fetchCall) {
			const width = window.screen.width;
			// var people = `
			// 	<div
			// 		style={{
			// 			display: "flex",
			// 		}}
			// 		className="multiple-person"
			// 	>
			// `
			// for (let i = 0; i < team_Persons.length; i++) {
			// 	const employee = {...team_Persons[i]};
			// 	let rowType = ""
			// 	if (i%2 === 0) {
			// 		rowType = width < 600 ? "reverse-row" : ""
			// 	}
			// 	people += `
			// 		<ProfileInfo
			// 			addClasses={"many ${rowType}"}
			// 			{${employee}}
			// 		/>
			// 	`
			// 	if (i%2 === 0) {
			// 		people += `</div>`
			// 	}
			// }
			const people = []
			let row = 0;
			for(let i = 0; i < team_Persons.length; i += 2) {
				const employee = team_Persons[i]
				const employee1 = team_Persons[i+1]
				let employeeClass = "many"
				let employeeClass1 = "many"
				if (row%2 !== 0) {
					employeeClass1 = "many " + (width < 600 ? " reverse-row" : "")
				} else {
					employeeClass = "many" + (width > 600 ? " reverse-row" : "")
					employeeClass1 = "many reverse-row"
				}
				row++
				if (employee1 === undefined || employee1 === null) {
					people.push(
						<div
							style={{
								display: "flex",
							}}
							className="multiple-person"
						>
							<ProfileInfo
								addClasses={employeeClass}
								{...employee}
							/>
						</div>
					)
					continue
				}
				people.push(
					<div
						style={{
							display: "flex",
						}}
						className="multiple-person"
					>
						<ProfileInfo
							addClasses={employeeClass}
							{...employee}
						/>
						<ProfileInfo
							addClasses={employeeClass1}
							{...employee1}
						/>
					</div>
				)
			}
			return (
				<div id="main-container">
					<Header />
					<section
						className={`content-container flex-row`}
						style={{
							marginTop: new Date() < new Date(fundingNewsTill) ? (width < 600 ? '120px' : '0') : '0',
							textAlign: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<article className={`intro-container-`}>
							<h1 className={`intro-title`}>
								<span> We are dedicated to making your </span> <br />
								<span> business smoother </span>
							</h1>
							{/* <section className={`flex-row intro-media-container`}>
												<img src={playbutton} alt="media-icon" className='media-icon' />
												<h3 className={`font2`}>Find out company’s goals & fundamentals & know how our business  can help you grow.</h3>
											</section> */}
						</article>
						{!!(width < 600) && (
							<section className={`flex-row profile-image-container`}>
								<picture>
									<source style={{ width: "100%" }} type="image/webp" alt="Team" srcset={TeamWebp} />
									<img
										src={Team}
										alt="Team"
										style={{
											width: "100%",
										}}
									/>
								</picture>
								
							</section>
						)}
						{/* <article className={`info-container`}>
											{info.map(i => {
												return (    
													<section className={`flex-row info-details`}>
													  <img src={checkmark} alt="checkmark-icon" style={{marginRight:'10px',width:"20px"}}/>
													  <h3 className={`font3`} style={{whiteSpace: 'pre'}}>{i}</h3>        
													</section>
												);
											})}
										</article> */}
					</section>
					{/* Images section */}
					{!!(width > 600) && (
						<section className={`flex-row profile-image-container`}>
							<picture>
								<source style={{ width: "100%" }} type="image/webp" alt="Team" srcset={TeamWebp} />
								<img
									src={Team}
									alt="Team"
									style={{
										width: "100%",
									}}
								/>
							</picture>
							
						</section>
					)}
					{/* Team Intro section */}
					<section className={`team-intro-container`}>
						<h1 className={`intro-title team-intro-title`}>
							
							Meet the talent behind our success
						</h1>
						<section
							className={`flex-row single-person-container sngle`}
							style={{
								border: "solid 1px #ededed",
								width: "100%",
							}}
						>
							<img src={founder.src} alt="person" />
							<span
								style={{
									width: "40px",
								}}
							>
								
							</span>
							<section
								className={`flx-column`}
								style={{
									justifyContent: "center",
									margin: "0 20px",
								}}
							>
								<h2
									className={`person-name`}
									style={{
										margin: "10px 0",
									}}
								>
									
									{founder.name}
								</h2>
								<h4
									className={`person-tag`}
									style={{
										marginBottom: "10px",
										color: "#498bdc",
									}}
								>
									
									{founder.tag}
								</h4>
								<article
									className={`person-description font2`}
									style={{
										width: "80%",
										color: "#949494",
										minHeight: "25%",
									}}
								>
									
									{founder.desc}
								</article>
								<section
									className={"person-socials"}
									style={{
										margin: "10px  0",
										display: "inherit",
									}}
								>
									
									{founder.socials.map((i) => (
										<a
											href={i.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											
											<img
												src={i.name}
												alt={i.name}
												style={{
													width: "30px",
													height: "30px",
													marginRight: "20px",
													cursor: "pointer",
												}}
											/>
										</a>
									))}
								</section>
							</section>
						</section>
						<div className="mul">
							{people}
						</div>
					</section>
					<Footer />
				</div>
			);
		} else return null;
	}

	componentDidMount() {
        main();
    }
}

export default OurTeam;

const ProfileInfo = ({
	src = "",
	name = "",
	tag = "",
	desc = "",
	socials,
	addClasses = "",
}) => {
	const descritption = desc ? desc.slice(0, 500) : desc;
	const width = window.screen.width;

	return (
		<section
			className={`flex-row single-person-container ${addClasses}`}
			style={{
				border: "solid 1px #ededed",
				width: addClasses === "sngle" ? "auto" : "100%",
			}}
		>
			
			{/* <div style={{width: '200px',height:'200px'}}> */}
			<img
				src={src}
				className={"personImage"}
				alt="person"
				style={{
					objectFit: "cover",
					minWidth: width < 600 ? "200px" : "300px",
					height: width < 600 ? "220px" : "300px",
				}}
			/>
			{/* </div> */}
			<span
				style={{
					width: "20px",
				}}
			>
				
			</span>
			<section
				className={`flx-column`}
				style={{
					justifyContent: "space-around",
					margin: width > 600 ? " 0 50px" : "0 20px",
					width: "100%",
				}}
			>
				<div>
					<h2
						className={`person-name2`}
						style={{
							margin: "10px 0",
						}}
					>
						
						{name}
					</h2>
					<h4
						className={`person-tag2`}
						style={{
							marginBottom: "10px",
							color: "#498bdc",
						}}
					>
						
						{tag}
					</h4>
				</div>
				{Boolean(desc) ? (
					<article
						className={`person-description font2`}
						style={{
							width: "80%",
							color: "#949494",
							minHeight: "25%",
						}}
					>
						
						{descritption}
					</article>
				) : (
					<div
						style={{
							minHeight: addClasses == "sngle" ? "25%" : "1%",
						}}
						className="empty-desc"
					/>
				)}
				<section
					className={"person-socials"}
					style={{
						margin: "10px  0",
						display: "inherit",
					}}
				>
					
					{socials.map((i) => (
						<a
							href={i.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							
							<img
								src={i.name}
								alt={i.name}
								style={{
									width: "34px",
									height: "auto",
									marginRight: "20px",
									cursor: "pointer",
								}}
							/>
						</a>
					))}
				</section>
			</section>
		</section>
	);
};
