import React, { useState, useEffect } from "react";
import "../assets/countDownTimer.css";

const CountdownTimer = ({ date }) => {
	const [days, setDays] = useState(null);
	const [hours, setHours] = useState(null);
	const [minutes, setMinutes] = useState(null);
	const [seconds, setSeconds] = useState(null);

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date().getTime();
			const distance = date.getTime() - now;

			const daysRemaining = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hoursRemaining = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutesRemaining = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);

			setDays(daysRemaining);
			setHours(hoursRemaining);
			setMinutes(minutesRemaining);
			setSeconds(secondsRemaining);
		}, 1000);

		return () => clearInterval(interval);
	}, [date]);

	return (
		<div className="timerBottomMargin">
			<div className="timerDisplayFlex">
				<div>
					<div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
						<div className="timer">{days}</div>
						<div className="timerRightLine"></div>
					</div>
					<div className="timerBottomLine"></div>
					<div className="timerText">Days</div>
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
						<div className="timer">{hours}</div>
						<div className="timerRightLine"></div>
					</div>
					<div className="timerBottomLine"></div>
					<div className="timerText">Hours</div>
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
						<div className="timer">{minutes}</div>
						<div className="timerRightLine"></div>
					</div>
					<div className="timerBottomLine"></div>
					<div className="timerText">Minutes</div>
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
						<div className="timer">{seconds}</div>
						<div className="timerRightLine"></div>
					</div>
					<div className="timerBottomLine"></div>
					<div className="timerText">Seconds</div>
				</div>
			</div>
		</div>
	);
};

export default CountdownTimer;
