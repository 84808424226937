import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import "./CustomIndustryWiseFormV2.css";

class MobileLandingPageFormV2 extends Component {
   state = {
      fullname: "",
      mobileNo: "",
      email: "",
      purpose: this.props.resType,
      mobileNoError: false,
      emailError: false,
      nameError: false,
      formResponse: false,
      submittingForm: false
   };

   handleChangeName = (e) => {
      this.setState({
         fullname: e.target.value,
      });
   };
   handleChangeMobile = (e) => {
      this.setState({
         mobileNo: e.target.value,
      });
   };

   handleChangeEmail = (e) => {
      this.setState({
         email: e.target.value,
      });
   };

   apiCall = () => {
      var EMAIL_VALIDATION_REGEX =
         /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      let { fullname, mobileNo, email } = this.state;
      if (!fullname.length) {
         this.setState({ nameError: true });
         return;
      }
      if (mobileNo[0] == 0) {
			mobileNo = mobileNo.slice(1, mobileNo.length)
		}
      if (mobileNo.length != 10) {
         this.setState({ mobileNoError: true });
         return;
      }
      if (email.match(EMAIL_VALIDATION_REGEX) == null) {
         this.setState({ emailError: true });
         return;
      }
      this.setState({
			submittingForm: true
		})
      let obj = {
         name: fullname,
         phoneCode: 91,
         mobile: mobileNo,
         email: email,
         purpose: this.state.purpose,
      };
      if ((fullname, mobileNo, email)) {
         fetch(window.location.origin + "/mail/demo", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(obj),
         })
            .then((res) => {
               if (res) {
                  const data = { resType: obj.purpose, fullname: obj.name };
                  this.props.history.push("/thankyou", data);
               }
            })
            .catch((e) => {
               console.log("e", e);
            });
      }
   };

   render() {
      let { fullname, mobileNo, email } = this.state;
      return (
         <div
            // className="formOuterBox"
            style={{
               display: "flex",
               flexDirection: "column",
               height:
                  window.screen.width > 750
                     ? "39.5vh"
                     : window.screen.height < 600 && window.screen.width < 375
                     ? "55vh"
                     : "44.5vh",
               alignItems: "left",
               marginTop: "8px",
               //   border: "1px solid blue",
               borderRadius: "5px",
               padding: "1rem 0.8rem 0.8rem",
               background: "#F4F9FE 0% 0% no-repeat padding-box",
               position: "relative",
            }}
         >
            <h4
               style={{
                  textAlign: "left",
                  height: "5vh",
                  //  height:"10%",
                  //  margin: "1rem 0 0 0.8rem",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "bold",
                  fontSize: "1.4rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#767881",
                  letterSpacing: "0%",
               }}
            >
               Request a Demo
            </h4>
            <p
               style={{
                  marginBottom: "2px",
                  fontFamily: "Poppins, sans-serif",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "500",
                  color: "#767881",
               }}
            >
               Our experts are here to help
            </p>
            <Form className="form" style={{ paddingTop: "0.5rem" }}>
               <FormGroup>
                  <Input
                     type="text"
                     name="name"
                     id="name"
                     placeholder="Full name"
                     style={{
                        height: "5vh",
                        borderRadius: "5px",
                        border: "0.5px solid #707070",
                     }}
                     onChange={(e) => this.handleChangeName(e)}
                  />
                  <p
                     style={
                        this.state.nameError && !this.state.fullname
                           ? { display: "block" }
                           : { display: "none" }
                     }
                     className="errorHandle"
                  >
                     Please Enter Your Name
                  </p>
               </FormGroup>
               <FormGroup>
                  <Input
                     type="number"
                     name="mobileNo"
                     id="mobileNo"
                     placeholder="Mobile Number"
                     style={{
                        height: "5vh",
                        borderRadius: "5px",
                        border: "0.5px solid #707070",
                     }}
                     onChange={(e) => {
                        this.handleChangeMobile(e);
                        this.setState({ mobileNoError: false });
                     }}
                     required={true}
                  />
                  <p
                     style={this.state.mobileNoError ? { display: "block" } : { display: "none" }}
                     className="errorHandle"
                  >
                     Please Enter Correct Phone Number
                  </p>
               </FormGroup>
               <FormGroup>
                  <Input
                     type="text"
                     name="email"
                     id="emailId"
                     placeholder="E-mail ID"
                     style={{
                        height: "5vh",
                        borderRadius: "5px",
                        border: "0.5px solid #707070",
                     }}
                     onChange={(e) => {
                        this.handleChangeEmail(e);
                        this.setState({ emailError: false });
                     }}
                  />
                  <p
                     style={this.state.emailError ? { display: "block" } : { display: "none" }}
                     className="errorHandle"
                  >
                     Please Enter Correct Email Address
                  </p>
                  <p
                     style={{
                        textAlign: "left",
                        //    font: "normal normal normal 0.7rem/0.9rem Poppins",
                        color: "#767881",
                        paddingTop: "0.625rem",
                        marginBottom: "5px",
                     }}
                     className="formInstructions"
                  >
                     By submitting my details, I agree to be contacted by QueueBuster via
                     Newsletter, Email, WhatsApp, SMS, Phone etc.
                  </p>
                  <div id="formTemplate" style={{ display: "flex", justifyContent: "center" }}>
                     <Button
                        disabled = {this.state.submittingForm}
                        onClick={this.apiCall}
                        style={{
                           width: "calc(100% - 1rem)",
                           background: "#3C69AF 0% 0% no-repeat padding-box",
                           height:
                              window.screen.height < 600 && window.screen.width < 375
                                 ? "6vh"
                                 : "5vh",
                           marginTop: "1rem",
                           position: "absolute",
                           //    margin: "0 auto",

                           bottom: "10px",
                        }}
                     >
                        {this.state.submittingForm ? "Saving..." : "Submit"}
                     </Button>
                  </div>
               </FormGroup>
            </Form>
         </div>
      );
   }
}
// }
export default MobileLandingPageFormV2;
